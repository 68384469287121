import { Search } from "@material-ui/icons";
import "./custom-input-typeform.css";
const CustomInputTypeForm = ({
  label,
  type,
  isDisabled,
  value,
  isMobNum,
  onChangeHandler,
  id,
  sn,
}) => {
  return (
    <div
      className={`w-100 custom-input-div-typeform rounded d-flex flex-column position-relative`}
    >
      <div
        style={{
          position: "absolute",
          backgroundColor: "#eaeeee",
          borderRadius: "20px",
          height: "2.5rem",
          padding: "0px 50px 0px 15px",
          margin: 0,
          left: "-36px",
          fontSize: "22px",
          zIndex: "-1",
        }}
      >
        <Search style={{ marginTop: "7px", color: "#3cb6d3" }} />
      </div>
      <input
        required
        type={type}
        maxLength={isMobNum ? 10 : 1000}
        minLength={isMobNum ? 10 : 1}
        disabled={isDisabled}
        list={id ? id : null}
        style={
          (isDisabled && { cursor: "no-drop" },
          {
            borderRadius: "20px",
            height: "2.5rem",
            fontFamily: "proxima_nova_rgregular",
            fontSize: "15px",
            padding: "0px 50px 0px 15px",
            margin: 0,
            width: "280px",
            backgroundColor: "#eaeeee",
            border: "none",
          })
        }
        value={value}
        placeholder="Search by Society"
        onChange={(e) => onChangeHandler(e.target.value)}
      />

      <button
        type="submit"
        style={{
          display: "none",
        }}
        onClick={(e) => e.preventDefault()}
      ></button>
    </div>
  );
};

export default CustomInputTypeForm;
