import React from "react";
import "./home-page.css";
import { ReactComponent as Building } from "../../../../assets/building.svg";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PlacesAutocomplete from "react-places-autocomplete";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useMediaQuery } from "react-responsive";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import { setCurrentLocalityID } from "../../../../store/actions/actions";
import { connect } from "react-redux";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: "#3cb6d3",
  boxShadow: "none",
  fontFamily: "proxima_nova_rgregular",
  fontSize: "17px",
  marginTop: "3px",
  ":hover": {
    textDecoration: "underline",
  },
}));

const HomePage = (props) => {
  const [address, setAddress] = React.useState("");
  const isMobileScreen = useMediaQuery({ query: "(max-width: 768px)" });

  const handleSelect = async (value) => {
    setAddress(value);
    const addressComponents = value.split(",");
    const len = addressComponents.length;
    const locality = len >= 4 ? addressComponents[len - 4].trim() : null;

    console.log("Locality:", locality);

    if (locality) {
      props.history.push(`/property/rent/bangalore/${locality}`);
    }
  };

  const localities = [
    {
      id: 1,
      name: "Whitefield",
      slug: "whitefield",
      img: "https://static.tnn.in/thumb/msid-95621322,width-1280,height-720,resizemode-75/95621322.jpg",
    },
    {
      id: 2,
      name: "HSR",
      slug: "HSR Layout",
      img: "https://www.capitaland.com/content/dam/capitaland-media-library/businesspark-industrial-logistics/india/bangalore/international-tech-park-bangalore/DFT-ITPB1.jpg.transform/cap-midres/image.jpg",
    },
    {
      id: 3,
      name: "Koramangala",
      slug: "koramangala",
      img: "https://media.istockphoto.com/id/535403859/photo/dancing-at-disco.jpg?s=612x612&w=0&k=20&c=mVZX9qAsgnOv8C0t9gR81ofJ0JG20Orc4Io9r4AKNQQ=",
    },
    {
      id: 4,
      name: "Indiranagar",
      slug: "indiranagar",
      img: "https://c.ndtvimg.com/2023-11/c4bp49g_restaurant-generic_625x300_21_November_23.jpg?im=FeatureCrop,algorithm=dnn,width=1200,height=675",
    },
    {
      id: 5,
      name: "Jaynagar",
      slug: "jaynagar",
      img: "https://img.traveltriangle.com/blog/wp-content/uploads/2022/01/Famous-Food-Of-South-India.jpg",
    },
    {
      id: 6,
      name: "Sarjapur Road",
      slug: "sarjapur-road",
      img: "https://im.whatshot.in/img/2022/Aug/play-cropped-1661866956.jpg",
    },
    {
      id: 7,
      name: "BTM Layout",
      slug: "btm-layout",
      img: "https://i0.wp.com/stanzaliving.wpcomstaging.com/wp-content/uploads/2022/04/d33d0-cafe-1.jpg?fit=1000%2C667&ssl=1",
    },
    {
      id: 8,
      name: "Electronic City",
      slug: "electronic-city",
      img: "https://www.treebo.com/blog/wp-content/uploads/2018/01/best-parks-in-bangalore_1.jpg",
    },
    {
      id: 9,
      name: "Rajaji Nagar",
      slug: "rajaji-nagar",
      img: "https://www.tirthayatra.org/wp-content/uploads/2018/09/ISKCON-Bangalore-HK-hill-night-view-pinterest.jpg",
    },
  ];

  return (
    <div className="landing-page">
      {/* contact whastapp */}

      <a
        className="position-fixed bg-white rounded-circle p-2 c-pointer bouncing border"
        style={{
          bottom: "30px",
          right: "20px",
          zIndex: 10,
        }}
        href="https://openlist.in/"
        rel="noreferrer"
        target="_blank"
      >
        <WhatsAppIcon
          style={{
            color: "green",
            fontSize: 40,
          }}
        />
      </a>

      <div
        className="landing-page-upper position-relative d-flex align-items-center align-items-md-start"
        style={{
          minHeight: isMobileScreen ? "45vh" : "60vh",
        }}
      >
        <div className="my-4 d-flex container justify-content-center flex-column align-items-center content">
          <h1
            className="text-center main-heading"
            style={{
              fontFamily: "proxima_nova_rgbold",
              fontSize: isMobileScreen ? 40 : 42,
              marginTop: isMobileScreen ? "0px" : "100px",
            }}
          >
            Let's find you a home
          </h1>
          <p
            className="text-center"
            style={{
              color: "rgba(255,255,255,.8)",
              fontFamily: "proxima_nova_rgregular",
              fontSize: isMobileScreen ? 18 : 21,
            }}
          >
            List & Rent for Free! No Subscription Popups.
            {isMobileScreen && <br />}
            {" Direct Deals"}
          </p>

          <div className="row w-100 mt-3">
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="places-search-bar position-relative p-0 d-flex flex-column align-items-center col-11 col-lg-7 mx-auto">
                  <div className="d-flex w-100 align-items-center bg-white input-container py-1">
                    <span
                      className="c-pointer"
                      onClick={() => {
                        if (address.length !== 0) {
                          handleSelect(address);
                        }
                      }}
                    >
                      <SearchIcon
                        style={{
                          color: "#3cb6d3",
                          margin: "0 7px",
                          fontSize: 32,
                        }}
                      />
                    </span>
                    <input
                      {...getInputProps({
                        placeholder: "Search City, Neighbourhood or Address",
                      })}
                      className="m-0"
                      style={{
                        border: "none",
                        fontFamily: "proxima_nova_rgregular",
                      }}
                    />
                  </div>
                  <div
                    className="position-absolute bg-white left-0 right-0"
                    style={{
                      border: `${
                        suggestions.length ? "1" : "0"
                      }px solid rgba(0,0,0,.2)`,
                      backdropFilter: "blur(10px)",
                      zIndex: 2,
                      top: 55,
                      minWidth: "100%",
                    }}
                  >
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active
                          ? "rgba(0,0,0,.05)"
                          : "white",
                        color: "black",
                      };

                      return (
                        <div {...getSuggestionItemProps(suggestion, { style })}>
                          {suggestion.description && (
                            <div className="d-flex align-items-center border-bottom p-2 text-muted">
                              <LocationOnIcon />
                              <p
                                className="result-item w-100 m-0 mx-2 fc-1c"
                                style={{
                                  fontFamily: "proxima_nova_rgregular",
                                }}
                              >
                                {suggestion.description}
                              </p>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>
        <Building
          style={{
            position: "absolute",
            bottom: 0,
          }}
        />
      </div>
      <section className="my-4">
        <h2
          className="text-center my-4 pt-4"
          style={{
            borderRadius: "20px",
            fontSize: "36px",
            fontFamily: "proximanovabold",
            color: "#3cb6d3",
          }}
        >
          The Way We Work
        </h2>
        <p
          className="text-muted text-center"
          style={{
            color: "rgba(255,255,255,.6)",
            fontFamily: "proximanova",
            fontSize: 20,
          }}
        >
          We gather properties from various sources to help you find your home
          within a week.
        </p>

        <div
          style={{
            maxHeight: "3vw",
          }}
        />
        <div className="locations pt-4">
          <div className="container">
            <Grid container spacing={2}>
              {localities.map((locality) => (
                <Grid item xs={12} sm={4} key={locality.id}>
                  <Link
                    to={`/property/rent/bangalore/${locality.slug}`}
                    className="location-card position-relative my-2"
                  >
                    <img
                      alt="location"
                      className="img-fluid"
                      loading="lazy"
                      src={locality.img}
                    />
                    {locality.comingSoon && (
                      <h5
                        className="position-absolute text-white top-0 start-70 p-2"
                        style={{ fontSize: 16 }}
                      >
                        Coming soon
                      </h5>
                    )}
                    <h3 className="text-center position-absolute text-white top-50 w-100">
                      {locality.name}
                    </h3>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>

        <p
          className="text-muted text-center my-4 pt-4"
          style={{
            color: "rgba(0,0,0,1)",
            fontFamily: "proxima_nova_rgregular",
            fontSize: 22,
          }}
        >
          With <span style={{ fontWeight: "bold", color: "#3cb6d3" }}>283</span>{" "}
          rentals currently available, you're sure to find a great place to
          live.
        </p>
        <div
          style={{
            minHeight: "5vw",
          }}
        />
      </section>

      {/* popular rentals searches */}

      <section className="container mx-auto mb-5">
        <Typography
          sx={{
            borderRadius: "20px",
            fontFamily: "proxima_nova_rgbold",
            color: "#3cb6d3",
            textAlign: "center",
            fontSize: { xs: "34px", md: "46px", lg: "46px" },
            marginBottom: { xs: "15px", md: "23px", lg: "23px" },
          }}
        >
          Popular Rentals Searches
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 2, sm: 8, md: 12 }}
          >
            {localities.map((elem, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Item>
                  <h4
                    style={{
                      color: "#a2a39a",
                      fontFamily: "proxima_nova_rgbold",
                      marginBottom: "2px",
                    }}
                  >
                    {elem.name}
                  </h4>
                </Item>
                <Item>
                  <Link
                    style={{
                      color: "#3cb6d3",
                      fontFamily: "proxima_nova_rgregular",
                    }}
                  >
                    1 bedroom apartments in {elem.name}
                  </Link>
                </Item>
                <Item>
                  <Link
                    style={{
                      color: "#3cb6d3",
                      fontFamily: "proxima_nova_rgregular",
                    }}
                  >
                    2 bedroom apartments in {elem.name}
                  </Link>
                </Item>
                <Item>
                  <Link
                    style={{
                      color: "#3cb6d3",
                      fontFamily: "proxima_nova_rgregular",
                    }}
                  >
                    Rooms for rent in {elem.name}
                  </Link>
                </Item>
                <Item>
                  <Link
                    style={{
                      color: "#3cb6d3",
                      fontFamily: "proxima_nova_rgregular",
                    }}
                  >
                    Short term rentals in {elem.name}
                  </Link>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
      </section>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentLocalityID: (val) => dispatch(setCurrentLocalityID(val)),
});
export default connect(null, mapDispatchToProps)(HomePage);
