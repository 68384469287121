import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import apartmentReducer from "./apartmentReducer";
import userReducer from "./userReducer";
import storage from "redux-persist/lib/storage";
import approveReducer from "./approveReducer";
import communityReducer from "./communityReducer";
import postsReducer from "./postsReducer";
import chatsReducer from "./chatsReducer";

const { persistReducer } = require("redux-persist");

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const rootReducer = combineReducers({
  apartment: apartmentReducer,
  firestore: firestoreReducer,
  user: userReducer,
  approve: approveReducer,
  community: communityReducer,
  posts: postsReducer,
  chats: chatsReducer,
});

export default persistReducer(persistConfig, rootReducer);
