export const NEW_POST = {
  city: "",
  address: "",
  locality: "",
  propertyType: "",
  furnishingStatus: "",
  latLng: {
    lat: 12.9698196,
    lng: 77.7499721,
  },
  bedroom: 0,
  bathroom: 0,
  cost: "",
  area: "",
  deposit: "",
  images: [],
  videos: [],
  desc: "",
  features: {
    unitFeatures: [],
    societyFeatures: [],
  },
  societyName: "",
  flatNumber: "",
  maintenanceIncluded: "",
  maintenance: "",
  Im: "Admin",
  idealFor: [],
  tags: [],
  type: "",
  brokerage: "",
  phone: "",
  managementPreference: "",
};
