import { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import firebase from "../../../config/fbConfig";
import {
  setAuthModalConfig,
  setUser,
  setShowChatList,
  setShowChatBox,
} from "../../../store/actions/actions";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import { compose } from "redux";
import SearchIcon from "@material-ui/icons/Search";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PlacesAutocomplete from "react-places-autocomplete";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import "./header.css";
import linearLoader from "./../../../assets/linear-loader.svg";
import Avatar from "@mui/material/Avatar";
import Messenger from "./../../../assets/messenger.png";
import { Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { headerList } from "../../../utils/data";
import { FaYoutube, FaEnvelope } from "react-icons/fa";
import { FaArrowLeftLong, FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import { MdFacebook } from "react-icons/md";
import { BsTwitterX } from "react-icons/bs";
import { IoNotifications } from "react-icons/io5";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Moment from "react-moment";
import { CommunityPostCardsPopUp } from "../../community/components/community-post-cards/postPopup";
import { setAllPosts } from "../../../store/reducers/postsReducer";
import mixpanel from "mixpanel-browser";

const socialMedia = [
  { id: 1, name: "Instagram", icon: <FaInstagram /> },
  { id: 2, name: "Facebook", icon: <MdFacebook /> },
  { id: 3, name: "YouTube", icon: <FaYoutube /> },
  { id: 4, name: "LinkedIn", icon: <FaLinkedinIn /> },
  { id: 5, name: "Twitter", icon: <BsTwitterX /> },
];

const SvgLoader = () => {
  return (
    <div
      className="position-relative d-flex align-items-center mx-2 my-0"
      style={{
        width: 100,
      }}
    >
      <img
        src={linearLoader}
        alt="svg loader"
        className="position-absolute"
        style={{
          height: 80,
          width: 200,
          top: -38,
          left: -30,
          margin: 0,
        }}
      />
    </div>
  );
};

const Header = ({
  user,
  setUser,
  setAuthModalConfig,
  welcomeMsgConfig,
  userDoc,
  setShowChatList,
  showChatList,
  setShowChatBox,
  unreadMessagesCount,
}) => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 599px)" });
  const history = useHistory();
  const [address, setAddress] = useState("");
  const location = useLocation();
  const [showSearchbar, setShowSearchbar] = useState(false);
  const [smallHeader, setSmallHeader] = useState(false);
  const [isCommunity, setCommunity] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showNotificatiosPost, setShowNotificatiosPost] = useState(false);
  const [postData, setPostData] = useState(null);
  const allPosts = useSelector((state) => state.posts?.posts);
  const [sortedComments, setSortedComments] = useState([]);
  const [showPostPopup, setShowPostPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [comment, setComment] = useState("");
  const [unseenNotificCount, setUnseenNotificCount] = useState(0);

  useEffect(() => {
    numberOfUnseenNotifications();
    setSortedComments(postData?.comments?.reverse());
  }, [showNotificatiosPost]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleShowNotificationPost = (postId, notificationId) => {
    console.log("hanji:", showNotificatiosPost);
    console.log("hanji:", allPosts);
    handleClose();
    allPosts.forEach((p) => {
      if (p.id === postId) {
        setPostData(p);
      }
    });
    setShowNotificatiosPost(true);

    firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("notifications")
      .doc(notificationId)
      .update({
        isSeen: true,
      });
  };

  useEffect(() => {
    setSmallHeader(location.pathname.includes("details"));
    setCommunity(location.pathname.includes("community"));
  }, [location]);

  const dispatch = useDispatch();
  const [filterLocality] = useState("");

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("posts")
      .where(
        "locality",
        `${filterLocality.length > 0 ? "==" : "!="}`,
        filterLocality
      )
      .onSnapshot((docs) => {
        if (docs.size > 0) {
          const posts = docs.docs.map((post) => ({
            id: post.id,
            ...post.data(),
          }));
          console.log(posts);
          dispatch(setAllPosts(posts));
        } else {
          dispatch(setAllPosts([]));
        }
      });

    return () => unsub();
  }, [dispatch, filterLocality]);

  const notify = () => toast(welcomeMsgConfig.msg);

  const handleSelect = async (value) => {
    setAddress(value);
    let len = value.split(",").length;
    let locality = "";
    locality = len >= 4 ? value.split(",")[len - 4] : "";
    setShowSearchbar(false);

    if (locality.toLowerCase().includes("whitefield")) {
      history.push("/apartments/yqnMZNXH2IRG8TVxw694");
    } else {
      history.push("/not-found");
    }
  };

  useEffect(() => {
    if (welcomeMsgConfig.show) {
      notify();
    }
    // eslint-disable-next-line
  }, [welcomeMsgConfig]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        mixpanel.identify(user.uid);

        try {
          const requestBody = {
            token: user.toJSON().stsTokenManager.accessToken,
          };

          window.localStorage.setItem(
            "accessToken",
            user.toJSON().stsTokenManager.accessToken
          );

          console.log(window.localStorage.getItem("accessToken"))

          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
            credentials: "include",
          };

          await fetch(
            "https://urbanletting-6afef.df.r.appspot.com/api/login",
            requestOptions
          )
            .then((response) => {})
            .catch((error) => {
              console.log(error);
            });

          const response = await fetch(
            `https://urbanletting-6afef.df.r.appspot.com/users/${user.uid}`
          );
          if (response.ok) {
            const userData = await response.json();

            const userDocRef = firebase
              .firestore()
              .collection("users")
              .doc(user.uid);

            const notificationsCollectionRef =
              userDocRef.collection("notifications");

            const notificationsQuerySnapshot =
              await notificationsCollectionRef.get();
            const notificationsData = [];
            notificationsQuerySnapshot.forEach((doc) => {
              notificationsData.push({ id: doc.id, ...doc.data() });
            });
            setUser({ ...user, ...userData, notifications: notificationsData });
          } else {
            setUser(user);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setUser(user);
        }
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const setPopUpFalse = () => {
    setShowPostPopup(false);
    setShowNotificatiosPost(false);
  };

  const handleSubmitComment = (comment) => {
    if (user) {
      const commentData = {
        userId: user.uid,
        userName: user.fullname,
        userAvatar: user.avatar,
        text: comment,
        timestamp: new Date(),
      };
      sortedComments?.unshift(commentData);
      setLoading(true);
      console.log("comment->", commentData);
      firebase
        .firestore()
        .collection("posts")
        .doc(postData?.id)
        .update({
          comments: firebase.firestore.FieldValue.arrayUnion(commentData),
        })
        .then(() => {
          console.log("comment done");
          if (!showPostPopup) setShowComment(false);
          setComment("");
          const notificationData = {
            userId: user?.uid,
            userName: user.fullname,
            userAvatar: user.avatar,
            postId: postData?.id,
            text: `${user.fullname} commented on your post`,
            isSeen: false,
            timestamp: new Date(),
          };
          firebase
            .firestore()
            .collection("users")
            .doc(postData?.uid)
            .collection("notifications")
            .add(notificationData)
            .then(() => console.log("Added notification"))
            .catch(() => console.log("error in notification sending"));
        })
        .catch((error) => console.log("error in comment->", error));
    }
    sortedComments?.shift();
    setLoading(false);
  };

  const numberOfUnseenNotifications = () => {
    let count = 0;
    user?.notifications?.forEach((notification) => {
      if (!notification.isSeen) count += 1;
    });
    console.log("count", count);
    setUnseenNotificCount(count);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <ListItem style={{ paddingTop: 15, paddingBottom: 0, opacity: "0.9" }}>
        <FaArrowLeftLong className="text-white mx-2" style={{ fontSize: 25 }} />
      </ListItem>
      <List sx={{ opacity: "0.9" }}>
        {headerList.map((item, index) => (
          <ListItem sx={{ marginTop: "-4px" }} key={index} disablePadding>
            <Link to={item.redirect}>
              <ListItemButton>
                <Typography>
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{
                      fontSize: "18px",
                      fontWeight: "600",
                      fontFamily: "proxima_nova_rgregular",
                    }}
                    sx={{
                      paddingLeft: "8px",
                      color: "white",
                    }}
                  />
                </Typography>
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider
        sx={{ width: "85%", color: "gray", opacity: 0.3, marginLeft: "20px" }}
      />
      <List sx={{ opacity: "0.9", marginLeft: "-7px" }}>
        <ListItem>
          <ListItemButton>
            <ListItemIcon sx={{ color: "white", fontSize: "18px" }}>
              <FaEnvelope />
            </ListItemIcon>
            <ListItemText
              primary="Contact Us"
              primaryTypographyProps={{
                fontSize: "16px",
                fontFamily: "proxima_nova_rgregular",
                marginLeft: "-23px",
              }}
              sx={{
                color: "white",
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider
        sx={{ width: "85%", color: "gray", opacity: 0.2, marginLeft: "20px" }}
      />
      <List sx={{ opacity: "0.9", marginLeft: "8px" }}>
        {socialMedia.map((item, index) => (
          <ListItem key={index} disablePadding>
            <Link to={"/"}>
              <ListItemButton>
                <ListItemIcon sx={{ color: "white", fontSize: "20px" }}>
                  {item.icon}
                </ListItemIcon>
                <Typography>
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{
                      fontSize: "16px",
                      fontFamily: "proxima_nova_rgregular",
                      marginLeft: "-20px",
                    }}
                    sx={{
                      color: "white",
                    }}
                  />
                </Typography>
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div
      className=" position-fixed top-0"
      style={{
        background: "#3cb6d3",
        zIndex: 100,
        width: "100%",
      }}
    >
      {/* header searchbar */}
      {showSearchbar && (
        <div
          tabIndex={-1}
          className="position-fixed w-100 h-100"
          style={{
            zIndex: 50,
          }}
        >
          {/* overlay */}
          <div
            onClick={() => setShowSearchbar(false)}
            className="w-100 h-100 position-fixed"
            style={{
              backgroundColor: "#00000085",
              zIndex: 1,
            }}
          ></div>
          <div
            className="position-fixed w-100 bg-cprimary py-3 b-shadow"
            style={{
              zIndex: 10,
            }}
          >
            <div className="container">
              <div className="bg-cprimary m-0 d-flex align-items-center">
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="places-search-bar position-relative justify-content-start p-0 d-flex flex-column align-items-center col-11">
                      <div className="d-flex w-100 align-items-center py-1">
                        <span
                          className="c-pointer"
                          onClick={() => {
                            if (address.length !== 0) {
                              setShowSearchbar(false);

                              if (
                                address.toLowerCase().includes("whitefield")
                              ) {
                                history.push(
                                  "/apartments/yqnMZNXH2IRG8TVxw694"
                                );
                              } else {
                                history.push("/not-found");
                              }
                            }
                          }}
                        >
                          <SearchIcon
                            style={{
                              color: "#ffffff",
                              margin: "0 7px",
                              fontSize: 28,
                            }}
                          />
                        </span>
                        <input
                          {...getInputProps({
                            placeholder:
                              "Search City, Neighbourhood or Address",
                          })}
                          className="m-0 text-white header-search-bar"
                          style={{
                            border: "none",
                            fontSize: isMobileScreen ? 15 : 20,
                            fontFamily: "proxima_nova_rgregular",
                          }}
                        />
                      </div>
                      <div
                        className="position-absolute bg-white left-0 right-0"
                        style={{
                          border: `${
                            suggestions.length ? "1" : "0"
                          }px solid rgba(0,0,0,.2)`,
                          backdropFilter: "blur(10px)",
                          zIndex: 2,
                          top: 67,
                          minWidth: "100%",
                        }}
                      >
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "rgba(0,0,0,.05)"
                              : "white",
                            color: "black",
                          };

                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                style,
                              })}
                            >
                              {suggestion.description && (
                                <div className="d-flex align-items-center border-bottom p-2 py-3 text-muted">
                                  <LocationOnIcon />
                                  <p className="result-item w-100 m-0 mx-2">
                                    {suggestion.description}
                                  </p>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

                <span
                  onClick={() => {
                    setAddress("");
                    setShowSearchbar(false);
                  }}
                  style={{
                    marginLeft: "auto",
                  }}
                  className="text-white c-pointer close-button p-2 transition-3s"
                >
                  <CloseIcon />
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer autoClose={3000} type="success" position="top-right" />

      <div
        className={`col-12 col-md-${
          smallHeader ? "9" : "12"
        } d-flex justify-content-start align-items-center py-3 container`}
      >
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            alignItems: "center",
            gap: 4,
          }}
        >
          <Link to="/" className="d-flex align-items-center">
            {/* <img
              src={logo}
              width="28"
              height="28"
              alt="logo"
              color="rgba(255,255,255,.8)"
            /> */}
            <h4
              className="mt-2"
              style={{
                fontFamily: "proxima_nova_rgbold",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontSize: 20 }}>OpenList.</span>
              <span className="in" style={{ fontSize: 14 }}>
                in
              </span>
            </h4>
          </Link>
          <Link to="/owners">
            <p
              style={{
                marginTop: "2px",
                color: "rgba(255,255,255,.8)",
                fontFamily: "proxima_nova_rgregular",
                margin: 0,
                padding: 0,
                fontSize: 19,
              }}
            >
              Owners
            </p>
          </Link>
          <Link to="/brokers">
            <p
              style={{
                marginTop: "2px",
                color: "rgba(255,255,255,.8)",
                fontFamily: "proxima_nova_rgregular",
                margin: 0,
                padding: 0,
                fontSize: 19,
              }}
            >
              Brokers
            </p>
          </Link>
          <Link to="/community">
            <p
              style={{
                marginTop: "2px",
                color: "rgba(255,255,255,.8)",
                fontFamily: "proxima_nova_rgregular",
                margin: 0,
                padding: 0,
                fontSize: 19,
              }}
            >
              Flat & Flatmates
            </p>
          </Link>
        </Box>

        {/* left drawer */}

        {/* <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Typography
            sx={{
              padding: 2,
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            <img src={logo} alt="logo" style={{ width: 50, fontWeight: 800 }} />
            UrbanLetting
          </Typography>
          <Divider />
          <List>
            {[
              "Post Property",
              "Callback Requests",
              "Requirements",
              "Onboarding Requests",
            ].map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                style={{
                  padding: 12,
                }}
              >
                <ListItemButton
                  sx={{
                    borderRadius: 3,
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      paddingRight: "8px",
                    }}
                  >
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <Typography>
                    <ListItemText primary={text} sx={{ paddingLeft: "2px" }} />
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <Divider />
        </Drawer> */}

        <Box
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        >
          <div style={{ display: "flex", gap: 0 }}>
            <div style={{ padding: 8, marginTop: 0 }}>
              <button
                style={{
                  border: "none",
                  background: "none",
                  padding: 0,
                  cursor: "pointer",
                }}
                onClick={toggleDrawer("left", true)}
              >
                <MenuIcon style={{ fontSize: 30, color: "white" }} />
              </button>
            </div>
            <div style={{ padding: 0, margin: 0 }}>
              <p style={{ color: "white", marginTop: 9 }}>
                <span
                  style={{ fontSize: 22, fontFamily: "proxima_nova_rgbold" }}
                >
                  OpenList
                </span>
                <span
                  className="in"
                  style={{ fontSize: 14, fontFamily: "proxima_nova_rgbold" }}
                ></span>
              </p>
            </div>
          </div>
          <SwipeableDrawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
            PaperProps={{
              style: {
                background: "#3cb6d3",
              },
            }}
          >
            {list("left")}
          </SwipeableDrawer>
        </Box>

        <div
          className="text-decoration-none text-white"
          style={{
            marginLeft: "auto",
            // height: 70,
          }}
        >
          {user === undefined ? (
            <SvgLoader />
          ) : user !== null ? (
            user.email === undefined ? (
              <SvgLoader />
            ) : (
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "flex",
                    alignItems: "center",
                    gap: 3,
                  },
                }}
              >
                <Box style={{ display: user.fullname ? "block" : "flex" }}>
                  {" "}
                  <span
                    className="text-animation transition-3s user-select-none d-inline-block text-truncate"
                    style={{
                      maxWidth: !isMobileScreen ? 200 : 100,
                      verticalAlign: "bottom",
                      fontFamily: "proxima_nova_rgbold",
                    }}
                  >
                    {user.fullname}
                  </span>
                  <h5
                    className="m-0 p-0 text-end text-animation transition-3s user-select-none c-pointer"
                    style={{
                      fontSize: 14,
                      fontFamily: "proxima_nova_rgbold",
                    }}
                    onClick={() =>
                      swal({
                        title: "Want to Sign out?",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          firebase.auth().signOut();
                          history.push("/");
                          swal("You are successfully Signed Out!", {
                            icon: "success",
                            closeOnClickOutside: true,
                            button: false,
                            timer: 2000,
                          });
                        }
                      })
                    }
                  >
                    Sign out
                  </h5>
                </Box>

                <div>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <Badge
                      badgeContent={unseenNotificCount}
                      color="primary"
                      sx={{
                        fontSize: "20px",
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      <IoNotifications
                        style={{ fontSize: "29px" }}
                        color="action"
                      />
                    </Badge>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                    sx={{ left: "160px", top: "15px" }}
                  >
                    {user?.notifications?.map((notification) => (
                      <MenuItem
                        key={notification.id}
                        onClick={() =>
                          handleShowNotificationPost(
                            notification.postId,
                            notification.id
                          )
                        }
                      >
                        <Typography
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 16,
                            position: "relative",
                          }}
                        >
                          <Typography
                            style={{
                              borderRadius: 6,
                              padding: 9,
                              color: `${
                                notification?.isSeen ? "gray" : "black"
                              }`,
                              fontFamily: `proxima_nova_rg${
                                notification?.isSeen ? "bold" : "bold"
                              }`,
                              display: "flex",
                              gap: 6,
                            }}
                          >
                            <Avatar
                              alt={notification.userName}
                              src="none"
                              style={{ width: 35, height: 35 }}
                            />
                            <Typography>{notification.text}</Typography>
                          </Typography>
                          <Typography
                            sx={{
                              color: `${
                                notification?.isSeen ? "gray" : "black"
                              }`,
                              fontFamily: `proxima_nova_rg${
                                notification?.isSeen ? "regular" : "bold"
                              }`,
                              position: "absolute",
                              left: "50px",
                              top: "26px",
                            }}
                          >
                            <Moment
                              fromNow
                              style={{
                                fontFamily: `proxima_nova_rg${
                                  notification?.isSeen ? "regular" : "bold"
                                }`,
                                fontSize: "13px",
                              }}
                            >
                              {notification.timestamp.toDate()}
                            </Moment>
                          </Typography>
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </Box>
            )
          ) : (
            <h5
              className="m-0 text-end mx-2 text-animation transition-3s user-select-none c-pointer"
              style={{
                fontSize: 16,
                font: "proximanovabold",
              }}
              onClick={() => {
                setAuthModalConfig({ show: true, type: "Login" });
              }}
            >
              Login
            </h5>
          )}
        </div>
        {isCommunity && (
          <div
            onClick={() => {
              setShowChatList(!showChatList);
              setShowChatBox(false);
            }}
            className={`c-pointer text-white mx-${user ? "3" : "0"}`}
          >
            <Badge badgeContent={unreadMessagesCount} color="primary">
              <img src={Messenger} width="28" alt="Messenger" height="28" />
            </Badge>
          </div>
        )}
        {!isMobileScreen && !isCommunity && (
          <Link
            className={`btn mx-${
              user ? "2" : "0"
            } border-0 text-capitalize font-weight-bold bg-white`}
            style={{
              borderRadius: "20px",
              color: "#3cb6d3",
              lineHeight: "26px",
              fontWeight: 500,
              fontSize: 12,
              fontFamily: "proxima_nova_rgbold",
            }}
            // onClick={() => {
            //   if (!user) {
            //     setAuthModalConfig({ show: true, type: "Login" });
            //   }
            // }}
            to="/post-for-free"
          >
            Post for Free
          </Link>
        )}
        {user && !isCommunity && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: isMobileScreen ? "block" : "none" }}>
              {" "}
              <span
                className="text-animation transition-3s user-select-none d-inline-block text-truncate"
                style={{
                  maxWidth: !isMobileScreen ? 200 : 100,
                  verticalAlign: "bottom",
                  fontFamily: "proxima_nova_rgbold",
                  color: "white",
                }}
              >
                {user.fullname}
              </span>
              <h5
                className="m-0 p-0 text-end text-animation transition-3s user-select-none c-pointer"
                style={{
                  fontSize: 14,
                  fontFamily: "proxima_nova_rgbold",
                  color: "white",
                }}
                onClick={() =>
                  swal({
                    title: "Want to Sign out?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      firebase.auth().signOut();
                      history.push("/");
                      swal("You are successfully Signed Out!", {
                        icon: "success",
                        closeOnClickOutside: true,
                        button: false,
                        timer: 2000,
                      });
                    }
                  })
                }
              >
                Sign out
              </h5>
            </Box>
            <Link to="/profile" className="mx-2 mx-md-0 c-pointer">
              <Avatar alt={user.fullname} src="none" />
            </Link>
          </Box>
        )}
      </div>
      {showNotificatiosPost && postData && (
        <CommunityPostCardsPopUp
          post={postData}
          user={user}
          showPost={true}
          setPopUpFalse={setPopUpFalse}
          allComments={sortedComments}
          handleSubmitComment={handleSubmitComment}
          loading={loading}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    welcomeMsgConfig: state.user.welcomeMsgConfig,
    showChatList: state.community.showChatList,
    unreadMessagesCount: state.firestore.ordered?.unreadMessages?.length,
    userDoc: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAuthModalConfig: (obj) => dispatch(setAuthModalConfig(obj)),
  setUser: (val) => dispatch(setUser(val)),
  setShowChatList: (val) => dispatch(setShowChatList(val)),
  setShowChatBox: (val) => dispatch(setShowChatBox(val)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header);
