import "./property-filters.css";
import { useEffect, useState } from "react";
import { setFilterArray } from "../../../../store/actions/actions";
import { connect } from "react-redux";
import { MultiRangeSlider } from "../../../common/multi-range-slider/multi-range-slider";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CustomInputTypeform from "../../../common/custom-input-typeform/custom-input-typeform";
import firebase from "../../../../config/fbConfig";
import { FaRegBuilding } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { VscDebugRestart } from "react-icons/vsc";
import { useMediaQuery } from "react-responsive";
import { Box, Container } from "@material-ui/core";
import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AppBar } from "@mui/material";
import { NotificationsNone } from "@material-ui/icons";

const APARTMENT_TYPES = [1, 2, 3, 4, 5];
const PROPERTY_TYPES = ["Apartment", "Independent"];
const FURNISHING_TYPES = ["Furnished", "Semi-furnished", "Un-furnished"];
const IDEAL_FOR_TYPES = ["Family", "Bachelors", "Couple"];
const CATEGORY_TYPES = ["Posted by Owner", "Posted by Agent"];

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  fontFamily: "proxima_nova_rgregular",
  textTransform: "none",
  fontSize: "17px",
  position: "relative",
  zIndex: 999,
  color: "black",
  backgroundColor: "#eaeeee",
  borderRadius: "20px",
  padding: "5px 19px",
  "&:hover": {
    backgroundColor: "#eaeeee",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "#eaeeee",
    boxShadow: "none",
  },
  "&:focus": {
    backgroundColor: "#eaeeee",
    boxShadow: "none",
  },
}));

const PropertyFilters = (props) => {
  const {
    setFilterArray,
    filterArray,
    selectedFurnishingTypes,
    setSelectedFurnishingTypes,
    selectedPropertyTypes,
    setSelectedPropertyTypes,
    selectedApartmentTypes,
    setSelectedApartmentTypes,
    setMinRentPrice,
    setMaxRentPrice,
    resetAllFilters,
    selectedLooking,
    setSelectedLooking,
    selectedCat,
    setSelectedCat,
    societyFilter,
    setSocietyFilter,
    setShowAllFilters,
    showAllFilters,
    minRentPrice,
    maxRentPrice,
  } = props;

  const [normalFilter, setNormalFilter] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [tempInp, setTempInp] = useState("");
  const [alignment, setAlignment] = useState("normalFilter");
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1800px)" });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openMenu, setOpenMenu] = useState(null);
  const handleClick = (event, menu) => {
    if (openMenu === menu) {
      handleClose(); // Close the menu if it's already open
    } else {
      setAnchorEl(event.currentTarget);
      setOpenMenu(menu);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
  };

  const isSuggContains = (arr, val) => arr.find((item) => item.name === val);

  useEffect(() => {
    firebase
      .firestore()
      .collection("city")
      .doc("SSX77pJNAU5wvOIwECnW")
      .collection("locality")
      .doc("yqnMZNXH2IRG8TVxw694")
      .collection("societies")
      .onSnapshot((d) => {
        if (d.size) {
          setSuggestions(
            d.docs.map((d) => ({
              name: d.data().name,
              id: d.id,
            }))
          );
        }
      });
  }, []);

  const isTypeSelected = (arr, selectedType) =>
    arr.find((type) => type === selectedType);

  const handleChange = (event, filter) => {
    setAlignment(filter);
    if (filter === "normalFilter") setNormalFilter(true);
    else setNormalFilter(false);
  };

  return (
    <Box
      style={{
        background: "white",
        position: "fixed",
        zIndex: 999,
        width: "100vw",
        height: "4.5rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Container
        style={{
          maxWidth: "96rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            {" "}
            <div className="">
              <CustomInputTypeform
                type="text"
                label="Society name"
                value={tempInp}
                onChangeHandler={(val) => {
                  setTempInp(val);
                  const isSugg = isSuggContains(suggestions, val);

                  setSocietyFilter(isSugg ? val : "");
                  setTempInp(isSugg ? "" : val);
                }}
                id="data"
                isOptions
                isLessHeight
              />

              <datalist
                id="data"
                style={{
                  maxHeight: 500,
                }}
              >
                {suggestions.map((item) => (
                  <option key={item.id} value={item.name} />
                ))}
              </datalist>
            </div>
            <div>
              {societyFilter && (
                <span
                  className="text-muted py-2 px-3 my-2 bg-white"
                  style={{
                    fontSize: 11,
                    borderRadius: 99,
                  }}
                >
                  {societyFilter}
                </span>
              )}
            </div>
          </div>
          <div>
            <CustomButton
              id="demo-customized-button"
              aria-controls={
                openMenu === "propertyType" ? "propertyType-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={openMenu === "propertyType" ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={(event) => handleClick(event, "propertyType")}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Type
            </CustomButton>
            <StyledMenu
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openMenu === "propertyType"}
              onClose={handleClose}
            >
              <div
                className={`d-flex flex-wrap  ${
                  isMobileScreen ? "flex-gap" : "gap-2"
                }`}
              >
                {PROPERTY_TYPES.map((propertyType) => (
                  <MenuItem
                    disableRipple
                    key={propertyType}
                    className="user-select-none text-center c-pointer border px-3 py-1"
                    style={{
                      fontFamily: "proxima_nova_rgregular",
                      borderRadius: "20px",
                      cursor: "pointer",
                      fontSize: "18px",
                      background:
                        selectedPropertyTypes === propertyType
                          ? "#3cb6d3"
                          : "white",
                      color:
                        selectedPropertyTypes === propertyType
                          ? "#fff"
                          : "black",
                    }}
                    onClick={(e) => {
                      handleClose();
                      setSelectedPropertyTypes(
                        selectedPropertyTypes === propertyType
                          ? ""
                          : propertyType
                      );
                    }}
                  >
                    {propertyType === "Apartment" ? (
                      <FaRegBuilding style={{ marginRight: "6px" }} />
                    ) : (
                      <IoHome style={{ marginRight: "6px" }} />
                    )}

                    {propertyType}
                  </MenuItem>
                ))}
              </div>
            </StyledMenu>
          </div>
          <div>
            <CustomButton
              id="demo-customized-button"
              aria-controls={openMenu === "rent" ? "rent-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu === "rent" ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={(event) => handleClick(event, "rent")}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Rent
            </CustomButton>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openMenu === "rent"}
              onClose={handleClose}
            >
              <div className="row mt-2" style={{ width: "500px" }}>
                <div className="d-flex flex-grow-1 align-items-center">
                  <div className="me-2">
                    <div
                      className="price-box"
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        width: "65px",
                        height: "40px",
                        display: "flex", // Enable Flexbox
                        alignItems: "center", // Center vertically
                        justifyContent: "center", // Center horizontally
                        fontFamily: "proxima_nova_rgregular", // Optional: set a font family
                      }}
                    >
                      ${minRentPrice}
                    </div>
                  </div>

                  <div
                    className="flex-grow-1"
                    style={{
                      marginLeft: isMobileScreen ? " 0px" : "20px",
                      marginRight: isMobileScreen ? "10px" : "30px",
                    }}
                  >
                    <div className="mb-2">
                      <MultiRangeSlider
                        min={0}
                        max={100000}
                        onChange={({ min, max }) => {
                          setMinRentPrice(min);
                          setMaxRentPrice(max);
                        }}
                        style={{ display: "block" }}
                      />
                    </div>
                  </div>

                  <div>
                    <div
                      className="price-box"
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        width: "65px",
                        height: "40px",
                        display: "flex", // Enable Flexbox
                        alignItems: "center", // Center vertically
                        justifyContent: "center", // Center horizontally
                        fontFamily: "proxima_nova_rgregular", // Optional: set a font family
                      }}
                    >
                      ${maxRentPrice}
                    </div>
                  </div>
                </div>
              </div>
            </StyledMenu>
          </div>
          <div>
            <CustomButton
              id="demo-customized-button"
              aria-controls={
                openMenu === "bedrooms" ? "bedrooms-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={openMenu === "bedrooms" ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={(event) => handleClick(event, "bedrooms")}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Beds
            </CustomButton>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openMenu === "bedrooms"}
              onClose={handleClose}
            >
              <div
                className={`d-flex flex-wrap  ${
                  isMobileScreen ? "flex-gap" : "gap-2"
                }`}
              >
                {APARTMENT_TYPES.map((apartmentType) => (
                  <span
                    key={apartmentType}
                    className="px-4 py-2 user-select-none border"
                    style={{
                      cursor: "pointer",
                      fontSize: "18px",
                      borderRadius: 99,
                      background: isTypeSelected(
                        selectedApartmentTypes,
                        apartmentType
                      )
                        ? "#3cb6d3"
                        : "white",
                      color: isTypeSelected(
                        selectedApartmentTypes,
                        apartmentType
                      )
                        ? "#fff"
                        : "black",
                    }}
                    onClick={() => {
                      if (
                        isTypeSelected(selectedApartmentTypes, apartmentType)
                      ) {
                        setSelectedApartmentTypes((prev) =>
                          prev.filter((type) => type !== apartmentType)
                        );
                      } else {
                        setSelectedApartmentTypes((prev) => [
                          ...prev,
                          apartmentType,
                        ]);
                      }
                    }}
                  >
                    {apartmentType}
                  </span>
                ))}
              </div>
            </StyledMenu>
          </div>

          <Button
            sx={{
              borderRadius: "20px",
              padding: "4px 12px",
              fontFamily: "proxima_nova_rgregular",
              fontSize: "17px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#E4F5FF",
                boxShadow: "none",
              },
              "&:active": {
                backgroundColor: "#E4F5FF",
                boxShadow: "none",
              },
              "&:focus": {
                backgroundColor: "#E4F5FF",
                boxShadow: "none",
              },
            }}
            variant="outlined"
            onClick={() => setShowAllFilters((prev) => !prev)}
          >
            All filters
          </Button>

          <Button
            sx={{
              borderRadius: "20px",
              padding: "5px 12px",
              fontFamily: "proxima_nova_rgregular",
              fontSize: "17px",
              textTransform: "none",
            }}
            variant="contained"
            startIcon={<NotificationsNone />}
          >
            Save Alert
          </Button>
        </div>
        <div style={{}}>
          <Button
            sx={{
              borderRadius: "20px",
              padding: "5px 12px",
              fontFamily: "proxima_nova_rgregular",
              fontSize: "17px",
              color: "gray",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#E4F5FF",
                boxShadow: "none",
              },
              "&:active": {
                backgroundColor: "#E4F5FF",
                boxShadow: "none",
              },
              "&:focus": {
                backgroundColor: "#E4F5FF",
                boxShadow: "none",
              },
            }}
            variant="text"
            startIcon={<NotificationsNone />}
          >
            Alerts
          </Button>
        </div>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  filterArray: state.apartment.filterArray,
});

const mapDispatchToProps = (dispatch) => ({
  setFilterArray: (arr) => dispatch(setFilterArray(arr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyFilters);
