import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import Loader from "../../../common/loader/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import StarIcon from "@material-ui/icons/Star";
import ContactForm from "../contact-form/contact-form";
import ReplyIcon from "@mui/icons-material/Reply";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ReactComponent as MaintenanceIncludedIcon } from "../../../../assets/maintenanceIncluded.svg";
import { ReactComponent as MaintenanceExtraIcon } from "../../../../assets/maintenanceExtra.svg";

import CameraAltIcon from "@mui/icons-material/CameraAlt";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import { useParams } from "react-router-dom";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./apartment-details.css";

import Lightbox from "react-image-lightbox";

import Map from "../../../common/google-map/google-map";
import { useMediaQuery } from "react-responsive";
import { FeaturesDisplay } from "../features-display/features-display";
import { setAuthModalConfig } from "../../../../store/actions/actions";
import { scrollToTop } from "../../../../utils/common-functions";
import SwiperCore, { Navigation, Pagination } from "swiper";
import DateFormatter from "../../../../common/DateFormatter";
import RequestCallback from "../contact-form/RequestCallback";
import { setUploadingLoader } from "../../../../store/actions/actions";

SwiperCore.use([Navigation, Pagination]);

const AboutChips = ({ name, value }) => (
  <div className="d-flex my-2">
    <div className="col-6 fc-5c" style={{}}>
      {name}
    </div>
    <div
      className="col-6 fc-75"
      style={{
        fontFamily: "proxima_nova_rgregular",
      }}
    >
      {value ? value : "No Info"}
    </div>
  </div>
);

const ApartmentDetails = (props) => {
  const { setAuthModalConfig, setUploadingLoader } = props;
  const user = useSelector((state) => state.user.user);
  const [showRequestCallback, setShowRequestCallback] = useState(false);
  const [readMore, setReadMore] = useState(true);
  const [featureType, setFeatureType] = useState("unitFeatures");
  const [currentDisplay, setCurrentDisplay] = useState("IMG");
  const [docRef] = useState("");
  const [photoIndex, setPhotoIndex] = useState(0);
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const [showBigPic, setBigPic] = useState(false);
  const contactFormDiv = useRef();

  const [apartment, setApartment] = useState(null);
  const [loading, setLoading] = useState(true);
  const { locality } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");

  useEffect(() => {
    console.log({ props });
    if (props.apartmentDet?.[0]) {
      setApartment(props.apartmentDet[0]);
    } else {
      const fetchApartment = async () => {
        try {
          const res = await axios.get(
            `https://urbanletting-6afef.df.r.appspot.com/properties/detail?city=bangalore&locality=${locality}&id=${id}`
          );
          console.log("response", res.data);
          setApartment(res.data);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      };
      fetchApartment();
    }
    scrollToTop();
    window.addEventListener("scroll", handleScroll, true);
  }, []);

  const handleScroll = (e) => {
    // Your code
    const height = document.documentElement.scrollTop;
    const STICK_FORM = 130;
    const MAX_FORM = 1320;

    if (contactFormDiv.current)
      contactFormDiv.current.style.marginTop = `${
        height > STICK_FORM && height < MAX_FORM ? height - STICK_FORM : 0
      }px`;
  };

  useEffect(() => {
    console.log({ loading, apartment, id });
  });

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          <div
            className="py-4 apartment-details m-0"
            style={{ backgroundColor: "#ffffff" }}
          >
            {apartment && apartment !== undefined ? (
              <div className="h-100 w-100">
                {/* row two */}
                {!isMobileScreen && (
                  <div className="bg-white m-0 mt-4">
                    <div className="container p-md-0 col-12 col-md-9 border-bottom">
                      <div className="row pb-4 d-flex align-items-center m-0">
                        <div className="d-flex flex-column col-12 col-md-6 p-md-0">
                          <div className="d-flex align-items-center">
                            <h2 className="fc-cprimary m-0">
                              ₹ {apartment.cost.toLocaleString()}
                            </h2>
                            <span className="mx-2 text-muted">•</span>
                            <h5 className="fc-5c m-0">
                              {" "}
                              {apartment.propertyType}
                            </h5>
                            <span className="mx-2 text-muted">•</span>
                            <span className="text-muted mx-1">
                              <AccessTimeIcon />
                            </span>
                            <h6
                              className="fc-75 m-0 mt-1"
                              style={{
                                fontFamily: "proxima_nova_rgregular",
                              }}
                            >
                              <DateFormatter postedOn={apartment.postedOn} />
                            </h6>
                          </div>
                          <div className="mt-3">
                            <h5
                              className="m-0 fc-75"
                              style={{
                                fontFamily: "proxima_nova_rgregular",
                              }}
                            >
                              {apartment.societyName} - {apartment.localeName},{" "}
                              {apartment.city}
                            </h5>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 my-3 my-md-0 p-md-0">
                          <div className="row w-100 m-0">
                            <div className="col-4 p-0 text-muted text-end p-md-0">
                              <HotelOutlinedIcon className="fc-cprimary" />
                              <span className="pt-2 fc-75 mx-2">
                                {apartment.bedroom} Bed
                              </span>
                            </div>
                            <div className="col-4 p-0 text-muted text-end p-md-0">
                              <BathtubOutlinedIcon className="fc-cprimary" />
                              <span className="pt-2 fc-75 mx-2">
                                {apartment.bathroom} Bath
                              </span>
                            </div>
                            <div className="col-4 p-0 text-muted text-end p-md-0">
                              <SquareFootOutlinedIcon className="fc-cprimary" />
                              <span className="pt-2 fc-75 mx-2">
                                {apartment.area} FT²
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="bg-white m-0">
                  <div className="container p-md-0 col-12 col-md-9">
                    <div className="py-0 py-md-4 d-flex justify-content-between flex-column flex-md-row">
                      <div
                        className="col-12 col-md-7 m-0 p-0 position-relative"
                        style={{
                          backgroundColor: "#ffffff",
                        }}
                      >
                        {" "}
                        {showBigPic && (
                          <Lightbox
                            enableZoom={false}
                            mainSrc={apartment.images[photoIndex]}
                            nextSrc={
                              apartment.images[
                                (photoIndex + 1) % apartment.images.length
                              ]
                            }
                            prevSrc={
                              apartment.images[
                                (photoIndex + apartment.images.length - 1) %
                                  apartment.images.length
                              ]
                            }
                            onCloseRequest={() => setBigPic(false)}
                            onMovePrevRequest={() =>
                              setPhotoIndex(
                                (photoIndex + apartment.images.length - 1) %
                                  apartment.images.length
                              )
                            }
                            onMoveNextRequest={() =>
                              setPhotoIndex(
                                (photoIndex + 1) % apartment.images.length
                              )
                            }
                          />
                        )}
                        <div
                          style={{
                            height: 350,
                          }}
                          className="bg-white border position-relative"
                        >
                          {currentDisplay === "IMG" && (
                            <div className="w-100 h-100 position-relative">
                              <div
                                className="position-absolute w-100 d-flex"
                                style={{
                                  zIndex: 99,
                                }}
                              >
                                <div
                                  className="p-2 ms-auto c-pointer m-2 text-white rounded-circle"
                                  style={{
                                    backgroundColor: "rgba(28,86,99,.5)",
                                  }}
                                >
                                  <ReplyIcon />
                                </div>
                                {/* 
                          <div
                            className="p-2 m-2 c-pointer text-white rounded-circle"
                            style={{
                              backgroundColor: "rgba(28,86,99,.5)",
                            }}
                          >
                            {isFav ? (
                              <FavoriteOutlinedIcon
                                style={{
                                  color: "red",
                                }}
                              />
                            ) : (
                              <FavoriteTwoToneIcon />
                            )}
                          </div> */}
                              </div>

                              {apartment.images.length > 3 &&
                              !isMobileScreen ? (
                                <div className="d-flex h-100">
                                  <img
                                    className="w-50"
                                    src={apartment.images[0]}
                                    alt="architecture"
                                    onClick={() => {
                                      setPhotoIndex(0);
                                      setBigPic(true);
                                    }}
                                  />

                                  <div className="position-relative w-50">
                                    <div
                                      className="position-absolute d-flex align-items-center justify-content-center text-white w-100 h-100"
                                      style={{
                                        background: "#00000090",
                                      }}
                                      onClick={() => {
                                        setPhotoIndex(1);
                                        setBigPic(true);
                                      }}
                                    >
                                      <h1
                                        style={{
                                          fontSize: 70,
                                        }}
                                      >
                                        +{apartment.images.length + 1}
                                      </h1>
                                    </div>
                                    <img
                                      className="w-100 h-100"
                                      src={apartment.images[1]}
                                      alt="architecture"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <Swiper
                                  navigation={true}
                                  pagination={{
                                    type: "fraction",
                                  }}
                                  style={{
                                    border: "none",
                                    height: "100%",
                                  }}
                                  grabCursor={true}
                                  slidesPerView={1}
                                  className="mySwiper p-0 bg-white"
                                >
                                  {apartment.images.map((img, index) => (
                                    <SwiperSlide key={img}>
                                      <div
                                        className="swiper-slide-img"
                                        onClick={() => {
                                          setPhotoIndex(index);
                                          setBigPic(true);
                                        }}
                                      >
                                        <img
                                          width="100%"
                                          height="100%"
                                          src={img}
                                          className="img-fluid"
                                          alt="apartment"
                                          loading="lazy"
                                        />
                                      </div>
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                              )}
                            </div>
                          )}

                          {currentDisplay === "VID" &&
                            apartment.videos.length > 0 && (
                              <video
                                className="p-0"
                                src={apartment.videos[0]}
                                alt="Selected file"
                                controls
                                width="100%"
                                height="100%"
                              ></video>
                            )}

                          {currentDisplay === "MAP" && (
                            <div className="col-12 bg-white w-100 h-100">
                              <Map
                                isMarkerShown
                                latlng={{
                                  isFixed: true,
                                  lat: apartment.latLng.latitude,
                                  lng: apartment.latLng.longitude,
                                }}
                              />
                            </div>
                          )}
                        </div>
                        {apartment.videos.length === 0 && !apartment.latLng ? (
                          <></>
                        ) : (
                          <div className="col-12 bg-white m-0">
                            <div className="row m-0">
                              <div
                                onClick={() => setCurrentDisplay("IMG")}
                                className={`col d-flex c-pointer align-items-center py-3 justify-content-center${
                                  currentDisplay === "IMG"
                                    ? " fc-cprimary"
                                    : " fc-1c-muted"
                                }`}
                                style={{
                                  border: "1px solid #00000020",
                                }}
                              >
                                <CameraAltIcon />
                                {!isMobileScreen && (
                                  <span className="mx-2">Images</span>
                                )}
                              </div>
                              {apartment.videos.length !== 0 && (
                                <div
                                  onClick={() => setCurrentDisplay("VID")}
                                  className={`col d-flex c-pointer align-items-center py-3 justify-content-center${
                                    currentDisplay === "VID"
                                      ? " fc-cprimary"
                                      : " fc-1c-muted"
                                  }`}
                                  style={{
                                    border: "1px solid #00000020",
                                  }}
                                >
                                  <OndemandVideoIcon />
                                  {!isMobileScreen && (
                                    <span className="mx-2">Video</span>
                                  )}
                                </div>
                              )}
                              {apartment.latLng && (
                                <div
                                  onClick={() => setCurrentDisplay("MAP")}
                                  className={`col d-flex c-pointer align-items-center py-3 justify-content-center${
                                    currentDisplay === "MAP"
                                      ? " fc-cprimary"
                                      : " fc-1c-muted"
                                  }`}
                                  style={{
                                    border: "1px solid #00000020",
                                  }}
                                >
                                  <MyLocationIcon />

                                  {!isMobileScreen && (
                                    <span className="mx-2">Location</span>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {/* only for mobile view */}
                        {isMobileScreen && (
                          <div className="bg-white m-0 row">
                            <div className="border-bottom pb-3">
                              <h1 className="fc-cprimary m-0 mt-4 mb-2">
                                ₹{apartment.cost.toLocaleString()}
                              </h1>
                              <div className="d-flex align-items-center">
                                <h5 className="fc-1c m-0">
                                  {apartment.propertyType}
                                </h5>
                                <span className="mx-2 text-muted">•</span>
                                <span className="text-muted mx-1">
                                  <AccessTimeIcon />
                                </span>
                                <h6 className="fc-1c m-0 mt-1 text-muted">
                                  <DateFormatter
                                    postedOn={apartment.postedOn}
                                  />
                                </h6>
                              </div>
                              <div className="mt-3">
                                <h5 className="m-0 text-muted fc-1c">
                                  {apartment.societyName} -{" "}
                                  {apartment.localeName}, {apartment.city}
                                </h5>
                              </div>
                            </div>

                            <div className="mt-4 border-bottom">
                              <div className="row">
                                <div className="col-6 d-flex align-items-center text-muted">
                                  <HotelOutlinedIcon className="fc-cprimary" />

                                  <span className="pt-1 fc-75 mx-2">
                                    {apartment.bedroom} Bed
                                  </span>
                                </div>
                                <div className="col-6 d-flex align-items-center text-muted">
                                  <BathtubOutlinedIcon className="fc-cprimary" />
                                  <span className="pt-1 fc-75 mx-2">
                                    {apartment.bathroom} Bath
                                  </span>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6 d-flex align-items-center text-muted">
                                  <SquareFootOutlinedIcon className="fc-cprimary" />
                                  <span className="pt-1 fc-75 mx-2">
                                    {apartment.area} FT²
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row m-0 p-2">
                          <div className="col-12 d-flex flex-column my-3">
                            <h5 className="m-0 my-2 fc-1c">
                              {apartment.address}
                            </h5>
                          </div>
                          <div className="col-12 d-flex flex-column align-items-start pb-4">
                            <p
                              className={`fc-1c ${
                                readMore ? "trunc-nline" : ""
                              } apartment-desc`}
                              style={{
                                fontSize: 16,
                                lineHeight: 1.8,
                                fontFamily: "proxima_nova_rgregular",
                                "--lines": 10,
                              }}
                              dangerouslySetInnerHTML={{
                                __html: apartment.desc,
                              }}
                            />

                            {apartment.desc.length > 200 && (
                              <button
                                onClick={() => setReadMore((prev) => !prev)}
                                className="tag align-self-start p-2 px-3 bg-white"
                                style={{
                                  borderRadius: "20px",
                                  color: "#3cb6d3",
                                  fontWeight: 500,
                                  fontSize: 12,
                                  border: "1px solid #3cb6d3",
                                }}
                              >
                                Read {readMore ? "More" : "Less"}
                              </button>
                            )}
                          </div>
                          <div className="col-12 d-flex flex-column align-items-start mt-4 pt-4 pb-3 px-0 mx-0">
                            <div className="row w-100 mx-0">
                              <h4 className="m-0 fc-1c pb-3">
                                Features & Amenities
                              </h4>

                              <div className="row col-12 p-0 m-0">
                                {isMobileScreen ? (
                                  <FeaturesDisplay
                                    options={[
                                      {
                                        key: "unitFeatures",
                                        name: "Unit Features",
                                      },
                                      {
                                        key: "societyFeatures",
                                        name: "Society Features",
                                      },
                                    ]}
                                    features={apartment.features}
                                  />
                                ) : (
                                  <div className="row m-0">
                                    <div
                                      className="col-12 mb-4 mb-md-0 col-md-5 p-0 pt-3 d-flex flex-row flex-md-column flex-gap flex-wrap"
                                      style={{
                                        borderRight: isMobileScreen
                                          ? "none"
                                          : "1px solid #00000020",
                                        borderBottom: !isMobileScreen
                                          ? "none"
                                          : "1px solid #00000020",
                                      }}
                                    >
                                      <p
                                        className={`user-select-none c-pointer fc-1c px-4 py-3 features-chip${
                                          featureType === "unitFeatures"
                                            ? " fc-1c chip-selected"
                                            : ""
                                        }`}
                                        onClick={(e) => {
                                          setFeatureType("unitFeatures");
                                        }}
                                      >
                                        <DomainOutlinedIcon />
                                        <span
                                          className="pt-2 mx-2"
                                          style={
                                            featureType === "unitFeatures"
                                              ? {}
                                              : {
                                                  fontFamily:
                                                    "proxima_nova_rgregular",
                                                }
                                          }
                                        >
                                          Unit Features (
                                          {
                                            apartment.features["unitFeatures"]
                                              .length
                                          }
                                          )
                                        </span>
                                      </p>
                                      <p
                                        className={`user-select-none c-pointer fc-1c px-4 py-3 features-chip${
                                          featureType === "societyFeatures"
                                            ? " fc-1c chip-selected"
                                            : ""
                                        }`}
                                        onClick={(e) => {
                                          setFeatureType("societyFeatures");
                                        }}
                                      >
                                        <EmojiPeopleOutlinedIcon />
                                        <span
                                          className="pt-2 mx-2"
                                          style={
                                            featureType === "societyFeatures"
                                              ? {}
                                              : {
                                                  fontFamily:
                                                    "proxima_nova_rgregular",
                                                }
                                          }
                                        >
                                          Society Features (
                                          {
                                            apartment.features[
                                              "societyFeatures"
                                            ].length
                                          }
                                          )
                                        </span>
                                      </p>
                                    </div>
                                    <div
                                      className="col-12 col-md-7 pt-3 m-0"
                                      style={{
                                        maxHeight: 220,
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                      }}
                                    >
                                      {apartment.features[featureType]
                                        .length === 0 && (
                                        <div className="d-flex align-items-center justify-content-center text-muted w-100">
                                          <p> No features </p>
                                        </div>
                                      )}
                                      <div className="row w-100">
                                        {apartment.features[featureType].map(
                                          (item) => (
                                            <div className="col-12 col-md-6">
                                              <p
                                                key={item}
                                                className="w-100 p-0 px-3 py-2 features-chip2"
                                                style={{
                                                  color: "#27788a",
                                                  fontFamily:
                                                    "proxima_nova_rgregular",
                                                  fontSize: 14,
                                                }}
                                              >
                                                {item}
                                              </p>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="d-flex flex-wrap tags">
                              {[...new Set(apartment.amenities)].map((tag) => (
                                <div
                                  className="user-select-none text-muted py-1 border px-2 my-2 tag d-flex justify-content-center align-items-center"
                                  key={tag}
                                  style={{
                                    fontSize: 12,
                                    background: "#f3f4f5",
                                  }}
                                >
                                  <StarIcon
                                    style={{
                                      fontSize: 14,
                                    }}
                                  />
                                  <span className="mx-1">{tag}</span>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="col-12 d-flex flex-column align-items-start mt-4 pb-3 px-0 mx-0">
                            <div className="row w-100 mx-0">
                              <div
                                className="row rounded col-12 p-0 m-0 py-3"
                                style={{
                                  backgroundColor: "#f3f4f5",
                                }}
                              >
                                <div className="d-flex justify-content-between my-2">
                                  <p className="fc-5c">Monthly Rent</p>

                                  <div className="mx-1 d-flex">
                                    <h5 className="fc-cprimary m-0">
                                      ₹ {apartment.cost.toLocaleString()}
                                    </h5>
                                    {apartment.maintenanceIncluded ===
                                    "Extra" ? (
                                      <div className="m-0 mb-4 c-pointer maintenance-chip position-relative">
                                        <p className="position-absolute bg-cprimary rounded text-white px-2 py-1 text-center">
                                          ₹{" "}
                                          {parseInt(
                                            apartment.maintenance
                                          ).toLocaleString()}{" "}
                                          Maintenance
                                        </p>
                                        <span className="mx-1">+</span>
                                        <MaintenanceExtraIcon />{" "}
                                      </div>
                                    ) : (
                                      <div className="m-0 mb-4 c-pointer maintenance-chip position-relative">
                                        <p className="position-absolute bg-cprimary rounded text-white px-2 py-1 text-center">
                                          No Extra Maintenance
                                        </p>
                                        <span className="mx-1">+</span>
                                        <MaintenanceIncludedIcon />{" "}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="my-2">
                                  <div className="d-flex justify-content-between">
                                    <p className="m-0 fc-5c">
                                      Security Deposit
                                    </p>
                                    <h5 className="fc-cprimary m-0">
                                      ₹ {apartment.deposit.toLocaleString()}
                                    </h5>
                                  </div>
                                  <p
                                    style={{
                                      fontFamily: "proxima_nova_rgregular",
                                    }}
                                    className="fc-1c mb-0"
                                  >
                                    Fully refundable if vacated in original
                                    condition (T&C Apply)
                                  </p>
                                </div>

                                {/* {(apartment.type === "managed" ||
                            apartment.category === "managed") && (
                            <div className="my-2">
                              <div className=" d-flex justify-content-between">
                                <p className="m-0 fc-5c">
                                  One Time Service Fees
                                </p>
                                <h5 className="fc-cprimary m-0">
                                  ₹{" "}
                                  {parseInt(
                                    apartment.cost / 2
                                  ).toLocaleString()}
                                </h5>
                              </div>
                              <p
                                style={{
                                  fontFamily: "proxima_nova_rgregular",
                                }}
                                className="fc-75"
                              >
                                Urbanletting charges a one time accommodation
                                convenience fee of Rs.2796.
                              </p>
                            </div>
                          )} */}
                              </div>
                            </div>
                          </div>

                          <div className="col-12 d-flex flex-column align-items-start mt-4 pb-3 px-0 mx-0">
                            <div className="row w-100 mx-0">
                              <h4 className="m-0 fc-1c pb-3">
                                About {apartment.societyName}
                              </h4>

                              <div
                                className="row col-12 p-0 m-0"
                                style={{
                                  backgroundColor: "#f3f4f5",
                                }}
                              >
                                {/* col one */}
                                <div className="col-12 col-md-6">
                                  <AboutChips
                                    name="Property Type"
                                    value={apartment.propertyType}
                                  />
                                  <AboutChips
                                    name="Parking Type"
                                    value={apartment.parkingType}
                                  />{" "}
                                  <AboutChips
                                    name="Lease Term"
                                    value={apartment.leaseTerm}
                                  />
                                  <AboutChips
                                    name="Furnished"
                                    value={apartment.furnishingStatus}
                                  />
                                </div>

                                {/* col two */}
                                <div className="col-12 col-md-6">
                                  <AboutChips
                                    name="Property Sub-type"
                                    value={apartment.propertyType}
                                  />

                                  <AboutChips
                                    name="Parking Spots"
                                    value={apartment.parkingSpots}
                                  />

                                  <AboutChips
                                    name="Short-term"
                                    value={apartment.shortTerm}
                                  />

                                  <AboutChips
                                    name="Year Built"
                                    value={apartment.yearBuilt}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="d-flex flex-wrap tags">
                              {[...new Set(apartment.amenities)].map((tag) => (
                                <div
                                  className="user-select-none text-muted py-1 border px-2 my-2 tag d-flex justify-content-center align-items-center"
                                  key={tag}
                                  style={{
                                    fontSize: 12,
                                    background: "#f3f4f5",
                                  }}
                                >
                                  <StarIcon
                                    style={{
                                      fontSize: 14,
                                    }}
                                  />
                                  <span className="mx-1">{tag}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        ref={contactFormDiv}
                        className="col-12 col-md-3 border rounded mx-0 mx-md-4 d-none d-md-block pt-2"
                        style={{
                          backgroundColor: "#f3f4f5",
                          height: "fit-content",
                          marginTop: 0,
                        }}
                      >
                        <ContactForm phone={apartment.phone} docRef={docRef} />
                      </div>

                      <div
                        className="start-0 position-fixed w-100 bottom-0 px-4 py-3 d-block d-md-none d-flex justify-content-center"
                        style={{
                          zIndex: 10,
                          backdropFilter: "blur(1px)",
                        }}
                      >
                        <button
                          type="button"
                          className="clean-button text-uppercase text-white px-4 py-2"
                          style={{
                            background: "rgb(60, 211, 163)",
                            borderRadius: 99,
                          }}
                          onClick={() => {
                            if (!user) {
                              setAuthModalConfig({
                                show: true,
                                type: "Login",
                              });
                            } else {
                              setShowRequestCallback(true);
                            }
                          }}
                        >
                          Get Contact Details
                        </button>
                      </div>
                      {showRequestCallback && (
                        <RequestCallback
                          showRequestCallback={setShowRequestCallback}
                          setUploadingLoader={setUploadingLoader}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              apartment === undefined && <Loader />
            )}
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    apartmentDet: state.firestore.ordered.details,
    user: state.user.user,
    userDoc: state.user.user
      ? state.firestore.ordered.userDoc
        ? state.firestore.ordered.userDoc[0]
        : {}
      : {},
    currentCityID: state.apartment.currentCityID,
    currentLocalityID: state.apartment.currentLocalityID,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAuthModalConfig: (obj) => dispatch(setAuthModalConfig(obj)),
  setUploadingLoader: (val) => dispatch(setUploadingLoader(val)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    props.match.params.id = props.currentLocalityID;
    return [
      {
        collection: "city",
        doc: props.currentCityID ? props.currentCityID : null,
        storeAs: "details",

        subcollections: [
          {
            collection: "locality",
            doc: props.match.params.id,
            subcollections: [
              {
                collection: "estate",
                doc: props.match.params.apartmentId,
              },
            ],
          },
        ],
      },
    ];
  })
)(ApartmentDetails);
