import React, { useState, useEffect } from "react";
import hashedImage from "../../../assets/hashed-image.png";

const ImageWidget = ({ src, alt, isRentedOut }) => {
  const [imageSrc, setImageSrc] = useState(hashedImage);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImageSrc(src);
    };
    img.onerror = (error) => {
      console.error("Failed to load image:", error);
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src]);

  return (
    <div
      style={{ position: "relative", width: "100%", paddingBottom: "56.25%" }}
    >
      <img
        src={imageSrc}
        loading="lazy"
        alt={alt}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          filter: isRentedOut ? "grayscale(100%)" : "none",
        }}
      />
      {isRentedOut && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            fontSize: "1.5em",
            fontWeight: "bold",
          }}
        >
          Rented Out
        </div>
      )}
    </div>
  );
};

export default ImageWidget;
