// localities.js
const polygons = {
  cities: {
    Bangalore: {
      localities: [
        {
          name: "Whitefield",
          coordinates: [
            { lat: 12.978284, lng: 77.710955 },
            { lat: 12.98096, lng: 77.711985 },
            { lat: 12.981044, lng: 77.713959 },
            { lat: 12.982549, lng: 77.715933 },
            { lat: 12.982382, lng: 77.71765 },
            { lat: 12.986647, lng: 77.718422 },
            { lat: 12.986475, lng: 77.720812 },
            { lat: 12.985026, lng: 77.726761 },
            { lat: 12.985412, lng: 77.727059 },
            { lat: 12.984375, lng: 77.728903 },
            { lat: 12.985667, lng: 77.730167 },
            { lat: 12.984292, lng: 77.733629 },
            { lat: 12.987788, lng: 77.733851 },
            { lat: 12.98754, lng: 77.740101 },
            { lat: 12.982442, lng: 77.758138 },
            { lat: 12.97672, lng: 77.756992 },
            { lat: 12.975321, lng: 77.763546 },
            { lat: 12.969759, lng: 77.762441 },
            { lat: 12.967186, lng: 77.767264 },
            { lat: 12.965465, lng: 77.765475 },
            { lat: 12.965993, lng: 77.764745 },
            { lat: 12.963744, lng: 77.764768 },
            { lat: 12.955759, lng: 77.747875 },
            { lat: 12.957098, lng: 77.745684 },
            { lat: 12.944044, lng: 77.718897 },
            { lat: 12.944499, lng: 77.717547 },
            { lat: 12.94288, lng: 77.714899 },
            { lat: 12.943234, lng: 77.706642 },
            { lat: 12.948345, lng: 77.70576 },
            { lat: 12.946878, lng: 77.718014 },
            { lat: 12.95578, lng: 77.717314 },
            { lat: 12.956192, lng: 77.730961 },
            { lat: 12.963512, lng: 77.733077 },
            { lat: 12.966171, lng: 77.726676 },
            { lat: 12.971046, lng: 77.726745 },
            { lat: 12.97332, lng: 77.717863 },
            { lat: 12.977273, lng: 77.718209 },
            { lat: 12.978147, lng: 77.710913 },
          ]
        },
        {
          name: "HSR Layout",
          coordinates: [
            { lat: 12.917312, lng: 77.622895 },
            { lat: 12.917876, lng: 77.625786 },
            { lat: 12.91696, lng: 77.628334 },
            { lat: 12.919323, lng: 77.628978 },
            { lat: 12.918805, lng: 77.630746 },
            { lat: 12.916904, lng: 77.63033 },
            { lat: 12.916536, lng: 77.634911 },
            { lat: 12.917199, lng: 77.63486 },
            { lat: 12.917088, lng: 77.635389 },
            { lat: 12.918234, lng: 77.63618 },
            { lat: 12.9164802, lng: 77.6377699 },
            { lat: 12.923798, lng: 77.639953 },
            { lat: 12.924669, lng: 77.649522 },
            { lat: 12.918822, lng: 77.66873 },
            { lat: 12.909879, lng: 77.662918 },
            { lat: 12.909469, lng: 77.663825 },
            { lat: 12.89582, lng: 77.657926 },
            { lat: 12.896768, lng: 77.653324 },
            { lat: 12.900149, lng: 77.654167 },
            { lat: 12.901507, lng: 77.649305 },
            { lat: 12.899864, lng: 77.649208 },
            { lat: 12.899422, lng: 77.647393 },
            { lat: 12.90037, lng: 77.646777 },
            { lat: 12.900907, lng: 77.638998 },
            { lat: 12.905709, lng: 77.638998 },
            { lat: 12.907384, lng: 77.635076 },
            { lat: 12.906331, lng: 77.629568 },
            { lat: 12.917312, lng: 77.622895 },
          ]
        },
        {
          name: "Koramangala",
          coordinates: [
            {
              "lat": 12.923585,
              "lng": 77.654754
            },
            {
              "lat": 12.924351,
              "lng": 77.654649
            },
            {
              "lat": 12.925176,
              "lng": 77.652668
            },
            {
              "lat": 12.926185,
              "lng": 77.650245
            },
            {
              "lat": 12.926941,
              "lng": 77.648693
            },
            {
              "lat": 12.927181,
              "lng": 77.648858
            },
            {
              "lat": 12.927405,
              "lng": 77.648603
            },
            {
              "lat": 12.92739,
              "lng": 77.648388
            },
            {
              "lat": 12.927621,
              "lng": 77.647987
            },
            {
              "lat": 12.927972,
              "lng": 77.647616
            },
            {
              "lat": 12.927966,
              "lng": 77.647192
            },
            {
              "lat": 12.928492,
              "lng": 77.646456
            },
            {
              "lat": 12.928664,
              "lng": 77.646299
            },
            {
              "lat": 12.928965,
              "lng": 77.646021
            },
            {
              "lat": 12.929362,
              "lng": 77.645584
            },
            {
              "lat": 12.929276,
              "lng": 77.645286
            },
            {
              "lat": 12.9289,
              "lng": 77.644781
            },
            {
              "lat": 12.92865,
              "lng": 77.644452
            },
            {
              "lat": 12.928604,
              "lng": 77.644344
            },
            {
              "lat": 12.928564,
              "lng": 77.644179
            },
            {
              "lat": 12.928594,
              "lng": 77.643917
            },
            {
              "lat": 12.928644,
              "lng": 77.643768
            },
            {
              "lat": 12.929121,
              "lng": 77.642548
            },
            {
              "lat": 12.929306,
              "lng": 77.642074
            },
            {
              "lat": 12.929532,
              "lng": 77.642115
            },
            {
              "lat": 12.929682,
              "lng": 77.642085
            },
            {
              "lat": 12.929964,
              "lng": 77.641987
            },
            {
              "lat": 12.930214,
              "lng": 77.641627
            },
            {
              "lat": 12.930234,
              "lng": 77.641313
            },
            {
              "lat": 12.930109,
              "lng": 77.641061
            },
            {
              "lat": 12.930079,
              "lng": 77.640886
            },
            {
              "lat": 12.930069,
              "lng": 77.640593
            },
            {
              "lat": 12.930575,
              "lng": 77.639577
            },
            {
              "lat": 12.930692,
              "lng": 77.639294
            },
            {
              "lat": 12.930771,
              "lng": 77.639112
            },
            {
              "lat": 12.93084,
              "lng": 77.638952
            },
            {
              "lat": 12.93086,
              "lng": 77.638896
            },
            {
              "lat": 12.930901,
              "lng": 77.63885
            },
            {
              "lat": 12.930918,
              "lng": 77.63883
            },
            {
              "lat": 12.930941,
              "lng": 77.63881
            },
            {
              "lat": 12.930988,
              "lng": 77.638774
            },
            {
              "lat": 12.93107,
              "lng": 77.63872
            },
            {
              "lat": 12.931222,
              "lng": 77.638634
            },
            {
              "lat": 12.931519,
              "lng": 77.6384
            },
            {
              "lat": 12.93163,
              "lng": 77.638323
            },
            {
              "lat": 12.931723,
              "lng": 77.638279
            },
            {
              "lat": 12.931789,
              "lng": 77.638248
            },
            {
              "lat": 12.9319,
              "lng": 77.638204
            },
            {
              "lat": 12.931984,
              "lng": 77.638146
            },
            {
              "lat": 12.932019,
              "lng": 77.638122
            },
            {
              "lat": 12.93205,
              "lng": 77.638127
            },
            {
              "lat": 12.932092,
              "lng": 77.638166
            },
            {
              "lat": 12.932184,
              "lng": 77.638227
            },
            {
              "lat": 12.932211,
              "lng": 77.638234
            },
            {
              "lat": 12.932273,
              "lng": 77.638234
            },
            {
              "lat": 12.932343,
              "lng": 77.6382
            },
            {
              "lat": 12.932383,
              "lng": 77.638181
            },
            {
              "lat": 12.932482,
              "lng": 77.638185
            },
            {
              "lat": 12.932521,
              "lng": 77.638185
            },
            {
              "lat": 12.932573,
              "lng": 77.638136
            },
            {
              "lat": 12.932763,
              "lng": 77.637884
            },
            {
              "lat": 12.932933,
              "lng": 77.637789
            },
            {
              "lat": 12.9331,
              "lng": 77.637697
            },
            {
              "lat": 12.933389,
              "lng": 77.63748
            },
            {
              "lat": 12.933532,
              "lng": 77.637392
            },
            {
              "lat": 12.933709,
              "lng": 77.637284
            },
            {
              "lat": 12.933836,
              "lng": 77.637101
            },
            {
              "lat": 12.933971,
              "lng": 77.636907
            },
            {
              "lat": 12.934187,
              "lng": 77.636681
            },
            {
              "lat": 12.93433,
              "lng": 77.636543
            },
            {
              "lat": 12.934395,
              "lng": 77.636478
            },
            {
              "lat": 12.934468,
              "lng": 77.63638
            },
            {
              "lat": 12.934533,
              "lng": 77.636292
            },
            {
              "lat": 12.934869,
              "lng": 77.63603
            },
            {
              "lat": 12.934964,
              "lng": 77.635919
            },
            {
              "lat": 12.934986,
              "lng": 77.635862
            },
            {
              "lat": 12.93499,
              "lng": 77.635795
            },
            {
              "lat": 12.934963,
              "lng": 77.635616
            },
            {
              "lat": 12.934898,
              "lng": 77.635341
            },
            {
              "lat": 12.934863,
              "lng": 77.635223
            },
            {
              "lat": 12.934916,
              "lng": 77.635207
            },
            {
              "lat": 12.934993,
              "lng": 77.635185
            },
            {
              "lat": 12.93513,
              "lng": 77.635163
            },
            {
              "lat": 12.935236,
              "lng": 77.635139
            },
            {
              "lat": 12.935479,
              "lng": 77.63509
            },
            {
              "lat": 12.935631,
              "lng": 77.635062
            },
            {
              "lat": 12.935604,
              "lng": 77.634957
            },
            {
              "lat": 12.93579,
              "lng": 77.634778
            },
            {
              "lat": 12.935834,
              "lng": 77.634744
            },
            {
              "lat": 12.935902,
              "lng": 77.634727
            },
            {
              "lat": 12.935967,
              "lng": 77.634692
            },
            {
              "lat": 12.936093,
              "lng": 77.634589
            },
            {
              "lat": 12.936106,
              "lng": 77.634645
            },
            {
              "lat": 12.936263,
              "lng": 77.634498
            },
            {
              "lat": 12.936287,
              "lng": 77.634468
            },
            {
              "lat": 12.936348,
              "lng": 77.634402
            },
            {
              "lat": 12.936386,
              "lng": 77.634353
            },
            {
              "lat": 12.936449,
              "lng": 77.634282
            },
            {
              "lat": 12.936498,
              "lng": 77.634228
            },
            {
              "lat": 12.93654,
              "lng": 77.634183
            },
            {
              "lat": 12.936653,
              "lng": 77.63405
            },
            {
              "lat": 12.936714,
              "lng": 77.633981
            },
            {
              "lat": 12.93682,
              "lng": 77.633871
            },
            {
              "lat": 12.936985,
              "lng": 77.633692
            },
            {
              "lat": 12.937049,
              "lng": 77.633618
            },
            {
              "lat": 12.93715,
              "lng": 77.633502
            },
            {
              "lat": 12.937268,
              "lng": 77.633385
            },
            {
              "lat": 12.937286,
              "lng": 77.633369
            },
            {
              "lat": 12.937537,
              "lng": 77.633178
            },
            {
              "lat": 12.937593,
              "lng": 77.633136
            },
            {
              "lat": 12.93811,
              "lng": 77.632888
            },
            {
              "lat": 12.938353,
              "lng": 77.632769
            },
            {
              "lat": 12.938422,
              "lng": 77.632735
            },
            {
              "lat": 12.938531,
              "lng": 77.632679
            },
            {
              "lat": 12.938545,
              "lng": 77.63186
            },
            {
              "lat": 12.938544,
              "lng": 77.631759
            },
            {
              "lat": 12.938543,
              "lng": 77.631659
            },
            {
              "lat": 12.938537,
              "lng": 77.631508
            },
            {
              "lat": 12.938528,
              "lng": 77.6313
            },
            {
              "lat": 12.938519,
              "lng": 77.631088
            },
            {
              "lat": 12.938512,
              "lng": 77.630913
            },
            {
              "lat": 12.938511,
              "lng": 77.630804
            },
            {
              "lat": 12.93851,
              "lng": 77.630567
            },
            {
              "lat": 12.938508,
              "lng": 77.630384
            },
            {
              "lat": 12.938508,
              "lng": 77.630358
            },
            {
              "lat": 12.938511,
              "lng": 77.630262
            },
            {
              "lat": 12.938531,
              "lng": 77.629669
            },
            {
              "lat": 12.938519,
              "lng": 77.62948
            },
            {
              "lat": 12.938506,
              "lng": 77.629321
            },
            {
              "lat": 12.938493,
              "lng": 77.62923
            },
            {
              "lat": 12.938471,
              "lng": 77.629126
            },
            {
              "lat": 12.938347,
              "lng": 77.628741
            },
            {
              "lat": 12.938332,
              "lng": 77.628704
            },
            {
              "lat": 12.938307,
              "lng": 77.628641
            },
            {
              "lat": 12.938263,
              "lng": 77.628531
            },
            {
              "lat": 12.938222,
              "lng": 77.62843
            },
            {
              "lat": 12.938123,
              "lng": 77.628474
            },
            {
              "lat": 12.938049,
              "lng": 77.628297
            },
            {
              "lat": 12.938037,
              "lng": 77.628275
            },
            {
              "lat": 12.937931,
              "lng": 77.62807
            },
            {
              "lat": 12.937771,
              "lng": 77.627768
            },
            {
              "lat": 12.937704,
              "lng": 77.627652
            },
            {
              "lat": 12.937637,
              "lng": 77.627543
            },
            {
              "lat": 12.937497,
              "lng": 77.627345
            },
            {
              "lat": 12.937394,
              "lng": 77.627198
            },
            {
              "lat": 12.937222,
              "lng": 77.62697
            },
            {
              "lat": 12.937307,
              "lng": 77.6269
            },
            {
              "lat": 12.937476,
              "lng": 77.626761
            },
            {
              "lat": 12.937623,
              "lng": 77.626634
            },
            {
              "lat": 12.937661,
              "lng": 77.626603
            },
            {
              "lat": 12.938356,
              "lng": 77.626078
            },
            {
              "lat": 12.938812,
              "lng": 77.625876
            },
            {
              "lat": 12.938905,
              "lng": 77.625837
            },
            {
              "lat": 12.939261,
              "lng": 77.625685
            },
            {
              "lat": 12.939773,
              "lng": 77.625465
            },
            {
              "lat": 12.939884,
              "lng": 77.625407
            },
            {
              "lat": 12.940493,
              "lng": 77.624845
            },
            {
              "lat": 12.94051,
              "lng": 77.625553
            },
            {
              "lat": 12.941233,
              "lng": 77.625906
            },
            {
              "lat": 12.941769,
              "lng": 77.626226
            },
            {
              "lat": 12.941816,
              "lng": 77.626205
            },
            {
              "lat": 12.942841,
              "lng": 77.62613
            },
            {
              "lat": 12.943266,
              "lng": 77.62612
            },
            {
              "lat": 12.943872,
              "lng": 77.626103
            },
            {
              "lat": 12.944252,
              "lng": 77.626093
            },
            {
              "lat": 12.944392,
              "lng": 77.626072
            },
            {
              "lat": 12.944547,
              "lng": 77.625976
            },
            {
              "lat": 12.944558,
              "lng": 77.62596
            },
            {
              "lat": 12.944946,
              "lng": 77.625662
            },
            {
              "lat": 12.945038,
              "lng": 77.625563
            },
            {
              "lat": 12.945149,
              "lng": 77.625444
            },
            {
              "lat": 12.945439,
              "lng": 77.624939
            },
            {
              "lat": 12.945812,
              "lng": 77.624274
            },
            {
              "lat": 12.945989,
              "lng": 77.623981
            },
            {
              "lat": 12.946217,
              "lng": 77.623976
            },
            {
              "lat": 12.946217,
              "lng": 77.62397
            },
            {
              "lat": 12.946256,
              "lng": 77.623971
            },
            {
              "lat": 12.946345,
              "lng": 77.623948
            },
            {
              "lat": 12.946614,
              "lng": 77.624031
            },
            {
              "lat": 12.947114,
              "lng": 77.623854
            },
            {
              "lat": 12.947191,
              "lng": 77.623817
            },
            {
              "lat": 12.947395,
              "lng": 77.623719
            },
            {
              "lat": 12.947642,
              "lng": 77.623369
            },
            {
              "lat": 12.947764,
              "lng": 77.623236
            },
            {
              "lat": 12.947901,
              "lng": 77.623086
            },
            {
              "lat": 12.948359,
              "lng": 77.622746
            },
            {
              "lat": 12.948545,
              "lng": 77.622607
            },
            {
              "lat": 12.948979,
              "lng": 77.622233
            },
            {
              "lat": 12.949337,
              "lng": 77.621924
            },
            {
              "lat": 12.949485,
              "lng": 77.621509
            },
            {
              "lat": 12.949501,
              "lng": 77.621499
            },
            {
              "lat": 12.949663,
              "lng": 77.621146
            },
            {
              "lat": 12.94983,
              "lng": 77.620814
            },
            {
              "lat": 12.949895,
              "lng": 77.620685
            },
            {
              "lat": 12.949918,
              "lng": 77.620631
            },
            {
              "lat": 12.949815,
              "lng": 77.620577
            },
            {
              "lat": 12.949776,
              "lng": 77.620554
            },
            {
              "lat": 12.949277,
              "lng": 77.620272
            },
            {
              "lat": 12.949266,
              "lng": 77.620267
            },
            {
              "lat": 12.949191,
              "lng": 77.620231
            },
            {
              "lat": 12.949088,
              "lng": 77.620179
            },
            {
              "lat": 12.948867,
              "lng": 77.620056
            },
            {
              "lat": 12.948809,
              "lng": 77.620026
            },
            {
              "lat": 12.948069,
              "lng": 77.619624
            },
            {
              "lat": 12.947701,
              "lng": 77.619417
            },
            {
              "lat": 12.94751,
              "lng": 77.619309
            },
            {
              "lat": 12.947468,
              "lng": 77.619285
            },
            {
              "lat": 12.947433,
              "lng": 77.619266
            },
            {
              "lat": 12.947269,
              "lng": 77.619179
            },
            {
              "lat": 12.947119,
              "lng": 77.619099
            },
            {
              "lat": 12.946938,
              "lng": 77.619002
            },
            {
              "lat": 12.946651,
              "lng": 77.618845
            },
            {
              "lat": 12.946273,
              "lng": 77.618641
            },
            {
              "lat": 12.946081,
              "lng": 77.618564
            },
            {
              "lat": 12.946003,
              "lng": 77.618532
            },
            {
              "lat": 12.946026,
              "lng": 77.618453
            },
            {
              "lat": 12.946041,
              "lng": 77.618424
            },
            {
              "lat": 12.946094,
              "lng": 77.618318
            },
            {
              "lat": 12.946106,
              "lng": 77.618295
            },
            {
              "lat": 12.946131,
              "lng": 77.618244
            },
            {
              "lat": 12.945947,
              "lng": 77.618204
            },
            {
              "lat": 12.945621,
              "lng": 77.618109
            },
            {
              "lat": 12.945597,
              "lng": 77.618101
            },
            {
              "lat": 12.945422,
              "lng": 77.618048
            },
            {
              "lat": 12.945424,
              "lng": 77.618044
            },
            {
              "lat": 12.945396,
              "lng": 77.618037
            },
            {
              "lat": 12.94464,
              "lng": 77.617805
            },
            {
              "lat": 12.944935,
              "lng": 77.616973
            },
            {
              "lat": 12.944912,
              "lng": 77.616859
            },
            {
              "lat": 12.944338,
              "lng": 77.616642
            },
            {
              "lat": 12.944198,
              "lng": 77.616575
            },
            {
              "lat": 12.944122,
              "lng": 77.616518
            },
            {
              "lat": 12.944102,
              "lng": 77.616503
            },
            {
              "lat": 12.943825,
              "lng": 77.616244
            },
            {
              "lat": 12.943717,
              "lng": 77.616113
            },
            {
              "lat": 12.943655,
              "lng": 77.615841
            },
            {
              "lat": 12.943012,
              "lng": 77.615867
            },
            {
              "lat": 12.942294,
              "lng": 77.615896
            },
            {
              "lat": 12.941768,
              "lng": 77.615907
            },
            {
              "lat": 12.941837,
              "lng": 77.615674
            },
            {
              "lat": 12.941864,
              "lng": 77.615604
            },
            {
              "lat": 12.941896,
              "lng": 77.615524
            },
            {
              "lat": 12.941963,
              "lng": 77.615357
            },
            {
              "lat": 12.94203,
              "lng": 77.615217
            },
            {
              "lat": 12.942137,
              "lng": 77.614992
            },
            {
              "lat": 12.942218,
              "lng": 77.614838
            },
            {
              "lat": 12.942305,
              "lng": 77.614673
            },
            {
              "lat": 12.942322,
              "lng": 77.61464
            },
            {
              "lat": 12.942544,
              "lng": 77.61424
            },
            {
              "lat": 12.942564,
              "lng": 77.614204
            },
            {
              "lat": 12.942659,
              "lng": 77.613943
            },
            {
              "lat": 12.942672,
              "lng": 77.613907
            },
            {
              "lat": 12.942725,
              "lng": 77.613764
            },
            {
              "lat": 12.942769,
              "lng": 77.613581
            },
            {
              "lat": 12.942808,
              "lng": 77.613437
            },
            {
              "lat": 12.942815,
              "lng": 77.613413
            },
            {
              "lat": 12.942817,
              "lng": 77.613408
            },
            {
              "lat": 12.941982,
              "lng": 77.613094
            },
            {
              "lat": 12.941824,
              "lng": 77.613563
            },
            {
              "lat": 12.941469,
              "lng": 77.614148
            },
            {
              "lat": 12.941072,
              "lng": 77.61447
            },
            {
              "lat": 12.94098,
              "lng": 77.614572
            },
            {
              "lat": 12.940964,
              "lng": 77.614629
            },
            {
              "lat": 12.940962,
              "lng": 77.614628
            },
            {
              "lat": 12.940933,
              "lng": 77.61465
            },
            {
              "lat": 12.940823,
              "lng": 77.614797
            },
            {
              "lat": 12.940727,
              "lng": 77.614876
            },
            {
              "lat": 12.940439,
              "lng": 77.615063
            },
            {
              "lat": 12.940141,
              "lng": 77.615389
            },
            {
              "lat": 12.939996,
              "lng": 77.615725
            },
            {
              "lat": 12.939775,
              "lng": 77.616615
            },
            {
              "lat": 12.939438,
              "lng": 77.617985
            },
            {
              "lat": 12.939303,
              "lng": 77.618413
            },
            {
              "lat": 12.939259,
              "lng": 77.618552
            },
            {
              "lat": 12.939214,
              "lng": 77.618692
            },
            {
              "lat": 12.93905,
              "lng": 77.619227
            },
            {
              "lat": 12.938933,
              "lng": 77.619162
            },
            {
              "lat": 12.938811,
              "lng": 77.619102
            },
            {
              "lat": 12.938606,
              "lng": 77.619002
            },
            {
              "lat": 12.938386,
              "lng": 77.618893
            },
            {
              "lat": 12.938283,
              "lng": 77.618842
            },
            {
              "lat": 12.937994,
              "lng": 77.618708
            },
            {
              "lat": 12.937924,
              "lng": 77.618675
            },
            {
              "lat": 12.937764,
              "lng": 77.618612
            },
            {
              "lat": 12.937479,
              "lng": 77.618525
            },
            {
              "lat": 12.937252,
              "lng": 77.618442
            },
            {
              "lat": 12.937061,
              "lng": 77.618345
            },
            {
              "lat": 12.937036,
              "lng": 77.618326
            },
            {
              "lat": 12.93692,
              "lng": 77.618237
            },
            {
              "lat": 12.936851,
              "lng": 77.61817
            },
            {
              "lat": 12.936769,
              "lng": 77.618092
            },
            {
              "lat": 12.936752,
              "lng": 77.618074
            },
            {
              "lat": 12.936601,
              "lng": 77.617907
            },
            {
              "lat": 12.936495,
              "lng": 77.617795
            },
            {
              "lat": 12.936479,
              "lng": 77.617775
            },
            {
              "lat": 12.936389,
              "lng": 77.617665
            },
            {
              "lat": 12.936334,
              "lng": 77.617525
            },
            {
              "lat": 12.936563,
              "lng": 77.616958
            },
            {
              "lat": 12.937342,
              "lng": 77.614718
            },
            {
              "lat": 12.937159,
              "lng": 77.614402
            },
            {
              "lat": 12.937534,
              "lng": 77.612665
            },
            {
              "lat": 12.937697,
              "lng": 77.612685
            },
            {
              "lat": 12.937909,
              "lng": 77.612557
            },
            {
              "lat": 12.938092,
              "lng": 77.611668
            },
            {
              "lat": 12.937967,
              "lng": 77.611658
            },
            {
              "lat": 12.937909,
              "lng": 77.611195
            },
            {
              "lat": 12.938209,
              "lng": 77.610254
            },
            {
              "lat": 12.937875,
              "lng": 77.610435
            },
            {
              "lat": 12.937338,
              "lng": 77.61072
            },
            {
              "lat": 12.936896,
              "lng": 77.610968
            },
            {
              "lat": 12.936873,
              "lng": 77.61098
            },
            {
              "lat": 12.936825,
              "lng": 77.611004
            },
            {
              "lat": 12.936493,
              "lng": 77.611168
            },
            {
              "lat": 12.936271,
              "lng": 77.611281
            },
            {
              "lat": 12.935734,
              "lng": 77.611569
            },
            {
              "lat": 12.935593,
              "lng": 77.611645
            },
            {
              "lat": 12.935316,
              "lng": 77.611807
            },
            {
              "lat": 12.934768,
              "lng": 77.612109
            },
            {
              "lat": 12.934394,
              "lng": 77.612335
            },
            {
              "lat": 12.934347,
              "lng": 77.612363
            },
            {
              "lat": 12.933725,
              "lng": 77.612707
            },
            {
              "lat": 12.932298,
              "lng": 77.613509
            },
            {
              "lat": 12.931845,
              "lng": 77.613761
            },
            {
              "lat": 12.93171,
              "lng": 77.613824
            },
            {
              "lat": 12.93153,
              "lng": 77.613908
            },
            {
              "lat": 12.930982,
              "lng": 77.614192
            },
            {
              "lat": 12.93011,
              "lng": 77.614707
            },
            {
              "lat": 12.929926,
              "lng": 77.61484
            },
            {
              "lat": 12.929702,
              "lng": 77.615017
            },
            {
              "lat": 12.929553,
              "lng": 77.615133
            },
            {
              "lat": 12.929404,
              "lng": 77.615238
            },
            {
              "lat": 12.929251,
              "lng": 77.615334
            },
            {
              "lat": 12.927855,
              "lng": 77.61618
            },
            {
              "lat": 12.9263,
              "lng": 77.617123
            },
            {
              "lat": 12.925864,
              "lng": 77.617381
            },
            {
              "lat": 12.925239,
              "lng": 77.61778
            },
            {
              "lat": 12.924697,
              "lng": 77.618117
            },
            {
              "lat": 12.924646,
              "lng": 77.618144
            },
            {
              "lat": 12.924566,
              "lng": 77.618188
            },
            {
              "lat": 12.9244,
              "lng": 77.618278
            },
            {
              "lat": 12.924011,
              "lng": 77.618527
            },
            {
              "lat": 12.923958,
              "lng": 77.618559
            },
            {
              "lat": 12.923586,
              "lng": 77.618791
            },
            {
              "lat": 12.923538,
              "lng": 77.618821
            },
            {
              "lat": 12.923276,
              "lng": 77.618984
            },
            {
              "lat": 12.923237,
              "lng": 77.619009
            },
            {
              "lat": 12.922744,
              "lng": 77.619312
            },
            {
              "lat": 12.922412,
              "lng": 77.619517
            },
            {
              "lat": 12.921712,
              "lng": 77.619954
            },
            {
              "lat": 12.921345,
              "lng": 77.620158
            },
            {
              "lat": 12.921153,
              "lng": 77.620238
            },
            {
              "lat": 12.921107,
              "lng": 77.620257
            },
            {
              "lat": 12.921078,
              "lng": 77.620275
            },
            {
              "lat": 12.92099,
              "lng": 77.620329
            },
            {
              "lat": 12.920041,
              "lng": 77.620903
            },
            {
              "lat": 12.919946,
              "lng": 77.620969
            },
            {
              "lat": 12.919556,
              "lng": 77.621223
            },
            {
              "lat": 12.919516,
              "lng": 77.621269
            },
            {
              "lat": 12.919351,
              "lng": 77.62146
            },
            {
              "lat": 12.919204,
              "lng": 77.621599
            },
            {
              "lat": 12.919177,
              "lng": 77.621624
            },
            {
              "lat": 12.918364,
              "lng": 77.622222
            },
            {
              "lat": 12.917906,
              "lng": 77.62256
            },
            {
              "lat": 12.917798,
              "lng": 77.622683
            },
            {
              "lat": 12.91771,
              "lng": 77.622793
            },
            {
              "lat": 12.91764,
              "lng": 77.622903
            },
            {
              "lat": 12.917596,
              "lng": 77.623046
            },
            {
              "lat": 12.917572,
              "lng": 77.623241
            },
            {
              "lat": 12.917579,
              "lng": 77.623474
            },
            {
              "lat": 12.917645,
              "lng": 77.623757
            },
            {
              "lat": 12.917719,
              "lng": 77.624205
            },
            {
              "lat": 12.917801,
              "lng": 77.624669
            },
            {
              "lat": 12.91782,
              "lng": 77.624777
            },
            {
              "lat": 12.917827,
              "lng": 77.62487
            },
            {
              "lat": 12.917828,
              "lng": 77.624931
            },
            {
              "lat": 12.917826,
              "lng": 77.625338
            },
            {
              "lat": 12.917797,
              "lng": 77.625557
            },
            {
              "lat": 12.917741,
              "lng": 77.625861
            },
            {
              "lat": 12.917621,
              "lng": 77.626228
            },
            {
              "lat": 12.917357,
              "lng": 77.627111
            },
            {
              "lat": 12.917022,
              "lng": 77.628314
            },
            {
              "lat": 12.917012,
              "lng": 77.628351
            },
            {
              "lat": 12.916971,
              "lng": 77.628676
            },
            {
              "lat": 12.916904,
              "lng": 77.6292
            },
            {
              "lat": 12.916875,
              "lng": 77.629812
            },
            {
              "lat": 12.916864,
              "lng": 77.629976
            },
            {
              "lat": 12.916842,
              "lng": 77.63028
            },
            {
              "lat": 12.916748,
              "lng": 77.631662
            },
            {
              "lat": 12.916745,
              "lng": 77.631687
            },
            {
              "lat": 12.916675,
              "lng": 77.632322
            },
            {
              "lat": 12.916531,
              "lng": 77.633712
            },
            {
              "lat": 12.916395,
              "lng": 77.635065
            },
            {
              "lat": 12.916392,
              "lng": 77.635098
            },
            {
              "lat": 12.916362,
              "lng": 77.635414
            },
            {
              "lat": 12.916366,
              "lng": 77.635414
            },
            {
              "lat": 12.917029,
              "lng": 77.635483
            },
            {
              "lat": 12.917498,
              "lng": 77.63559
            },
            {
              "lat": 12.917733,
              "lng": 77.635711
            },
            {
              "lat": 12.917916,
              "lng": 77.635863
            },
            {
              "lat": 12.918156,
              "lng": 77.636112
            },
            {
              "lat": 12.918173,
              "lng": 77.63613
            },
            {
              "lat": 12.918403,
              "lng": 77.636341
            },
            {
              "lat": 12.918593,
              "lng": 77.636527
            },
            {
              "lat": 12.918706,
              "lng": 77.636623
            },
            {
              "lat": 12.918577,
              "lng": 77.636876
            },
            {
              "lat": 12.918924,
              "lng": 77.636929
            },
            {
              "lat": 12.919036,
              "lng": 77.636953
            },
            {
              "lat": 12.919261,
              "lng": 77.637043
            },
            {
              "lat": 12.919515,
              "lng": 77.637159
            },
            {
              "lat": 12.919728,
              "lng": 77.637347
            },
            {
              "lat": 12.919944,
              "lng": 77.637522
            },
            {
              "lat": 12.920158,
              "lng": 77.637678
            },
            {
              "lat": 12.92036,
              "lng": 77.637813
            },
            {
              "lat": 12.920125,
              "lng": 77.638374
            },
            {
              "lat": 12.92018,
              "lng": 77.638386
            },
            {
              "lat": 12.920259,
              "lng": 77.638399
            },
            {
              "lat": 12.920327,
              "lng": 77.638415
            },
            {
              "lat": 12.920897,
              "lng": 77.638583
            },
            {
              "lat": 12.921004,
              "lng": 77.638614
            },
            {
              "lat": 12.921276,
              "lng": 77.638695
            },
            {
              "lat": 12.921478,
              "lng": 77.638754
            },
            {
              "lat": 12.921507,
              "lng": 77.638763
            },
            {
              "lat": 12.921554,
              "lng": 77.638784
            },
            {
              "lat": 12.921768,
              "lng": 77.638895
            },
            {
              "lat": 12.921895,
              "lng": 77.638966
            },
            {
              "lat": 12.921946,
              "lng": 77.638995
            },
            {
              "lat": 12.922166,
              "lng": 77.639111
            },
            {
              "lat": 12.92246,
              "lng": 77.639267
            },
            {
              "lat": 12.922762,
              "lng": 77.639404
            },
            {
              "lat": 12.922899,
              "lng": 77.639467
            },
            {
              "lat": 12.923211,
              "lng": 77.639609
            },
            {
              "lat": 12.923382,
              "lng": 77.63969
            },
            {
              "lat": 12.92349,
              "lng": 77.639741
            },
            {
              "lat": 12.923554,
              "lng": 77.639763
            },
            {
              "lat": 12.923585,
              "lng": 77.639773
            },
            {
              "lat": 12.923642,
              "lng": 77.639785
            },
            {
              "lat": 12.923758,
              "lng": 77.639809
            },
            {
              "lat": 12.923794,
              "lng": 77.639816
            },
            {
              "lat": 12.923826,
              "lng": 77.639823
            },
            {
              "lat": 12.923916,
              "lng": 77.639841
            },
            {
              "lat": 12.923873,
              "lng": 77.639948
            },
            {
              "lat": 12.923863,
              "lng": 77.639973
            },
            {
              "lat": 12.923707,
              "lng": 77.640373
            },
            {
              "lat": 12.923606,
              "lng": 77.640638
            },
            {
              "lat": 12.923582,
              "lng": 77.640698
            },
            {
              "lat": 12.923536,
              "lng": 77.640816
            },
            {
              "lat": 12.923432,
              "lng": 77.641092
            },
            {
              "lat": 12.923393,
              "lng": 77.641219
            },
            {
              "lat": 12.923391,
              "lng": 77.64129
            },
            {
              "lat": 12.923398,
              "lng": 77.641375
            },
            {
              "lat": 12.923418,
              "lng": 77.64147
            },
            {
              "lat": 12.923513,
              "lng": 77.6418
            },
            {
              "lat": 12.923587,
              "lng": 77.642056
            },
            {
              "lat": 12.923655,
              "lng": 77.642308
            },
            {
              "lat": 12.923692,
              "lng": 77.642475
            },
            {
              "lat": 12.92384,
              "lng": 77.643048
            },
            {
              "lat": 12.924016,
              "lng": 77.643649
            },
            {
              "lat": 12.924208,
              "lng": 77.644283
            },
            {
              "lat": 12.924319,
              "lng": 77.644599
            },
            {
              "lat": 12.924328,
              "lng": 77.644624
            },
            {
              "lat": 12.924389,
              "lng": 77.644835
            },
            {
              "lat": 12.924436,
              "lng": 77.645011
            },
            {
              "lat": 12.924458,
              "lng": 77.645126
            },
            {
              "lat": 12.924474,
              "lng": 77.645231
            },
            {
              "lat": 12.924491,
              "lng": 77.645454
            },
            {
              "lat": 12.924495,
              "lng": 77.645776
            },
            {
              "lat": 12.924498,
              "lng": 77.646246
            },
            {
              "lat": 12.92451,
              "lng": 77.646572
            },
            {
              "lat": 12.924549,
              "lng": 77.647017
            },
            {
              "lat": 12.92458,
              "lng": 77.647263
            },
            {
              "lat": 12.924615,
              "lng": 77.647572
            },
            {
              "lat": 12.924626,
              "lng": 77.647865
            },
            {
              "lat": 12.924629,
              "lng": 77.648116
            },
            {
              "lat": 12.92463,
              "lng": 77.64817
            },
            {
              "lat": 12.924647,
              "lng": 77.648422
            },
            {
              "lat": 12.924692,
              "lng": 77.648992
            },
            {
              "lat": 12.924701,
              "lng": 77.649132
            },
            {
              "lat": 12.924683,
              "lng": 77.649345
            },
            {
              "lat": 12.92466,
              "lng": 77.649604
            },
            {
              "lat": 12.924621,
              "lng": 77.649716
            },
            {
              "lat": 12.924591,
              "lng": 77.649878
            },
            {
              "lat": 12.924555,
              "lng": 77.650019
            },
            {
              "lat": 12.924509,
              "lng": 77.650155
            },
            {
              "lat": 12.924434,
              "lng": 77.65032
            },
            {
              "lat": 12.924354,
              "lng": 77.651118
            },
            {
              "lat": 12.924277,
              "lng": 77.651754
            },
            {
              "lat": 12.924202,
              "lng": 77.651736
            },
            {
              "lat": 12.924166,
              "lng": 77.651909
            },
            {
              "lat": 12.924082,
              "lng": 77.652347
            },
            {
              "lat": 12.92404,
              "lng": 77.652555
            },
            {
              "lat": 12.923833,
              "lng": 77.653577
            },
            {
              "lat": 12.923585,
              "lng": 77.654754
            }
          ]
        }
      ]
    }
  }
};

export default polygons;
