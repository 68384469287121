import { NavLink, Redirect, Route } from "react-router-dom";

import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import "./console.css";
import { useMediaQuery } from "react-responsive";
import { scrollToTop } from "../../utils/common-functions";
import { useEffect } from "react";
import RequestPageIcon from "@mui/icons-material/RequestPage";

import { connect } from "react-redux";
import Requests from "./components/requests/requests";
import apartmentDetailsApprove from "./components/apartment-details-approve/apartment-details-approve";
import Approve from "./components/approve/approve";
import Societies from "./components/societies/societies";

const Console = ({ userDoc }) => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div style={{ backgroundColor: "#f3f4f5" }}>
      <div
        className="container h-100 w-100 py-4 my-0"
        style={{
          minHeight: "100vh",
        }}
      >
        <div className="row">
          <div
            className="col-12 col-md-3 d-flex flex-row flex-md-column justify-content-around justify-content-md-start"
            style={{
              backgroundColor: isMobileScreen ? "white" : "#f3f4f5",
            }}
          >
            <NavLink
              to="/console/admin/requests"
              className="my-0 my-md-3 py-3 px-4 d-flex align-items-center py-md-2 profile-options-link"
            >
              <RequestPageIcon />
              {!isMobileScreen && <span className="mx-2 pt-1">Requests</span>}
            </NavLink>
            <NavLink
              to="/console/admin/approve"
              className="my-0 my-md-3 py-3 px-4 d-flex align-items-center py-md-2 profile-options-link"
            >
              <DomainVerificationIcon />
              {!isMobileScreen && <span className="mx-2 pt-1">Approve</span>}
            </NavLink>
            <NavLink
              to="/console/admin/societies"
              className="my-0 my-md-3 py-3 px-4 d-flex align-items-center py-md-2 profile-options-link"
            >
              <AddAPhotoIcon />
              {!isMobileScreen && <span className="mx-2 pt-1">Societies</span>}
            </NavLink>
          </div>
          <div className="col-12 col-md-9">
            <Redirect
              exact
              from="/console/admin"
              to="/console/admin/requests/"
            />
            <Route path="/console/admin/requests/" component={Requests} exact />
            <Route path="/console/admin/approve/" component={Approve} exact />
            <Route
              path="/console/admin/societies/"
              component={Societies}
              exact
            />
            <Route
              path="/console/admin/approve/:apartmentId"
              component={apartmentDetailsApprove}
              exact
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDoc: state.firestore.ordered.userDoc
    ? state.firestore.ordered.userDoc[0]
    : {},
});

export default connect(mapStateToProps, null)(Console);
