// import { ReactComponent as NotFoundSvg } from "../../assets/not-found.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

const NotFound = () => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div
      className="container w-100 h-100 d-flex align-items-start align-items-md-center justify-content-center"
      style={{
        minHeight: isMobileScreen ? "90vh" : "100vh",
      }}
    >
      <div
        className=""
        style={{
          fontSize: isMobileScreen ? 200 : 340,
          color: "#3cb6d318",
        }}
      >
        {isMobileScreen && <div style={{ height: 50 }} />}

        <p>404</p>
      </div>
      <div className="position-absolute w-100 h-75 g-black flex-column d-flex align-items-center top-0 justify-content-start justify-content-md-center">
        {isMobileScreen && <div style={{ height: 150 }} />}
        {/* <NotFoundSvg height="300" width="300" /> */}

        <div
          style={{
            marginBottom: "10vh",
          }}
        />
        <p
          className="fc-1c text-capitalize"
          style={{
            fontSize: 20,
          }}
        >
          Sorry, It's not you.It's us
        </p>
        <h5
          className="text-muted text-uppercase text-center"
          style={{
            color: "rgba(255,255,255,.6)",
            fontSize: 14,
            fontFamily: "proxima_nova_rgregular",
          }}
        >
          Unfortunately, we are currently not operating in this area
        </h5>

        <div
          style={{
            marginTop: "10vh",
          }}
        />

        <Link
          to="/"
          className="button-clear p-2 px-4 text-white text-uppercase m-4"
          style={{
            backgroundColor: "#3cd3a3",
            borderRadius: 99,
            fontFamily: "proxima_nova_rgregular",
          }}
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
