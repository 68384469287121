import "./field-error.css";

const FieldError = ({ errMsg, extraPadding }) => (
  <div
    className={`col-12 text-danger my-2 my-md-2 p-0 text-uppercase mx-${
      extraPadding ? "2" : "0"
    }`}
    style={{
      fontFamily: "proxima_nova_rgregular",
      fontSize: 13,
    }}
  >
    {errMsg}
  </div>
);

export { FieldError };
