import { NEW_POST } from "../../models/newPost";

const initState = {
  newPost: NEW_POST,
  uploadingLoader: false,
  doneLoader: false,
  filterArray: [["approved", "==", true]],
  currentCityID: "SSX77pJNAU5wvOIwECnW",
  currentLocalityID: "yqnMZNXH2IRG8TVxw694",
};

const apartmentReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_DATA_TO_NEW_POST":
      return {
        ...state,
        newPost: {
          ...state.newPost,
          [action.payload.name]: action.payload.value,
        },
      };

    case "SET_UPLOADING_LOADER":
      return {
        ...state,
        uploadingLoader: action.payload,
      };

    case "SET_DONE_LOADER":
      return {
        ...state,
        doneLoader: action.payload,
      };

    case "SET_CURRENT_LOCALITY_ID":
      return {
        ...state,
        currentLocalityID: action.payload,
      };

    case "RESET_ALL_INPUTS":
      return {
        ...state,
        newPost: {
          ...NEW_POST,
        },
      };

    case "SET_FILTER_ARRAY":
      return {
        ...state,
        filterArray: action.payload,
      };

    default:
      return state;
  }
};

export default apartmentReducer;
