import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const UserAccount = () => {
  return (
    <div className="container h-100 w-100 py-4">
      <div className="fc-cprimary border-bottom pb-2 d-flex align-items-center">
        <ManageAccountsIcon
          style={{
            fontSize: 40,
          }}
        />

        <h2 className="mx-3 fc-1c pt-2">Account</h2>
      </div>
    </div>
  );
};

export default UserAccount;
