import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CommunityPostCards } from "../community-post-cards/community-post-cards";
import { FaImages } from "react-icons/fa";

const MyPosts = () => {
  const [myPost, setMyPost] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { posts } = useSelector((state) => state.posts);
  useEffect(() => {
    if (user && posts) {
      setMyPost(posts.filter((post) => post.uid === user.uid));
    }
  }, [user, posts]);
  return (
    <Box className="container h-100 w-100 py-4">
      <div className="fc-cprimary border-bottom pb-2 d-flex align-items-center">
        <FaImages style={{ fontSize: 38 }} />

        <h2 className="mx-3 fc-1c pt-2">My Posts</h2>
      </div>
      {myPost.length === 0 && (
        <div
          style={{ fontFamily: "proxima_nova_rgregular" }}
          className="text-center"
        >
          <h3
            style={{ fontFamily: "proxima_nova_rgregular", fontSize: "17px" }}
            className="text-center fc-1c mt-5 "
          >
            You have not created any posts yet.
          </h3>
        </div>
      )}
      {myPost.map((post) => (
        <CommunityPostCards post={post} user={user} />
      ))}
    </Box>
  );
};

export default MyPosts;
