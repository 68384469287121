// Define action types
const SET_ALL_POSTS = "SET_ALL_POSTS";

// Action creator
export const setAllPosts = (posts) => ({
  type: SET_ALL_POSTS,
  payload: posts,
});

const initialState = [];

const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    // Other cases...
    default:
      return state;
  }
};

export default postsReducer;
