import * as React from "react";
import { FaLock } from "react-icons/fa6";
import { IoIosInformationCircleOutline } from "react-icons/io";
import firebase from "../../../../config/fbConfig";
import { setAuthModalConfig } from "../../../../store/actions/actions";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export const Signup = ({ setAuthModalConfig }) => {
  const [isChecked, setIsChecked] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [fullname, setFullname] = React.useState("");
  const [isRealEStateAgent, setIsRealEStateAgent] = React.useState(false);
  const [phoneNo, setPhoneNo] = React.useState("");
  const [uid, setUid] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  React.useEffect(() => {
    setPhoneNo(firebase.auth()?.currentUser?.phoneNumber);
    setUid(firebase.auth()?.currentUser?.uid);
  }, []);
  const AVATARS = [
    "https://www.w3schools.com/howto/img_avatar.png",
    "https://www.w3schools.com/howto/img_avatar2.png",
    "https://www.w3schools.com/w3images/avatar2.png",
    "https://www.w3schools.com/w3images/avatar6.png",
    "https://www.w3schools.com/w3images/avatar5.png",
  ];
  const [avatar, setAvatar] = React.useState(
    AVATARS[Math.floor(Math.random() * AVATARS.length)]
  );

  const signUpNewUser = () => {
    try {
      firebase.firestore().collection("users").doc(uid).set({
        uid,
        fullname,
        email,
        isRealEStateAgent,
        phone: phoneNo,
        isAdmin: false,
        avatar,
      });
      setAuthModalConfig({
        show: false,
        type: "SignUp",
      });
      toast.success("Account Created");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("Error Creating Account", { id: "signupError" });
      console.log("error in signup", error);
    }
  };
  return (
    <div className="modalWrapper">
      <div className="loginModal">
        <p className="heading" style={{ marginBottom: "4px" }}>
          Create Account{" "}
        </p>
        <h6
          style={{ fontSize: "12px", marginBottom: "10px", fontWeight: "100" }}
        >
          We’ll use your email and phone to share the owner’s details.
        </h6>
        <div className="position-relative">
          <label
            htmlFor="fullname"
            style={{
              fontSize: 11,
              marginLeft: 7,
              marginTop: 4,
              color: "gray",
              fontFamily: "proxima_nova_rgregular",
              fontWeight: 600,
              zIndex: 1,
            }}
            className="position-absolute"
          >
            Full Name
          </label>
          <input
            id="fullname"
            style={{ paddingLeft: 11, paddingTop: 15 }}
            type="text"
            onFocus={() =>
              (document.querySelector('label[for="fullname"]').style.color =
                "#0078DB")
            }
            onBlur={() =>
              (document.querySelector('label[for="fullname"]').style.color =
                "gray")
            }
            onChange={(e) => setFullname(e.target.value)}
          />
        </div>
        <div className="position-relative">
          <label
            htmlFor="email"
            style={{
              fontSize: 11,
              marginLeft: 7,
              marginTop: 4,
              color: "gray",
              fontFamily: "proxima_nova_rgregular",
              fontWeight: 600,
              zIndex: 1,
            }}
            className="position-absolute"
          >
            Email Id
          </label>
          <input
            id="email"
            style={{ paddingLeft: 11, paddingTop: 15 }}
            type="text"
            onFocus={() =>
              (document.querySelector('label[for="email"]').style.color =
                "#0078DB")
            }
            onBlur={() =>
              (document.querySelector('label[for="email"]').style.color =
                "gray")
            }
            onChange={(e) => {
              const value = e.target.value;
              const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailPattern.test(value)) {
                // Invalid email logic
                setEmailError(true);
              } else {
                // Valid email logic
                setEmail(value);
                setEmailError(false);
              }
            }}
          />
          {emailError && (
            <div style={{ color: "red", fontSize: "12px", marginTop: "-10px" }}>
              Invalid email
            </div>
          )}
        </div>
        <div className="position-relative">
          <label
            htmlFor="phoneNo"
            style={{
              fontSize: 11,
              marginLeft: 7,
              marginTop: 4,
              color: "gray",
              zIndex: 1,
              fontFamily: "proxima_nova_rgregular",
              fontWeight: 600,
            }}
            className="position-absolute"
          >
            Phone Number
          </label>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "gray",
              fontFamily: "proxima_nova_rgregular",
              background: "#EBECF0",
            }}
            className="phoneInput"
          >
            <p className="pt-3 phoneNumber">{phoneNo}</p>
            <p style={{ paddingTop: 10, paddingRight: 5 }}>
              <FaLock />
            </p>
          </div>
        </div>
        <div className="mt-0">
          <span className="changeNumButton">Change Number </span>
          <span>
            <IoIosInformationCircleOutline />
          </span>
        </div>
        <p
          style={{ fontSize: "15px", fontWeight: "bold", color: "black" }}
          className="heading2 mt-4"
        >
          Are you a Real Estate Agent?
        </p>
        <div className="w-auto">
          <button
            style={{
              width: "30%",
              marginTop: "-80px",
              background: isRealEStateAgent ? "#0078DB" : "white",
              color: isRealEStateAgent ? "white" : "black",
              border: "1px solid gray",
              cursor: "pointer",
              pointerEvents: "all",
              opacity: 1,
            }}
            onClick={() => setIsRealEStateAgent(true)}
          >
            Yes
          </button>
          <button
            style={{
              width: "30%",
              marginLeft: 7,
              marginTop: "-80px",
              background: !isRealEStateAgent ? "#0078DB" : "white",
              color: !isRealEStateAgent ? "white" : "black",
              border: "1px solid gray",
              cursor: "pointer",
              pointerEvents: "all",
              opacity: 1,
            }}
            onClick={() => setIsRealEStateAgent(false)}
          >
            No
          </button>
        </div>
        <div className="d-flex">
          <FormControlLabel
            sx={{ marginTop: 2 }}
            control={
              <Checkbox
                checked={isChecked}
                onChange={() => setIsChecked((prev) => !prev)}
              />
            }
            label=""
          />
          <div
            style={{
              fontSize: "12px",
              lineHeight: "14px",
              marginLeft: "-15px",
              fontFamily: "proxima_nova_rgregular",
            }}
            className="heading2 mt-5"
          >
            I agree to be contacted by Openlist for similar properties or
            related services via WhatsApp, phone(overriding NDNC registration),
            sms, email etc.
          </div>
        </div>

        <button
          onClick={
            fullname.length && email.length > 0 ? signUpNewUser : undefined
          }
          style={{
            marginTop: "-5px",
            opacity: fullname.length && email.length > 0 ? 0.9 : 0.6,
            cursor: "pointer",
          }}
        >
          Create Account
        </button>
        <div
          style={{ fontSize: "12px", lineHeight: "14px" }}
          className="heading2 mt-1"
        >
          By clicking you agree to{" "}
          <span
            style={{
              color: "#0078DB",
              fontFamily: "proxima_nova_rgregular",
              fontWeight: 600,
            }}
          >
            Terms & Conditions
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authModalConfig: state.user.authModalConfig,
});

const mapDispatchToProps = (dispatch) => ({
  setAuthModalConfig: (val) => dispatch(setAuthModalConfig(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
