import React, { useState, useEffect } from "react";
import "./authflow.css";
import { setAuthModalConfig } from "../../../../store/actions/actions";
import { connect } from "react-redux";
import firebase from "../../../../config/fbConfig";
import toast from "react-hot-toast";

export const Login = ({ setAuthModalConfig }) => {
  const [number, setNumber] = useState("");

  const removeCaptcha = () => {
    if (document.querySelector("#re-c"))
      document.querySelector("#re-c").innerHTML = "";
  };

  // For Recaptcha
  function setUpRecaptcha() {
    if (!window.recaptchaVerifier) {
      // Check if the container element is empty before initializing reCAPTCHA
      const captchaContainer = document.getElementById("re-c");
      if (captchaContainer && captchaContainer.children.length === 0) {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          captchaContainer,
          {
            size: "invisible",
            callback: function (response) {
              // reCAPTCHA verification completed, proceed with sign-in
              verifyNumber();
            },
          }
        );
      }
    }
  }
  setUpRecaptcha();
  const verifyNumber = () => {
    if (number.length !== 10) {
      return;
    }
    window.localStorage.setItem("phoneNumber", number);
    const toastId = toast.loading("Sending OTP...", { id: "loading" });
    const phoneNumber = `+91${parseInt(number)}`;
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log("otp sent");
        toast.dismiss(toastId);
        toast.success("OTP sent");
        setAuthModalConfig({
          show: true,
          type: "OTP",
        });
        console.log("********************");
        console.log(confirmationResult);
      })
      .catch((error) => {
        // Error; SMS not sent
        removeCaptcha();
        console.log("error in otp sent::", error);
        toast.dismiss(toastId);
        toast.error("OTP sent error", { id: "error" });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    verifyNumber();
  };
  return (
    <div className="modalWrapper">
      <div className="loginModal">
        <div className="login">
          <p className="heading">
            Login / Register
            <span
              className="close_modal"
              onClick={() => {
                setAuthModalConfig({
                  show: false,
                  type: "Login",
                });
              }}
            >
              X
            </span>
          </p>
          <p className="heading2">Please enter your Phone Number</p>{" "}
          <input
            onChange={(e) => setNumber(e.target.value)}
            type="tel"
            placeholder="Phone Number"
          />
          <button
            style={{
              cursor: "pointer",
              opacity: number.length === 10 ? 0.9 : 0.6,
            }}
            onClick={number.length === 10 ? handleSubmit : undefined}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authModalConfig: state.user.authModalConfig,
});

const mapDispatchToProps = (dispatch) => ({
  setAuthModalConfig: (val) => dispatch(setAuthModalConfig(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
