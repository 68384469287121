import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
  Polygon,
} from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import polygons from "./polygons";

function Map(props) {
  let lat = props.newPostData.latLng.lat;
  let lng = props.newPostData.latLng.lng;
  const [mapCenter, setMapCenter] = useState(null);
  const [markers, setMarkers] = useState([]);
  console.log("Props:", props);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  const center = {
    lat: lat,
    lng: lng,
  };

  useEffect(() => {
    if (lat && lng) {
      setMapCenter(center);
    }
    const geocoder = new window.google.maps.Geocoder();

    if (props.cityPlaceId) {
      geocoder.geocode(
        { address: props.cityPlaceId + ", Bangalore" },
        (results, status) => {
          if (status === "OK" && results[0]) {
            setMapCenter(results[0].geometry.location);
          } else {
            console.error("Geocode error: ", status);
          }
        }
      );
    }
    console.log("lat long", mapCenter);
    // Mock data for markers, replace with your data
    const mockMarkers = [
      { lat: 12.98132, lng: 77.75918 },
      { lat: 12.98072, lng: 77.74291 },
      { lat: 12.98518, lng: 77.74912 },
      { lat: 12.98229, lng: 77.75667 },
      { lat: 12.98691, lng: 77.75069 },
      { lat: 12.97977, lng: 77.75004 },
      { lat: 12.97298, lng: 77.75142 },
    ];
    setMarkers(mockMarkers);
  }, [props.cityPlaceId, lat, lng]);

  const mapOptions = {
    fillColor: "#A4E1F2", // Changed fill color to blue
    fillOpacity: 0.4,
    strokeColor: "#45b7d5", // Changed stroke color to blue
    strokeOpacity: 1,
    strokeWeight: 2,
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    minZoom: 12,
    maxZoom: 16,
    gestureHandling: "greedy",
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }], // Hides points of interest labels
      },
      {
        featureType: "transit",
        elementType: "labels",
        stylers: [{ visibility: "off" }], // Hides transit labels
      },
      {
        featureType: "road",
        elementType: "labels",
        stylers: [{ visibility: "off" }], // Hides road labels
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "off" }], // Customizes water color
      },
    ],
  };

  const svgIcon = `
  <svg width="64" height="64" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <!-- Red ring between the outer and inner circles -->
    <circle cx="16" cy="16" r="8" stroke="none" fill="rgba(60, 182, 211, .3)"/> <!-- Transparent red fill -->
    <!-- Inner ring (solid color) -->
    <circle cx="16" cy="16" r="6" stroke="#3cb6d3" stroke-width="0" fill="#3cb6d3"/>
    <!-- Number text in the center -->
    <text x="50%" y="50%" font-size="8" text-anchor="middle" stroke="white" stroke-width="0.3px" dy=".3em" fill="white">1</text>
  </svg>
`;

  const customIcon = {
    url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgIcon)}`,
    scaledSize: new window.google.maps.Size(64, 64), // Adjust size if needed
  };
  const getPolygonCoordinates = (placeId) => {
    const cityData = polygons.cities.Bangalore; // Adjust if needed
    const normalizedPlaceId = placeId.trim().toLowerCase();

    const locality = cityData.localities.find(
      (loc) => loc.name.trim().toLowerCase() === normalizedPlaceId
    );

    return locality ? locality.coordinates : [];
  };

  const polygonCoords = getPolygonCoordinates(props.cityPlaceId);

  return (
    isLoaded && (
      <>
        <GoogleMap
          mapContainerStyle={{ height: "100%" }}
          center={mapCenter}
          zoom={14}
          options={mapOptions}
        >
          <MarkerClusterer>
            {(clusterer) =>
              markers.map((marker, index) => {
                return (
                  <Marker
                    key={index}
                    position={{ lat: marker.lat, lng: marker.lng }}
                    clusterer={clusterer}
                    icon={customIcon}
                  />
                );
              })
            }
          </MarkerClusterer>
          <Polygon paths={polygonCoords} options={mapOptions} />
        </GoogleMap>
      </>
    )
  );
}

const mapStateToProps = (state) => ({
  newPostData: state.apartment.newPost,
});

export default connect(mapStateToProps, null)(Map);
