import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { scrollToTop } from "../../utils/common-functions";
import Apartment from "../../components/catalogue/components/apartment/apartment";
import Loader from "../../components/common/loader/Loader";
import firebase from "../../config/fbConfig";
import PropertyFilters from "../../components/catalogue/components/property-filters/property-filters";
import { setCurrentLocalityID } from "../../store/actions/actions";
import "../../components/catalogue/components/apartments/apartments.css";

const DEFAULT_MIN_RANGE = 0;
const DEFAULT_MAX_RANGE = 100000;

const AapartmentPages = (props) => {
  const [mobileViewFilters, setMobileViewFilters] = useState(false);
  const { apartments, setCurrentLocalityID } = props;

  // filters
  const [selectedApartmentTypes, setSelectedApartmentTypes] = useState([]);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState("");
  const [selectedFurnishingTypes, setSelectedFurnishingTypes] = useState("");
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedLooking, setSelectedLooking] = useState("");
  const [minRentPrice, setMinRentPrice] = useState(DEFAULT_MIN_RANGE);
  const [maxRentPrice, setMaxRentPrice] = useState(DEFAULT_MAX_RANGE);
  const [societyFilter, setSocietyFilter] = useState("");
  const [filteredApartments, setFilteredApartments] = useState(undefined);

  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const [fetch, setFetch] = useState(false);
  const [normalData, setNormalData] = useState(undefined);
  const [nearByData, setNearByData] = useState(undefined);

  useEffect(() => {
    setNormalData(undefined);
    setNearByData(undefined);
    if (props.match.params.id) {
      setCurrentLocalityID(props.match.params.id);
    }
    // eslint-disable-next-line
  }, []);

  // fetch nearby data
  useEffect(() => {
    if (fetch) {
      setNearByData([]);

      props.nearByLocalities[0] &&
        props.nearByLocalities[0].nearBy &&
        props.nearByLocalities[0].nearBy.forEach((item) => {
          firebase
            .firestore()
            .collection("city")
            .doc(props.currentCityID)
            .collection("locality")
            .doc(item)
            .collection("estate")
            .where("approved", "==", true)
            .get()
            .then((d) => {
              if (!d.size) return;

              setNearByData((prev) => [
                ...(prev ? prev : []),
                ...d.docs.map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                  localityID: item,
                  isNearBy: true,
                })),
              ]);
            });
        });
    }

    setFetch(false);
    // eslint-disable-next-line
  }, [fetch, props.nearByLocalities]);

  useEffect(() => {
    if (props.nearByLocalities) {
      setFetch(true);
    }
  }, [props.nearByLocalities]);

  useEffect(() => {
    setNormalData(apartments);
  }, [apartments]);

  useEffect(() => {
    if (nearByData === undefined || normalData === undefined) {
      setFilteredApartments(undefined);
      return;
    }

    // Combine normalData and nearByData arrays
    const allApartments = [...normalData, ...nearByData];

    const sortedApartments = allApartments.sort((a, b) => {
      return b.postedOn - a.postedOn;
    });

    // Apply filters on the sorted array
    const filtered = sortedApartments.filter((item) => {
      let isCost = false,
        isIdeal = false,
        isCat = false,
        isSociety = false;

      // rent filter
      if (
        parseInt(item.cost) >= parseInt(minRentPrice) &&
        parseInt(item.cost) <= parseInt(maxRentPrice)
      )
        isCost = true;

      // looking for filter
      if (
        selectedLooking.length === 0 ||
        item.idealFor.find((i) => i === selectedLooking)
      ) {
        isIdeal = true;
      }

      // category filter
      if (
        selectedCat.length === 0 ||
        item.tags.find((i) => i === selectedCat)
      ) {
        isCat = true;
      }

      if (
        societyFilter.length === 0 ||
        item.societyName
          .toLowerCase()
          .includes(societyFilter.toLowerCase().trim())
      ) {
        isSociety = true;
      }
      return isCost && isIdeal && isCat && isSociety;
    });

    // Set the filtered and sorted apartments
    setFilteredApartments(filtered);
    scrollToTop();
    // eslint-disable-next-line
  }, [
    maxRentPrice,
    minRentPrice,
    selectedLooking,
    selectedCat,
    normalData,
    nearByData,
    societyFilter,
  ]);

  const resetAllFilters = () => {
    setSelectedApartmentTypes([]);
    setSelectedPropertyTypes("");
    setSelectedFurnishingTypes("");
    setSelectedCat("");
    setSelectedLooking("");
    setSocietyFilter("");

    setMaxRentPrice(DEFAULT_MAX_RANGE);
    setMinRentPrice(DEFAULT_MIN_RANGE);
  };

  const [flats, setFlats] = useState([]);
  const { locality } = useParams();
  console.log(locality);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFlats = async () => {
      try {
        const res = await axios.get(
          `https://urbanletting-6afef.df.r.appspot.com/properties?city=bangalore&locality=${locality}`
        );
        setFlats(res.data);
        setLoading(false); // Set loading to false after data is fetched
        console.log(flats);
      } catch (err) {
        console.log(err);
      }
    };
    fetchFlats();
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#00000010",
        minHeight: "100vh",
      }}
      className="pt-0"
    >
      <div className="dashboard container h-100">
        <div className="row d-flex mb-0 h-100">
          <div
            className="position-fixed col-12 col-md-3 my-4 rounded d-none d-md-block filter-sidebar z-999"
            style={{
              paddingRight: 10,
              width: "20%",
              overflow: "auto",
              height: "100%",
            }}
          >
            <PropertyFilters
              selectedFurnishingTypes={selectedFurnishingTypes}
              setSelectedFurnishingTypes={setSelectedFurnishingTypes}
              selectedPropertyTypes={selectedPropertyTypes}
              setSelectedPropertyTypes={setSelectedPropertyTypes}
              selectedApartmentTypes={selectedApartmentTypes}
              setSelectedApartmentTypes={setSelectedApartmentTypes}
              selectedLooking={selectedLooking}
              setSelectedLooking={setSelectedLooking}
              selectedCat={selectedCat}
              setSelectedCat={setSelectedCat}
              minRentPrice={minRentPrice}
              setMinRentPrice={setMinRentPrice}
              maxRentPrice={maxRentPrice}
              setMaxRentPrice={setMaxRentPrice}
              resetAllFilters={resetAllFilters}
              societyFilter={societyFilter}
              setSocietyFilter={setSocietyFilter}
            />
          </div>
          {mobileViewFilters && (
            <div
              className="position-fixed d-block d-md-none w-100 bg-white top-0 p-0"
              style={{
                zIndex: 10,
                overflow: "scroll",
                height: "100vh",
              }}
            >
              <div
                className="pt-4"
                style={{
                  backgroundColor: "#00000010",
                  marginTop: 70,
                }}
              >
                <PropertyFilters
                  selectedFurnishingTypes={selectedFurnishingTypes}
                  setSelectedFurnishingTypes={setSelectedFurnishingTypes}
                  selectedPropertyTypes={selectedPropertyTypes}
                  setSelectedPropertyTypes={setSelectedPropertyTypes}
                  selectedApartmentTypes={selectedApartmentTypes}
                  setSelectedApartmentTypes={setSelectedApartmentTypes}
                  selectedLooking={selectedLooking}
                  setSelectedLooking={setSelectedLooking}
                  selectedCat={selectedCat}
                  setSelectedCat={setSelectedCat}
                  minRentPrice={minRentPrice}
                  setMinRentPrice={setMinRentPrice}
                  maxRentPrice={maxRentPrice}
                  setMaxRentPrice={setMaxRentPrice}
                  resetAllFilters={resetAllFilters}
                  societyFilter={societyFilter}
                  setSocietyFilter={setSocietyFilter}
                />
              </div>
            </div>
          )}

          <div
            className="position-fixed w-100 bottom-0 p-4 d-block d-md-none d-flex justify-content-center"
            style={{
              zIndex: 10,
            }}
          >
            <button
              type="button"
              className="clean-button text-uppercase text-white px-4 py-2"
              onClick={() => setMobileViewFilters((prev) => !prev)}
              style={{
                background: "rgb(60, 211, 163)",
                borderRadius: 99,
                width: 110,
              }}
            >
              {mobileViewFilters ? "Apply" : "Filters"}
            </button>
          </div>
          <div className="d-none d-md-block col-3"></div>
          <div
            className="col-12 col-md-9"
            style={{
              paddingLeft: isMobileScreen ? 10 : 20,
              borderLeft: "1px solid #00000010",
            }}
          >
            {filteredApartments && filteredApartments.length > 0 && (
              <h5 className="mt-4 fc-1c">
                {filteredApartments.length} results | Property in{" "}
                {filteredApartments[0].localeName}, {filteredApartments[0].city}{" "}
                for Rent
              </h5>
            )}
            {loading ? (
              <Loader /> // Show loader while data is being fetched
            ) : flats && flats.length > 0 ? (
              flats.map((flat) => (
                <Apartment key={flat.id} {...flat} {...props} />
              ))
            ) : (
              <div
                className="d-flex fc-1c justify-content-center align-items-center"
                style={{
                  height: "90vh",
                }}
              >
                No results found
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    apartments: state.firestore.ordered.catlogue,
    nearByLocalities: state.firestore.ordered.nearByLocalities,
    currentCityID: state.apartment.currentCityID,
    currentLocalityID: state.apartment.currentLocalityID,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentLocalityID: (val) => dispatch(setCurrentLocalityID(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AapartmentPages);
