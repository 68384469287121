import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Map from "../../../common/google-map/google-map";
import { scrollToTop } from "../../../../utils/common-functions";
import { FieldError } from "../../../common/field-error/field-error";

const PageOne = ({ handleNavClick, newPostData, addNewPostData }) => {
  const [latlng, setLatlng] = useState({
    lat: 41.31694479999999,
    lng: -74.1250465,
  });
  const [nextClicked, setNextClicked] = useState(false);
  const [showBangErr, setShowBangErr] = useState(false);
  const [addressError, setAddressError] = useState(false);

  const handleSelect = async (value) => {
    setAddressError(false);
    console.log("vl", value);
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);

    setLatlng(() => ({ ...latLng }));

    let spliited_data = value.split(",");
    let len = spliited_data.length;
    let city = "";
    if (len <= 3) {
      setAddressError(true);
      return;
    }
    city = len >= 4 ? value.split(",")[len - 3] : "";
    let societyName = len >= 6 ? spliited_data[0] : "";

    addNewPostData({
      name: "latLng",
      value: { ...latLng },
    });

    addNewPostData({
      name: "societyName",
      value: societyName,
    });

    addNewPostData({
      name: "address",
      value: value,
    });

    addNewPostData({
      name: "city",
      value: len >= 4 ? value.split(",")[len - 3] : "",
    });

    setShowBangErr(!"bengaluru".includes(city.trim().toLowerCase()));

    addNewPostData({
      name: "locality",
      value: len >= 4 ? value.split(",")[len - 4].trim() : "",
    });
  };

  const { locality, city } = newPostData;

  return (
    <>
      <div className="py-4 border-bottom pb-4">
        <h2 className="mb-4 fc-1c mt-4">Property Location</h2>
        <p
          style={{
            fontFamily: "proxima_nova_rgregular",
          }}
        >
          Select your property address below then confirm it's correct location
          on the map
        </p>

        <div className="row">
          <div className="col-8 col-md-6">
            <PlacesAutocomplete
              value={city}
              onChange={(city) => {
                addNewPostData({ name: "city", value: city });
              }}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="places-search-bar position-relative p-0 d-flex flex-column align-items-center mx-auto">
                  {/* <span className="position-absolute left-0 w-100 h-100">
                    <SearchIcon
                      style={{
                        color: "#3cb6d3",
                        margin: "0 7px",
                        fontSize: 28,
                      }}
                    />
                  </span> */}
                  <div className="d-flex w-100 align-items-center bg-white input-container">
                    <input
                      {...getInputProps({
                        placeholder: "Search City, Neighbourhood or Address",
                      })}
                      className="m-0 px-2 location-input"
                      style={{
                        fontFamily: "proxima_nova_rgregular",
                        borderColor: "transparent",
                      }}
                    />
                  </div>

                  {addressError && (
                    <FieldError errMsg="enter address with locality (ex:- Whitefield, Bangalore, Karnataka, India)" />
                  )}

                  <div
                    className="position-absolute bg-white left-0 right-0"
                    style={{
                      border: `${
                        suggestions.length ? "1" : "0"
                      }px solid rgba(0,0,0,.2)`,
                      backdropFilter: "blur(10px)",
                      zIndex: 2,
                      top: 55,
                      minWidth: "100%",
                    }}
                  >
                    {suggestions
                      .filter((s) =>
                        s.terms.some(
                          (term) =>
                            term.value === "Bengaluru" ||
                            term.value === "Bangalore"
                        )
                      )
                      .map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "rgba(0,0,0,.1)"
                            : "white",
                          color: "black",
                        };

                        return (
                          <div
                            key={suggestion.description}
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description && (
                              <div className="d-flex align-items-center border-bottom p-2 text-muted">
                                <LocationOnIcon />
                                <p
                                  style={{
                                    fontFamily: "proxima_nova_rgregular",
                                  }}
                                  className="result-item w-100 fc-1c m-0 mx-2"
                                >
                                  {suggestion.description}
                                </p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className="col-4 col-md-2 input-container">
            <input
              placeholder="Locality"
              className="m-0 px-2 input bg-white b-shadow location-input"
              value={locality}
              onChange={(e) =>
                addNewPostData({ name: "locality", value: e.target.value })
              }
              style={{
                fontFamily: "proxima_nova_rgregular",
                borderColor: "transparent",
              }}
            />
          </div>
        </div>

        <div style={{ marginTop: "-20px" }}>
          {showBangErr && (
            <FieldError
              errMsg="Please select Bangalore localities as currently we are operating in
            Bangalore only."
            />
          )}

          {(newPostData.city.length === 0 ||
            newPostData.locality.length === 0) &&
            nextClicked &&
            !addressError &&
            !showBangErr && (
              <FieldError errMsg="Please select locality and city above." />
            )}
        </div>
        <div className={`row m-0 ${!addressError && "mt-5"} `}>
          <div
            style={{ height: "200px" }}
            className="col col-md-8 p-1 p-md-2 bg-white"
          >
            <Map isMarkerShown latlng={latlng} />
          </div>
        </div>
      </div>

      <div className="d-flex w-100 justify-content-end my-4 mb-0 pb-4">
        <button
          onClick={() => {
            setNextClicked(true);
            if (
              newPostData.city.length !== 0 &&
              newPostData.locality.length !== 0 &&
              (newPostData.city.trim() === "Bangalore" ||
                newPostData.city.trim() === "Bengaluru")
            ) {
              handleNavClick("NEXT");
            } else {
              scrollToTop();
            }
          }}
          className="text-white border-none bg-cprimary m-2 px-4 input-form-btns"
        >
          Next
        </button>
      </div>
    </>
  );
};

export default PageOne;
