import "./uploading-data-loader.css";

const UploadingDataLoader = () => {
  return (
    <div
      className="position-fixed w-100 h-100 d-flex justify-content-center align-items-center"
      style={{
        zIndex: 1100, // Increased z-index value
        backgroundColor: "#00000025",
        backdropFilter: "blur(2px)",
      }}
    >
      <div
        className="loader-inner-div rounded p-1"
        style={{
          backgroundColor: "#00000095",
          backdropFilter: "blur(20px)",
        }}
      >
        <svg id="loader" viewBox="00 00 50 50">
          <circle
            id="loader-circle"
            fill="none"
            cx="25"
            cy="25"
            r="20"
          ></circle>
        </svg>
      </div>
    </div>
  );
};

export default UploadingDataLoader;
