export const addNewPostData = (payload) => {
  return {
    type: "ADD_DATA_TO_NEW_POST",
    payload: payload,
  };
};

export const resetAllInputs = () => {
  return {
    type: "RESET_ALL_INPUTS",
  };
};

export const setUploadingLoader = (payload) => {
  return {
    type: "SET_UPLOADING_LOADER",
    payload,
  };
};

export const setDoneLoader = (payload) => {
  return {
    type: "SET_DONE_LOADER",
    payload,
  };
};

export const setAuthModalConfig = (payload) => {
  return {
    type: "SET_AUTH_MODAL_CONFIG",
    payload,
  };
};

export const setUser = (payload) => {
  return {
    type: "SET_USER",
    payload,
  };
};

export const setWelcomeMsgConfig = (payload) => {
  return {
    type: "SET_WELCOME_MSG_CONFIG",
    payload,
  };
};

export const setFilterArray = (payload) => {
  return {
    type: "SET_FILTER_ARRAY",
    payload,
  };
};

export const setCity = (payload) => {
  return {
    type: "SET_CITY_NAME",
    payload,
  };
};

export const setLocality = (payload) => {
  return {
    type: "SET_LOCALITY_NAME",
    payload,
  };
};

export const setDocId = (payload) => {
  return {
    type: "SET_DOC_ID",
    payload,
  };
};

export const setCurrentLocalityID = (payload) => {
  return {
    type: "SET_CURRENT_LOCALITY_ID",
    payload,
  };
};

export const setShowChatList = (payload) => {
  return {
    type: "SET_SHOW_CHAT_LIST",
    payload,
  };
};

export const setShowChatBox = (payload) => {
  return {
    type: "SET_SHOW_CHAT_BOX",
    payload,
  };
};

export const setCurrFriendID = (payload) => {
  return {
    type: "SET_CURR_FRIEND_ID",
    payload,
  };
};

export const setCurrFriendObj = (payload) => {
  return {
    type: "SET_CURR_FRIEND_OBJ",
    payload,
  };
};
