import React, { useState, useRef, useEffect } from "react";
import { setAuthModalConfig } from "../../../../store/actions/actions";
import { connect } from "react-redux";
import firebase from "../../../../config/fbConfig";
import toast from "react-hot-toast";

export const OtpInput = ({ setAuthModalConfig }) => {
  const [otp, setOTP] = useState(new Array(6).fill(""));
  const [resendDisabled, setResendDisabled] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const [number, setNumber] = useState("");
  const otpInputs = useRef([]);
  const removeCaptcha = () => {
    if (document.querySelector("#re-c"))
      document.querySelector("#re-c").innerHTML = "";
  };

  useEffect(() => {
    console.log("in use effect:", window.localStorage?.phoneNumber);
    setNumber(window.localStorage?.phoneNumber);
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleChange = (index, value) => {
    if (isNaN(value)) return; // Only allow numeric input
    const newOTP = [...otp];
    newOTP[index] = value[value.length - 1]; // Only take the last digit
    setOTP(newOTP);
    if (value !== "" && otpInputs.current[index + 1]) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleKeyUp = (index, e) => {
    if (e.keyCode === 8 && otpInputs.current[index - 1]) {
      otpInputs.current[index - 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.keyCode === 8 && otpInputs.current[index - 1]) {
      otpInputs.current[index - 1].focus();
      const newOTP = [...otp];
      newOTP[index - 1] = ""; // Clear the previous digit
      setOTP(newOTP);
    }
  };

  const verifyNumber = () => {
    console.log("Verifying number", number);
    const toastId = toast.loading("Sending OTP...", { id: "loading" });
    const phoneNumber = `+91${parseInt(number)}`;
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log("otp sent");
        toast.dismiss(toastId);
        toast.success("OTP sent");
        console.log("********************");
        console.log(confirmationResult);
      })
      .catch((error) => {
        // Error; SMS not sent
        removeCaptcha();
        toast.dismiss(toastId);
        toast.error("OTP sent error", { id: "error" });
      });
  };
  const handleResendOTP = () => {
    // Logic to resend OTP
    setResendDisabled(true);
    setRemainingTime(60);
    // Additional logic to resend OTP
    console.log("into resend otp");
    verifyNumber();
    console.log("after resend otp");
  };

  useEffect(() => {
    let timer;
    if (resendDisabled) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          setAuthModalConfig({
            show: true,
            type: "OTP",
          });
          if (prevTime === 1) {
            clearInterval(timer);
            setResendDisabled(false);
          }
          return prevTime - 1;
        });
      }, 1000); // Update every second
    }
    return () => clearInterval(timer);
  }, [resendDisabled]);

  useEffect(() => {
    setResendDisabled(true);
  }, []);

  const verifyOTP = () => {
    if (!window.confirmationResult) {
      return;
    }
    const toastId = toast.loading("Verifying OTP...");
    const validOtp = otp.toString().split(",").join("");
    console.log("otp:::", validOtp);

    window.confirmationResult
      .confirm(validOtp)
      .then((result) => {
        // User signed in successfully.
        const { uid, phoneNumber } = result.user;
        console.log("otp verified", uid, phoneNumber);
        firebase
          .firestore()
          .collection("users")
          .where("uid", "==", uid)
          .get()
          .then((d) => {
            if (d.docs.length) {
              toast.dismiss(toastId);
              toast.success("Login Successfully");
              setAuthModalConfig({ show: false, type: "Login" });
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              firebase
                .firestore()
                .collection("users")
                .doc(uid)
                .set({
                  uid,
                  phone: phoneNumber,
                  isAdmin: false,
                })
                .then(() => {
                  toast.dismiss(toastId);
                  toast.success("OTP Verified");
                  setAuthModalConfig({ show: true, type: "SignUp" });
                })
                .catch((err) => {
                  console.log(err);
                  toast.dismiss(toastId);
                  toast.error("Error In SignUp", { id: "signupError" });
                });
              //new user
            }
          });
      })
      .catch((error) => {
        removeCaptcha();
        toast.dismiss(toastId);
        toast.error("Invalid OTP", { id: "otpError" });
      });
  };
  return (
    <div className="modalWrapper">
      <div className="loginModal">
        <p className="heading">
          Verify your number{" "}
          <span
            onClick={() => {
              setAuthModalConfig({
                show: false,
                type: "OTP",
              });
            }}
            className="close_modal"
          >
            X
          </span>
        </p>
        <p
          style={{
            fontFamily: "proxima_nova_rgregular",
            fontWeight: 600,
            marginTop: 17,
            opacity: "0.9",
          }}
        >
          Enter your OTP
        </p>
        <div className="input_div d-flex gap-2">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="tel"
              id="OtpInput"
              maxLength={1}
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyUp={(e) => handleKeyUp(index, e)}
              // onKeyDown={(e) => handleKeyDown(index, e)}
              ref={(input) => (otpInputs.current[index] = input)}
            />
          ))}
        </div>
        <div
          style={{ fontFamily: "proxima_nova_rgregular", marginTop: "-8px" }}
        >
          Haven't received yet?{" "}
          <span
            style={{
              color: "#0078DB",
              fontSize: 17,
              fontWeight: 650,
              fontFamily: "proxima_nova_rgregular",
            }}
          >
            {resendDisabled ? (
              <span
                style={{
                  cursor: "wait",
                  fontSize: 13,
                  fontFamily: "proxima_nova_rgregular",
                }}
              >
                Resend OTP in {formatTime(remainingTime)}
              </span>
            ) : (
              <span
                style={{
                  color: "#0078DB",
                  fontSize: 17,
                  fontWeight: 650,
                  fontFamily: "proxima_nova_rgregular",
                }}
                onClick={handleResendOTP}
              >
                Resend OTP
              </span>
            )}
          </span>
        </div>
        <button
          style={{ cursor: "pointer", opacity: otp[5] ? 0.9 : 0.6 }}
          onClick={otp[5] ? verifyOTP : undefined}
        >
          Verify & Continue
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authModalConfig: state.user.authModalConfig,
});

const mapDispatchToProps = (dispatch) => ({
  setAuthModalConfig: (val) => dispatch(setAuthModalConfig(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtpInput);
