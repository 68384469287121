import { useEffect, useState } from "react";
import "./upload-videos.css";
import DeleteIcon from "@material-ui/icons/Delete";
import uuid from "uuid/v1";
import CircularProgress from "@mui/material/CircularProgress";
import { storage } from "../../../config/fbConfig";
// import imageCompression from "browser-image-compression";

function UploadVideos({ videos, setVideos, newPostData }) {
  const [dragId, setDragId] = useState();
  const [dragModeOn, setDragMode] = useState(false);
  const [isUploading, setUploading] = useState(false);

  useEffect(() => {
    if (isUploading) {
      uploadAllImages();
    }
    // console.log(videos);
    setUploading(false);
    // eslint-disable-next-line
  }, [videos]);

  const isAllUploaded = () => newPostData.videos.every((vid) => vid.length > 0);

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
    setDragMode(true);
  };

  const handleDrop = (ev) => {
    const dragVideo = videos.find((video) => video.id === dragId);
    const dropVideo = videos.find((video) => video.id === ev.currentTarget.id);

    const dragVideoOrder = dragVideo.order;
    const dropVideoOrder = dropVideo.order;

    const newVideosState = videos.map((video) => {
      if (video.id === dragId) {
        video.order = dropVideoOrder;
      }
      if (video.id === ev.currentTarget.id) {
        video.order = dragVideoOrder;
      }
      return video;
    });

    setVideos(newVideosState);
    setDragMode(false);
  };

  const uploadVideos = (e) => {
    if (e.target.files.length) {
      const inc = videos ? videos.length : 0;
      const temp = Array.from(e.target.files).map((item, i) => ({
        file: item,
        order: i + inc,
        id: uuid(),
        url: "",
      }));

      setVideos((prev) => [...new Set([...prev, ...temp])]);

      setUploading(true);
    }
  };

  const removeVideo = (id) => {
    setVideos((prev) => prev.filter((vid) => vid.id !== id));
  };

  const uploadAllImages = () => {
    Promise.all(
      // Array of "Promises"
      videos.map((item) => uploadImageAsPromise(item, item.url))
    )
      .then((url) => {
        // console.log(`All success`, url);
      })
      .catch((error) => {
        console.log(`Some failed: `, error.message);
      });
  };

  //Handle waiting to upload each file using promise
  function uploadImageAsPromise(imageFile, url) {
    if (url) {
      return;
    }
    return new Promise(function (resolve, reject) {
      var storageRef = storage.ref("videos/" + imageFile.file.name);

      //Upload file
      var task = storageRef.put(imageFile.file);

      //Update progress bar
      task.on(
        "state_changed",
        function progress(snapshot) {
          // var percentage =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log(percentage);
        },
        function error(err) {},
        async () => {
          const url = await storageRef.getDownloadURL();
          setVideos((prev) => [
            ...prev.filter((item) => item.id !== imageFile.id),
            {
              ...prev.filter((item) => item.id === imageFile.id)[0],
              url: url,
            },
          ]);
        }
      );
    });
  }

  return (
    <>
      <div
        className="row upload-videos"
        style={
          dragModeOn
            ? {
                border: "1px dashed #ff000050",
              }
            : {}
        }
      >
        {videos
          .sort((a, b) => a.order - b.order)
          .map((video) => (
            <li
              className="col-lg-3 col-md-12 p-3 rounded position-relative d-flex images-container transition-3s"
              style={{
                height: 200,
              }}
              id={video.id}
              draggable={true}
              key={video.id}
              onDragOver={(ev) => {
                ev.preventDefault();
              }}
              onDragStart={handleDrag}
              onDrop={handleDrop}
            >
              <div className="w-100 h-100 position-relative bg-white">
                <video
                  className="p-0"
                  src={URL.createObjectURL(video.file)}
                  alt="Selected file"
                  controls
                  width="100%"
                  height="100%"
                ></video>

                {video.url.length === 0 && !isAllUploaded() && (
                  <div
                    className="position-absolute w-100 h-100 left-0 bottom-0"
                    style={{
                      zIndex: 20,
                      backdropFilter: "blur(2px)",
                      background: "#00000005",
                    }}
                  >
                    <div
                      className="d-flex align-items-center flex-column justify-content-center"
                      style={{
                        marginTop: "20%",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  </div>
                )}
              </div>

              <div
                className="position-absolute rounded-circle p-2 transition-3s del-button"
                onClick={() => removeVideo(video.id)}
              >
                <DeleteIcon />
              </div>
            </li>
          ))}
        <div className="col-12 col-lg-3 col-md-12 p-3">
          <input
            type="file"
            className="bg-white w-100 w-100 position-relative post-property-img-input"
            accept="video/*"
            onChange={uploadVideos}
            multiple
            disabled={!isAllUploaded()}
            style={{
              height: 170,
              border: "2px dashed gray",
            }}
          />
        </div>
      </div>
    </>
  );
}

export default UploadVideos;
