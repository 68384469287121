import { React } from "react";

import { connect } from "react-redux";
import { FieldError } from "../field-error/field-error";

import { addNewPostData } from "../../../store/actions/actions";

const CountComponent = ({
  heading,
  name,
  newPostData,
  addNewPostData,
  pageTwoClicked,
}) => {
  return (
    <div className="row my-3 my-md-0 d-flex align-items-center">
      <h6 className="col-6 col-md-12">{heading}</h6>
      <div
        className="d-flex align-items-center mx-auto mx-md-0 justify-content-between"
        style={{
          width: 130,
        }}
      >
        <button
          onClick={() =>
            addNewPostData({
              name,
              value: newPostData[name] === 0 ? 0 : newPostData[name] - 1,
            })
          }
          className="border px-3 button bg-white py-2 rounded-circle text-muted"
        >
          -
        </button>
        <h5 className="m-0 text-muted">{newPostData[name]}</h5>
        <button
          onClick={() => addNewPostData({ name, value: newPostData[name] + 1 })}
          className="border px-3 py-2 rounded-circle bg-white text-muted"
        >
          +
        </button>
      </div>

      {newPostData[name] === 0 && pageTwoClicked && (
        <FieldError errMsg={`enter ${name}`} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  newPostData: state.apartment.newPost,
});

const mapDispatchToProps = (dispatch) => ({
  addNewPostData: (obj) => dispatch(addNewPostData(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountComponent);
