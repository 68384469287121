import React from "react";
import { setAuthModalConfig } from "../../../store/actions/actions";
import { connect } from "react-redux";
import firebase from "../../../config/fbConfig";
import { Redirect } from "react-router-dom";
export const ProtectedRoute = ({ children, setAuthModalConfig }) => {
  const uid = firebase.auth()?.currentUser?.uid;
  if (uid !== undefined) return children;
  else {
    setAuthModalConfig({
      show: true,
      type: "Login",
    });
    return <Redirect to="/" />;
  }
};

const mapStateToProps = (state) => ({
  authModalConfig: state.user.authModalConfig,
});

const mapDispatchToProps = (dispatch) => ({
  setAuthModalConfig: (val) => dispatch(setAuthModalConfig(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
