import React, { useState } from "react";
import { Grid, Paper, Typography, Modal, IconButton, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";

export default function ImagePost({ images }) {
  const [open, setOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images?.length);
  };

  const handlePrev = () => {
    setSelectedImageIndex(
      (prevIndex) => (prevIndex - 1 + images?.length) % images?.length
    );
  };

  const renderImages = () => {
    const remainingImagesCount = images?.length - 4;

    if (images?.length === 1) {
      return (
        <Grid container spacing={0.2} justifyContent="center">
          <Grid item xs={12}>
            <Paper
              style={{
                position: "relative",
                cursor: "pointer",
                paddingBottom: "60%",
              }}
              onClick={() => handleImageClick(0)}
            >
              <img
                src={images[0]}
                alt={`1`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      );
    }

    const displayedImages = images.slice(0, 4);
    const firstRowImages = displayedImages.slice(0, 2);
    const secondRowImages = displayedImages.slice(2, 4);

    if (images?.length === 3) {
      return (
        <>
          <Grid container spacing={0.2}>
            {firstRowImages.map((image, index) => (
              <Grid item xs={6} key={index}>
                <Paper
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    paddingBottom: "100%",
                  }}
                  onClick={() => handleImageClick(index)}
                >
                  <img
                    src={image}
                    alt={`${index + 1}`}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={0.2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  position: "relative",
                  cursor: "pointer",
                  paddingBottom: "60%",
                }}
                onClick={() => handleImageClick(2)}
              >
                <img
                  src={images[2]}
                  alt={`3`}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid container spacing={0.2} sx={{ marginBottom: "2px" }}>
          {firstRowImages.map((image, index) => (
            <Grid item xs={6} key={index}>
              <Paper
                style={{
                  position: "relative",
                  cursor: "pointer",
                  paddingBottom: "100%",
                }}
                onClick={() => handleImageClick(index)}
              >
                <img
                  src={image}
                  alt={`${index + 1}`}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={0.2}>
          {secondRowImages.map((image, index) => (
            <Grid item xs={6} key={index}>
              <Paper
                style={{
                  position: "relative",
                  cursor: "pointer",
                  paddingBottom: "100%",
                }}
                onClick={() => handleImageClick(index + 2)}
              >
                <img
                  src={image}
                  alt={`${index + 3}`}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
                {index === 1 && remainingImagesCount > 0 && (
                  <Typography
                    variant="subtitle1"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      background: "rgba(0,0,0,0.7)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: 25,
                        fontFamily: "proxima_nova_rgregular",
                      }}
                    >
                      +{remainingImagesCount}
                    </Typography>
                  </Typography>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <div>
      {renderImages()}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "100%", sm: "70%" },
            background: "#555555",
            width: { xs: "100vw", sm: "50%", lg: "50%" },
            margin: "auto",
            position: { xs: "relative", sm: "absolute" },
            top: { xs: "0px", sm: "50%", lg: "50%" },
            left: { xs: "0px", sm: "50%", lg: "50%" },
            transform: { xs: "", sm: "translate(-50%, -50%)" },
          }}
        >
          <IconButton
            style={{
              position: "absolute",
              top: 0,
              left: 2,
              zIndex: 1000,
              color: "white",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
          <IconButton
            style={{
              position: "absolute",
              top: "50%",
              left: 10,
              transform: "translateY(-50%)",
              zIndex: 1000,
              fontSize: "30px",
              color: "white",
              backgroundColor: "transparent",
              borderStyle: "hidden",
              cursor: "pointer",
            }}
            onClick={handlePrev}
          >
            <IoIosArrowDropleftCircle />
          </IconButton>
          <IconButton
            style={{
              position: "absolute",
              top: "50%",
              right: 10,
              transform: "translateY(-50%)",
              zIndex: 1000,
              fontSize: "30px",
              color: "white",
              backgroundColor: "transparent",
              borderStyle: "hidden",
              cursor: "pointer",
            }}
            onClick={handleNext}
          >
            <IoIosArrowDroprightCircle />
          </IconButton>
          <img
            src={images[selectedImageIndex]}
            alt={`${selectedImageIndex + 1}`}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Box>
      </Modal>
    </div>
  );
}
