import { useState } from "react";
import firebase from "../../../config/fbConfig";
import {
  setAuthModalConfig,
  setWelcomeMsgConfig,
} from "../../../store/actions/actions";
import { connect } from "react-redux";
import "./auth.css";
import Login from "./authFlow/Login";
import OtpInput from "./authFlow/OtpInput";
import Signup from "./authFlow/Signup";

const AVATARS = [
  "https://www.w3schools.com/howto/img_avatar.png",
  "https://www.w3schools.com/howto/img_avatar2.png",
  "https://www.w3schools.com/w3images/avatar2.png",
  "https://www.w3schools.com/w3images/avatar6.png",
  "https://www.w3schools.com/w3images/avatar5.png",
];

function Auth({ setAuthModalConfig, authModalConfig, setWelcomeMsgConfig }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userMsg, setUserMsg] = useState({
    type: "success",
    msg: "Please enter your email and password.",
  });
  const [avatar, setAvatar] = useState(
    AVATARS[Math.floor(Math.random() * AVATARS.length)]
  );

  const handleGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const user = result.user;
        updateUserInDatabase(
          user.uid,
          user.displayName,
          user.email,
          user.phoneNumber,
          user.photoURL
        );
        setUserMsg({
          type: "success",
          msg: "Signed in with Google successfully!",
        });
        setAuthModalConfig({ show: false, type: "Login" });
      })
      .catch((error) => {
        setUserMsg({ type: "danger", msg: error.message });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (authModalConfig.type === "Login") {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          setUserMsg({ type: "success", msg: "Login successful!" });
          setAuthModalConfig({ show: false, type: "Login" });
        })
        .catch((error) => {
          setUserMsg({ type: "danger", msg: error.message });
        });
    } else {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log(user);
          updateUserInDatabase(
            user.uid,
            user.displayName,
            user.email,
            user.phoneNumber,
            user.photoURL
          );
          setUserMsg({ type: "success", msg: "Sign up successful!" });
          setAuthModalConfig({ show: false, type: "Sign Up" });
        })
        .catch((error) => {
          setUserMsg({ type: "danger", msg: error.message });
        });
    }
  };

  const updateUserInDatabase = (uid, name, email, phoneNumber, avatar) => {
    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .set({
        uid,
        name,
        email,
        phone: phoneNumber,
        isAdmin: false,
        avatar,
      })
      .then(() => {
        setAuthModalConfig({ show: false, type: "Login" });
      })
      .catch((error) => {
        console.error("Error updating user in database:", error);
      });
  };

  return (
    <div>
      <div id="re-c"></div>
      {(authModalConfig.type === "Login" && <Login />) ||
        (authModalConfig.type === "OTP" && <OtpInput />) ||
        (authModalConfig.type === "SignUp" && <Signup />)}
    </div>
    // <Modal
    //   show={true}
    //   backdrop="static"
    //   keyboard={false}
    //   style={{
    //     height: "100vh",
    //   }}
    // >
    //   <Modal.Body>
    //     <div className="position-relative row user-select-none modal-body-container d-flex flex-column flex-md-row">
    //       <div className="position-absolute d-flex justify-content-end px-4 px-md-2 py-2 mt-2">
    //         <span
    //           style={{
    //             cursor: "pointer",
    //           }}
    //           onClick={() =>
    //             setAuthModalConfig({
    //               show: false,
    //               type: "Login",
    //             })
    //           }
    //         >
    //           <CloseIcon />
    //         </span>
    //       </div>
    //       <form
    //         className="col-12 col-md-12 py-4 p-0 mx-auto px-2 px-md-0 right"
    //         onSubmit={handleSubmit}
    //       >
    //         <h5
    //           className="mx-1 mx-md-0 px-3 py-1 "
    //           style={{
    //             color: "#758D91",
    //             marginTop: "-7px",
    //           }}
    //         >
    //           {authModalConfig.type === "Login"
    //             ? "Login into your account"
    //             : "Create your Account"}
    //         </h5>
    //         <hr />
    //         <h5
    //           style={{
    //             color: "#758D91",
    //             textAlign: "center",
    //             fontSize: 18,
    //             fontFamily: "proxima_nova_rgregular",
    //           }}
    //         >
    //           Log in or <span style={{ color: "#3CB6D3" }}>register</span> to{" "}
    //           <span style={{ fontWeight: "550" }}>save alerts</span> and
    //           <br />{" "}
    //           <span style={{ fontWeight: "550" }}>favourite rentals</span>
    //         </h5>
    //         <div className="px-3">
    //           <input
    //             required
    //             type="email"
    //             placeholder="Email*"
    //             onChange={(e) => {
    //               setEmail(e.target.value);
    //             }}
    //             style={{
    //               backgroundColor: "white",
    //               textDecoration: "none",
    //               padding: 16,
    //               borderLeft: "4px solid #3CB6D3",
    //               height: "60px",
    //               borderBottomStyle: "hidden",
    //               boxShadow: "0 0.1rem 0.7rem rgba(0,0,0,.05)",
    //             }}
    //           />
    //         </div>
    //         <div className="px-3 mt-2">
    //           <input
    //             required
    //             type="password"
    //             placeholder="Password*"
    //             onChange={(e) => {
    //               setPassword(e.target.value);
    //             }}
    //             style={{
    //               backgroundColor: "white",
    //               textDecoration: "none",
    //               padding: 16,
    //               borderLeft: "4px solid #3CB6D3",
    //               height: "60px",
    //               borderBottomStyle: "hidden",
    //               boxShadow: "0 0.1rem 0.7rem rgba(0,0,0,.05)",
    //             }}
    //           />
    //         </div>
    //         {authModalConfig.type === "Sign Up" && (
    //           <>
    //             <div className="px-3 mt-2 mb-3">
    //               <FormControl fullWidth>
    //                 <InputLabel id="demo-simple-select-label">
    //                   Professional type
    //                 </InputLabel>
    //                 <Select
    //                   labelId="demo-simple-select-label"
    //                   id="demo-simple-select"
    //                   label="Select professional type*"
    //                   style={{
    //                     backgroundColor: "white",
    //                     textDecoration: "none",
    //                     padding: 16,
    //                     height: "60px",
    //                     borderBottomStyle: "hidden",
    //                   }}
    //                 >
    //                   <MenuItem value={10}>Property Owner</MenuItem>
    //                   <MenuItem value={20}>Property Manager</MenuItem>
    //                   <MenuItem value={30}>
    //                     Real Estate Agent or Broker
    //                   </MenuItem>
    //                 </Select>
    //               </FormControl>
    //             </div>
    //             <div className="px-3 mt-2" style={{ display: "flex", gap: 20 }}>
    //               <div>
    //                 <input
    //                   placeholder="First Name*"
    //                   onChange={(e) => {
    //                     setPassword(e.target.value);
    //                   }}
    //                   style={{
    //                     backgroundColor: "white",
    //                     textDecoration: "none",
    //                     padding: 16,
    //                     borderLeft: "4px solid #3CB6D3",
    //                     height: "60px",
    //                     borderBottomStyle: "hidden",
    //                     boxShadow: "0 0.1rem 0.7rem rgba(0,0,0,.05)",
    //                   }}
    //                 />
    //               </div>
    //               <div>
    //                 <input
    //                   placeholder="Last Name*"
    //                   onChange={(e) => {
    //                     setPassword(e.target.value);
    //                   }}
    //                   style={{
    //                     backgroundColor: "white",
    //                     textDecoration: "none",
    //                     padding: 16,
    //                     borderLeft: "4px solid #3CB6D3",
    //                     height: "60px",
    //                     borderBottomStyle: "hidden",
    //                     boxShadow: "0 0.1rem 0.7rem rgba(0,0,0,.05)",
    //                   }}
    //                 />
    //               </div>
    //             </div>
    //             <div className="px-3 mt-2">
    //               <input
    //                 placeholder="Phone Number*"
    //                 onChange={(e) => {
    //                   setPassword(e.target.value);
    //                 }}
    //                 style={{
    //                   backgroundColor: "white",
    //                   textDecoration: "none",
    //                   padding: 16,
    //                   borderLeft: "4px solid #3CB6D3",
    //                   height: "60px",
    //                   borderBottomStyle: "hidden",
    //                   boxShadow: "0 0.1rem 0.7rem rgba(0,0,0,.05)",
    //                 }}
    //               />
    //             </div>
    //           </>
    //         )}

    //         {userMsg && (
    //           <h6
    //             className={`px-3 text-${userMsg.type}`}
    //             style={{
    //               fontSize: 12,
    //               fontFamily: "proxima_nova_rgregular",
    //               marginTop: "-5px",
    //             }}
    //           >
    //             {userMsg.msg}
    //           </h6>
    //         )}
    //         <div
    //           style={{
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //             gap: 6,
    //           }}
    //         >
    //           <p
    //             style={{
    //               fontSize: 17,
    //               marginTop: 14,
    //               color: "#758d91",
    //               fontFamily: "sans-serif",
    //             }}
    //           >
    //             Log in with{" "}
    //           </p>
    //           <button
    //             style={{
    //               backgroundColor: "white",
    //               border: "1px gray solid",
    //               borderRadius: 4,
    //               padding: 3,
    //               display: "flex",
    //               alignItems: "center",
    //               cursor: "pointer",
    //             }}
    //             onClick={handleGoogleSignIn}
    //           >
    //             <img
    //               src={googleLogo}
    //               alt="g-logo"
    //               style={{ width: 18, marginTop: 0 }}
    //             />
    //             <span
    //               style={{
    //                 color: "#758d91",
    //                 fontFamily: "sans-serif",
    //                 fontWeight: 500,
    //               }}
    //             >
    //               oogle
    //             </span>
    //           </button>
    //         </div>

    //         <div className="row px-2 mt-2 mb-0">
    //           <div className="px-3 col-6 mb-0">
    //             <button
    //               style={{
    //                 border: "none",
    //                 backgroundColor: "transparent",
    //                 color: "#3CB6D3",
    //                 fontFamily: "proxima_nova_rgregular",
    //                 fontSize: 18,
    //                 fontWeight: "normal",
    //                 marginTop: 4,
    //               }}
    //               onClick={() =>
    //                 setAuthModalConfig({
    //                   show: true,
    //                   type:
    //                     authModalConfig.type === "Login" ? "Sign Up" : "Login",
    //                 })
    //               }
    //               type="button"
    //             >
    //               {authModalConfig.type === "Login"
    //                 ? "Create Account"
    //                 : "Log in"}
    //             </button>
    //           </div>

    //           <div className="px-3 col-6">
    //             <CustomButton
    //               label={
    //                 authModalConfig.type === "Login"
    //                   ? "Log in "
    //                   : "Create Account"
    //               }
    //               isIcon
    //               type="submit"
    //               bg="#3cb6d3"
    //               fontColor="#ffffff"
    //               isBg
    //               isRounded
    //             />
    //           </div>
    //         </div>
    //       </form>
    //     </div>
    //   </Modal.Body>
    // </Modal>
  );
}

const mapStateToProps = (state) => ({
  authModalConfig: state.user.authModalConfig,
});

const mapDispatchToProps = (dispatch) => ({
  setAuthModalConfig: (val) => dispatch(setAuthModalConfig(val)),
  setWelcomeMsgConfig: (obj) => dispatch(setWelcomeMsgConfig(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
