import { connect, useSelector } from "react-redux";
import Loader from "../../../common/loader/Loader";
import AddIcon from "@mui/icons-material/Add";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PlacesAutocomplete from "react-places-autocomplete";
import Avatar from "@mui/material/Avatar";
import firebase from "../../../../config/fbConfig";
import SearchIcon from "@material-ui/icons/Search";
import {
  setUploadingLoader,
  setDoneLoader,
} from "../../../../store/actions/actions";
import "./community-post.css";
import { useEffect, useRef, useState } from "react";
import { CustomButton } from "../../../common/custom-button/custom-button";
import UploadImages from "../../../common/upload-images/upload-images";
import { scrollToTop } from "../../../../utils/common-functions";
import { CommunityPostCards } from "../community-post-cards/community-post-cards";
import ModalWrapper from "../../../common/modal-wrapper/modal-wrapper";
import Messages from "../chat-list/chat-list";
import Conversation from "../conversation/conversation";
import LanguageIcon from "@mui/icons-material/Language";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Grid from "@mui/material/Grid";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import { BsFillInfoSquareFill } from "react-icons/bs";
import { Box, Divider } from "@mui/material";
import { flatGroups } from "../../../../utils/data";
import photoVideo from "../../../../assets/photo-video.png";
import { setAuthModalConfig } from "../../../../store/actions/actions";
import CommunityHeader from "./CommunityHeader";
import { useDispatch } from "react-redux";
import { setAllPosts } from "../../../../store/reducers/postsReducer";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Map from "../../../common/google-map/google-map";
import Footer from "../../../common/footer/footer";

const CommunityMain = ({
  user,
  setUploadingLoader,
  setDoneLoader,
  showChatList,
  showChatBox,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [address, setAddress] = useState("");
  const [images, setImages] = useState([]);
  const [para, setPara] = useState("");
  const addPostRef = useRef();
  const divRef = useRef();
  const [showFilters, setFilters] = useState(false);

  const isAllUploadedImages = () => images.every((img) => img.url.length > 0);

  const uploadPost = async () => {
    let splitted_data = address.split(",");
    let len = splitted_data.length;

    const locality = len >= 4 ? splitted_data[len - 4].trim() : "";
    const city = len >= 4 ? splitted_data[len - 3].trim() : "";

    if (locality === "" || city === "") {
      alert("Please provide both locality and city.");
      return;
    }

    setUploadingLoader(true);
    addPostDoc(locality, city);
    handleClose();
  };

  const addPostDoc = (locality, city) => {
    firebase
      .firestore()
      .collection("posts")
      .add({
        images: images.sort((a, b) => a.order - b.order).map((img) => img.url),
        desc: para,
        likes: [],
        messages: [],
        timestamp: new Date(),
        uid: user.uid,
        name: user.displayName,
        locality,
        city,
      })
      .then(() => {
        setUploadingLoader(false);
        setDoneLoader(true);
        cleanUpEverything();

        setTimeout(() => {
          setDoneLoader(false);
        }, 3500);
      });
  };

  const cleanUpEverything = () => {
    setImages([]);
    setPara("");
    setAddress("");
  };

  const handleSelect = (value) => {
    setAddress(value);
  };

  const handleAddIconClick = () => {
    if (user) {
      handleOpen();
    } else {
      dispatch(setAuthModalConfig({ show: true, type: "Login" }));
    }
  };

  return (
    <div className="position-relative" ref={divRef}>
      <div className="container">
        {/* upload post div */}
        {!(showChatBox || showChatList) && (
          <div
            ref={addPostRef}
            className={`position-fixed w-100 h-${showFilters ? "50" : "0"}`}
            style={{
              bottom: 10,
              right: 10,
              zIndex: 10,
              pointerEvents: "none",
            }}
          >
            <div className="container h-100 d-flex flex-column">
              <div
                className={`ms-auto col-11 col-md-4 rounded ${
                  showFilters ? " border" : ""
                }`}
                style={{
                  pointerEvents: "auto",
                  backgroundColor: showFilters ? "#f3f4f5" : "transparent",
                }}
              ></div>
              {/* <div
                onClick={handleAddIconClick}
                className="rounded-circle text-white c-pointer d-flex align-items-center justify-content-center b-shadow border ms-auto"
                style={{
                  background: "#3cd3a3",
                  height: 50,
                  width: 50,
                  pointerEvents: "auto",
                  marginTop: "auto",
                }}
              >
                <AddIcon
                  style={{
                    fontSize: 44,
                  }}
                />
              </div> */}
            </div>
          </div>
        )}

        {user ? (
          <>
            <ModalWrapper open={open} handleClose={handleClose}>
              <div className="bg-white rounded row m-0 p-2 py-3">
                <div>
                  <div className="d-flex pb-2 align-items-center">
                    <Avatar alt={user.name} src={user?.avatar} />
                    <div className="mx-2 fc-1c position-relative col-10">
                      <p className="m-0">{user.name}</p>

                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="places-search-bar post-input-place transition-3s position-relative justify-content-start p-0 d-flex flex-column align-items-center">
                            <div className="d-flex w-100 align-items-center">
                              <span className="c-pointer">
                                <SearchIcon
                                  style={{
                                    color: "#3cb6d3",
                                    margin: "0 7px",
                                    fontSize: 16,
                                  }}
                                />
                              </span>
                              <input
                                {...getInputProps({
                                  placeholder:
                                    "Search City, Neighbourhood or Address",
                                })}
                                className="m-0"
                                style={{
                                  border: "none",
                                  height: 12,
                                  fontSize: 14,
                                  fontFamily: "proxima_nova_rgregular",
                                }}
                              />
                            </div>
                            <div
                              className="position-absolute bg-white left-0 right-0"
                              style={{
                                border: `${
                                  suggestions.length ? "1" : "0"
                                }px solid rgba(0,0,0,.2)`,
                                backdropFilter: "blur(10px)",
                                zIndex: 2,
                                top: 25,
                                minWidth: "100%",
                              }}
                            >
                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "rgba(0,0,0,.05)"
                                    : "white",
                                  color: "black",
                                };

                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description && (
                                      <div className="d-flex align-items-center p-2 py-1 text-muted">
                                        <LocationOnIcon />
                                        <p className="result-item w-100 m-0 mx-2">
                                          {suggestion.description}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>

                  <div className="my-2 pb-2">
                    <textarea
                      className="rounded filter-sidebar border p-2 post-input transition-3s"
                      placeholder="Create a public post..."
                      style={{
                        fontFamily: "proxima_nova_rgregular",
                        border: "1px solid #00000040",
                        resize: "none",
                        maxHeight: 200,
                        minHeight: "100px",
                      }}
                      value={para}
                      onChange={(e) => {
                        const textarea = e.target;
                        textarea.style.height = "auto";
                        textarea.style.height = textarea.scrollHeight + "px";
                        setPara(e.target.value);
                      }}
                    ></textarea>
                  </div>

                  <div
                    className="col-12 border my-2 py-2 px-2 position-relative community-post-img-inp-div"
                    style={{
                      height: 220,
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    {/* upload images */}
                    <UploadImages
                      images={images}
                      setImages={setImages}
                      isAllUploaded={isAllUploadedImages}
                      isCommunity
                    />
                  </div>

                  <div className="pt-2">
                    <CustomButton
                      label="post"
                      onClickHandler={() => {
                        uploadPost();
                      }}
                      fontColor="#3cd3a3"
                    />
                  </div>
                </div>
              </div>
            </ModalWrapper>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const CommunityPost = ({
  user,
  setDoneLoader,
  setUploadingLoader,
  communityPosts,
  showChatList,
  showChatBox,
}) => {
  useEffect(() => {
    scrollToTop();
  }, []);

  const [aboutShowMore, setAboutShowMore] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [hoveredGroup, setHoveredGroup] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [address, setAddress] = useState("");
  const [images, setImages] = useState([]);
  const [para, setPara] = useState("");

  const isAllUploadedImages = () => images.every((img) => img.url.length > 0);

  const addPostDoc = (locality, city) => {
    firebase
      .firestore()
      .collection("posts")
      .add({
        images: images.sort((a, b) => a.order - b.order).map((img) => img.url),
        desc: para,
        likes: [],
        messages: [],
        timestamp: new Date(),
        uid: user.uid,
        name: user.displayName,
        locality,
        city,
      })
      .then(() => {
        if (!locality) {
          alert("Locality!!");
        }
        setUploadingLoader(false);
        setDoneLoader(true);
        cleanUpEverything();

        setTimeout(() => {
          setDoneLoader(false);
        }, 3500);
      });
  };

  const uploadPost = () => {
    handleClose();
    setUploadingLoader(true);

    let splitted_data = address.split(",");
    let len = splitted_data.length;

    const locality = len >= 4 ? splitted_data[len - 4].trim() : "";
    const city = len >= 4 ? splitted_data[len - 3].trim() : "";

    console.log("Locality:", locality);
    console.log("City:", city);

    if (locality === "" || city === "") {
      alert("Please provide both locality and city.");
      return;
    }

    setUploadingLoader(true);
    addPostDoc(locality, city);
  };

  const handleSelect = (value) => {
    setAddress(value);
  };

  const cleanUpEverything = () => {
    setImages([]);
    setPara("");
    setAddress("");
  };

  const dispatch = useDispatch();
  const [filterTemp, setFilterTemp] = useState("");
  const [filterLocality, setFilterLocality] = useState("");
  const [showFilters, setFilters] = useState(false);
  // const [allPosts, setAllPosts] = useState([]);

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("posts")
      .where(
        "locality",
        `${filterLocality.length > 0 ? "==" : "!="}`,
        filterLocality
      )
      .onSnapshot((docs) => {
        if (docs.size > 0) {
          const posts = docs.docs.map((post) => ({
            id: post.id,
            ...post.data(),
          }));
          dispatch(setAllPosts(posts));
        } else {
          dispatch(setAllPosts([]));
        }
      });

    return () => unsub();
  }, [dispatch, filterLocality]);

  const allPosts = useSelector((state) => state.posts?.posts);

  const handleFilterSelect = (address) => {
    setFilterTemp(address);

    if (address.length !== 0) {
      let splitted_data = address.split(",");
      let len = splitted_data.length;

      const locality = len >= 4 ? splitted_data[len - 4].trim() : "";
      if (locality === "") {
        setFilterLocality("");
        return;
      }

      setFilterLocality(locality);
      setFilters(false);
    }
  };

  return (
    <>
      <CommunityHeader />
      <div style={{ backgroundColor: "#00000010" }}>
        {user ? (
          <>
            <ModalWrapper open={open} handleClose={handleClose}>
              <div
                className="bg-white rounded row m-0 p-2 py-3"
                style={{ maxHeight: "800px" }}
              >
                <div>
                  <div className="d-flex pb-2 align-items-center">
                    <Avatar alt={user.name} src={user?.avatar} />
                    <div className="mx-2 fc-1c position-relative col-10">
                      <p className="m-0">{user.name}</p>

                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="places-search-bar post-input-place transition-3s position-relative justify-content-start p-0 d-flex flex-column align-items-center">
                            <div className="d-flex w-100 align-items-center">
                              <span className="c-pointer">
                                <SearchIcon
                                  style={{
                                    color: "#3cb6d3",
                                    margin: "0 7px",
                                    fontSize: 16,
                                  }}
                                />
                              </span>
                              <input
                                {...getInputProps({
                                  placeholder:
                                    "Search City, Neighbourhood or Address",
                                })}
                                className="m-0"
                                style={{
                                  border: "none",
                                  height: 12,
                                  fontSize: 14,
                                  fontFamily: "proxima_nova_rgregular",
                                }}
                              />
                            </div>
                            <div
                              className="position-absolute bg-white left-0 right-0"
                              style={{
                                border: `${
                                  suggestions.length ? "1" : "0"
                                }px solid rgba(0,0,0,.2)`,
                                backdropFilter: "blur(10px)",
                                zIndex: 2,
                                top: 25,
                                minWidth: "100%",
                              }}
                            >
                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "rgba(0,0,0,.05)"
                                    : "white",
                                  color: "black",
                                };

                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description && (
                                      <div className="d-flex align-items-center p-2 py-1 text-muted">
                                        <LocationOnIcon />
                                        <p className="result-item w-100 m-0 mx-2">
                                          {suggestion.description}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>

                  <div className="my-2 pb-2">
                    <textarea
                      className="rounded filter-sidebar border p-2 post-input transition-3s"
                      placeholder="Create a public post..."
                      style={{
                        fontFamily: "proxima_nova_rgregular",
                        border: "1px solid #00000040",
                        resize: "none",
                        maxHeight: 200,
                        minHeight: "100px",
                      }}
                      value={para}
                      onChange={(e) => {
                        const textarea = e.target;
                        textarea.style.height = "auto";
                        textarea.style.height = textarea.scrollHeight + "px";
                        setPara(e.target.value);
                      }}
                    ></textarea>
                  </div>

                  <div
                    className="col-12 border my-2 py-2 px-2 position-relative community-post-img-inp-div"
                    style={{
                      height: 220,
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    {/* upload images */}
                    <UploadImages
                      images={images}
                      setImages={setImages}
                      isAllUploaded={isAllUploadedImages}
                      isCommunity
                    />
                  </div>

                  <div className="pt-2">
                    <CustomButton
                      label="post"
                      onClickHandler={() => {
                        uploadPost();
                      }}
                      fontColor="#3cd3a3"
                    />
                  </div>
                </div>
              </div>
            </ModalWrapper>
          </>
        ) : (
          <></>
        )}
        <div
          className=""
          style={{ paddingTop: 70, paddingLeft: 0, paddingRight: 0 }}
        >
          <Grid
            container
            style={{
              display: "flex",
              position: "relative",
              zIndex: 1,
            }}
            spacing={2}
            direction="row"
          >
            {showChatList && <Messages />}
            {showChatBox && <Conversation />}
            {/* //left column */}
            <Grid item xs={12} sm={3} md={7}>
              <div
                className=" bg-white"
                style={{
                  width: "100%",
                  height: "100vh",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                }}
              >
                <Map
                  cityPlaceId="whitefield"
                  localityPlaceId="yqnMZNXH2IRG8TVxw694"
                />
              </div>
            </Grid>
            {/* //right column */}
            <Grid item xs={12} sm={3} md={5}>
              <div
                style={{
                  height: "100vh",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  marginTop: "-18px",
                }}
              >
                <div className="text-light">
                  {user && allPosts ? (
                    <>
                      <Box sx={{ display: { xs: "none", sm: "block" } }}>
                        <div
                          style={{
                            backgroundColor: "white",
                            padding: 16,
                            borderRadius: 8,
                            marginTop: 26,
                          }}
                          onClick={() => handleOpen()}
                        >
                          <div style={{ display: "flex", gap: 10 }}>
                            <Avatar alt={user.displayName} src="none" />
                            <textarea
                              placeholder="Write something..."
                              style={{
                                border: "none",
                                backgroundColor: "#F0F2F5",
                                borderRadius: 80,
                                resize: "none",
                                paddingTop: "8px",
                                paddingLeft: "10px",
                                height: 40,
                                fontFamily: "proxima_nova_rgregular",
                              }}
                            ></textarea>
                          </div>
                          <Divider style={{ color: "gray", marginTop: 18 }} />
                          <div
                            style={{
                              border: "none",
                              backgroundColor: "#F0F2F5",
                              borderRadius: 10,
                              resize: "none",
                              paddingLeft: "10px",
                              height: 40,
                              marginTop: 16,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={photoVideo}
                              alt="logo"
                              style={{ width: 20, objectFit: "contain" }}
                            />
                            <p
                              style={{
                                marginTop: 14,
                                marginLeft: 8,
                                color: "gray",
                                fontFamily: "proxima_nova_rgregular",
                              }}
                            >
                              Photo/Video
                            </p>
                          </div>
                        </div>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}

                  <CommunityMain
                    user={user}
                    setUploadingLoader={setUploadingLoader}
                    setDoneLoader={setDoneLoader}
                    communityPosts={communityPosts}
                    showChatList={showChatList}
                    showChatBox={showChatBox}
                  />
                  <div className="">
                    <div className="my-3">
                      {allPosts === null ? (
                        <Loader />
                      ) : allPosts ? (
                        allPosts
                          .sort(
                            (a, b) =>
                              new Date(b.timestamp.seconds) -
                              new Date(a.timestamp.seconds)
                          )
                          .map((post) => (
                            <CommunityPostCards
                              key={post.id}
                              post={post}
                              user={user}
                            />
                          ))
                      ) : (
                        <div
                          className="pt-2 text-uppercase text-center fc-1c"
                          style={{
                            fontFamily: "proxima_nova_rgregular",
                            fontSize: 14,
                          }}
                        >
                          <Card
                            // sx={{ m: 2, height: { xs: "40vh", sm: "70vh" } }}
                            sx={{ mb: 2 }}
                          >
                            <CardHeader
                              avatar={
                                <Skeleton
                                  animation="wave"
                                  variant="circular"
                                  width={50}
                                  height={50}
                                />
                              }
                              title={
                                <Skeleton
                                  animation="wave"
                                  height={15}
                                  width="80%"
                                  style={{ marginBottom: 6 }}
                                />
                              }
                              subheader={
                                <Skeleton
                                  animation="wave"
                                  height={15}
                                  width="40%"
                                />
                              }
                            />

                            <Skeleton
                              sx={{ height: { xs: "25vh", sm: "50vh" } }}
                              animation="wave"
                              variant="rectangular"
                            />

                            <CardContent>
                              <>
                                <Skeleton
                                  animation="wave"
                                  height={15}
                                  style={{ marginBottom: 6 }}
                                />
                                <Skeleton
                                  animation="wave"
                                  height={15}
                                  width="80%"
                                />
                              </>
                            </CardContent>
                          </Card>
                          <Card
                            // sx={{ m: 2, height: { xs: "40vh", sm: "70vh" } }}
                            sx={{ mb: 2 }}
                          >
                            <CardHeader
                              avatar={
                                <Skeleton
                                  animation="wave"
                                  variant="circular"
                                  width={50}
                                  height={50}
                                />
                              }
                              title={
                                <Skeleton
                                  animation="wave"
                                  height={15}
                                  width="80%"
                                  style={{ marginBottom: 6 }}
                                />
                              }
                              subheader={
                                <Skeleton
                                  animation="wave"
                                  height={15}
                                  width="40%"
                                />
                              }
                            />

                            <Skeleton
                              sx={{ height: { xs: "25vh", sm: "50vh" } }}
                              animation="wave"
                              variant="rectangular"
                            />

                            <CardContent>
                              <>
                                <Skeleton
                                  animation="wave"
                                  height={15}
                                  style={{ marginBottom: 6 }}
                                />
                                <Skeleton
                                  animation="wave"
                                  height={15}
                                  width="80%"
                                />
                              </>
                            </CardContent>
                          </Card>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  showChatList: state.community.showChatList,
  showChatBox: state.community.showChatBox,
});

const mapDispatchToProps = (dispatch) => ({
  setUploadingLoader: (val) => dispatch(setUploadingLoader(val)),
  setDoneLoader: (val) => dispatch(setDoneLoader(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CommunityPost);
