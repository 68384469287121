import { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Loader from "../../../common/loader/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import StarIcon from "@material-ui/icons/Star";
import firebase from "../../../../config/fbConfig";
import peopleIcon from "../../../../assets/people.png";
import Moment from "react-moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import {
  setUploadingLoader,
  setDocId,
} from "../../../../store/actions/actions";
import MyLocationIcon from "@mui/icons-material/MyLocation";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./apartment-details-approve.css";
import SwiperCore, { Navigation, Pagination } from "swiper";
import Map from "../../../common/google-map/google-map";
import { CustomButton } from "../../../common/custom-button/custom-button";
import { useMediaQuery } from "react-responsive";
import { FeaturesDisplay } from "../../../details/components/features-display/features-display";
import swal from "sweetalert";

SwiperCore.use([Navigation, Pagination]);

const AboutChips = ({ name, value }) => (
  <div className="d-flex my-2">
    <div className="col-6 fc-5c" style={{}}>
      {name}
    </div>
    <div
      className="col-6 fc-75"
      style={{
        fontFamily: "proxima_nova_rgregular",
      }}
    >
      {value ? value : "No Info"}
    </div>
  </div>
);

const ApartmentDetailsApprove = ({
  apartment,
  city,
  locality,
  doc_id,
  setUploadingLoader,
  setDocId,
}) => {
  const [readMore, setReadMore] = useState(true);
  const [featureType, setFeatureType] = useState("unitFeatures");
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const [currentDisplay, setCurrentDisplay] = useState("IMG");

  const approveDoc = () => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        // do yes work

        setUploadingLoader(true);

        const ref = firebase
          .firestore()
          .collection("city")
          .doc(city)
          .collection("locality")
          .doc(locality);

        // update catalogue data
        ref
          .collection("catalogue")
          .doc(doc_id.toString().trim())
          .get()
          .then((d) => {
            d.ref
              .update({
                approved: true,
              })
              .then(() => {
                // update estate doc
                ref
                  .collection("estate")
                  .doc(doc_id)
                  .get()
                  .then((dd) => {
                    dd.ref
                      .update({
                        approved: true,
                      })
                      .then(() => {
                        // delete pending doc
                        ref
                          .collection("pending")
                          .doc(doc_id)
                          .get()
                          .then((ddd) => {
                            ddd.ref.delete();
                          })
                          .then(() => {
                            // show successful msg
                            setUploadingLoader(false);
                            setDocId(null);
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });

                            swal("Property successfully Approved!", {
                              icon: "success",
                              closeOnClickOutside: true,
                              button: false,
                              timer: 2000,
                            });
                          });
                      });
                  });
              });
          });
      }
    });
  };

  const rejectProperty = () => {
    swal({
      text: "Enter remark before rejecting the property.",
      content: "input",
      button: {
        text: "Reject",
        closeModal: false,
      },
    }).then((remark) => {
      console.log(remark);
      if (!remark) return;

      const ref = firebase
        .firestore()
        .collection("city")
        .doc(city)
        .collection("locality")
        .doc(locality);

      // del catalogue doc
      ref.collection("catalogue").doc(doc_id.toString().trim()).delete();

      // update estate
      ref.collection("estate").doc(doc_id.toString().trim()).update({ remark });

      ref
        .collection("pending")
        .doc(doc_id.toString().trim())
        .delete()
        .then(() => {
          setDocId(null);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });

          swal("Property successfully Rejected with remark!", {
            icon: "success",
            closeOnClickOutside: true,
            button: false,
            timer: 2000,
          });
        });
    });
  };

  const deleteProperty = () => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        // do yes work

        setUploadingLoader(true);

        const ref = firebase
          .firestore()
          .collection("city")
          .doc(city)
          .collection("locality")
          .doc(locality);

        // del catalogue doc
        ref.collection("catalogue").doc(doc_id.toString().trim()).delete();

        ref.collection("estate").doc(doc_id.toString().trim()).delete();

        ref
          .collection("pending")
          .doc(doc_id.toString().trim())
          .delete()
          .then(() => {
            setUploadingLoader(false);
            setDocId(null);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });

            swal("Property successfully Approved!", {
              icon: "success",
              closeOnClickOutside: true,
              button: false,
              timer: 2000,
            });
          });
      }
    });
  };

  return (
    <div
      className="apartment-details border mt-4"
      style={{ backgroundColor: "#f3f4f5" }}
    >
      {apartment && apartment !== undefined ? (
        <div className="h-100 w-100">
          {/* row two */}
          {!isMobileScreen && (
            <div className="bg-white m-0 pt-4">
              <div className="col-12">
                <div className="row pb-4 d-flex align-items-center m-0">
                  <div className="d-flex flex-column col-12 col-md-6">
                    <div className="d-flex align-items-center">
                      <h3 className="fc-cprimary m-0">
                        ₹ {apartment.cost.toLocaleString()}
                      </h3>
                      <span className="mx-2 text-muted">•</span>
                      <h5 className="fc-1c m-0">{apartment.propertyType}</h5>
                      <span className="mx-2 text-muted">•</span>
                      <span className="text-muted mx-1">
                        <AccessTimeIcon />
                      </span>
                      <h6 className="fc-1c m-0 mt-1 text-muted">
                        <Moment fromNow>{apartment.postedOn.toDate()}</Moment>
                      </h6>
                    </div>
                    <div className="mt-3">
                      <h5 className="m-0 text-muted fc-1c">
                        {apartment.societyName} - {apartment.localeName},{" "}
                        {apartment.city}
                      </h5>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-3 my-md-0">
                    <div className="row w-100">
                      <div className="col-4">
                        <img
                          src={peopleIcon}
                          width="24"
                          height="24"
                          alt="logo"
                        />
                        <span className="pt-2 mx-2 fc-1c text-muted">
                          {apartment.bedroom} Bed
                        </span>
                      </div>
                      <div className="col-4">
                        <img
                          src={peopleIcon}
                          width="24"
                          height="24"
                          alt="logo"
                        />
                        <span className="pt-2 mx-2 fc-1c text-muted">
                          {apartment.bathroom} Bath
                        </span>
                      </div>
                      <div className="col-4">
                        <img
                          src={peopleIcon}
                          width="24"
                          height="24"
                          alt="logo"
                        />
                        <span className="pt-2 mx-2 fc-1c text-muted">
                          {apartment.area} FT²
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row p-2">
            <div
              className="col-6 col-md-2"
              style={{
                marginLeft: "auto",
              }}
            >
              <CustomButton
                label="Back to top"
                onClickHandler={() => {
                  setDocId(null);
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
                isBg
                bg="#3cd3a3"
              />{" "}
            </div>
          </div>

          <div>
            <div className="row py-0 pt-4 d-flex justify-content-center flex-column flex-md-row">
              <div className="col-12 col-md-10 m-0 p-0">
                <div
                  style={{
                    height: 350,
                  }}
                  className="bg-white border"
                >
                  {currentDisplay === "IMG" && (
                    <Swiper
                      navigation={true}
                      pagination={{
                        type: "fraction",
                      }}
                      style={{
                        border: "none",
                        height: "100%",
                      }}
                      grabCursor={true}
                      slidesPerView={1}
                      className="mySwiper p-0 bg-white"
                    >
                      {apartment.images.map((img) => (
                        <SwiperSlide key={img}>
                          <div className="swiper-slide-img">
                            <img
                              width="100%"
                              height="100%"
                              src={img}
                              className="img-fluid"
                              alt="apartment"
                              loading="lazy"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}

                  {currentDisplay === "VID" && apartment.videos.length > 0 && (
                    <video
                      className="p-0"
                      src={apartment.videos[0]}
                      alt="Selected file"
                      controls
                      width="100%"
                      height="100%"
                    ></video>
                  )}

                  {currentDisplay === "MAP" && (
                    <div className="col-12 bg-white w-100 h-100">
                      <Map
                        isMarkerShown
                        latlng={{
                          fixed: true,
                          lat: apartment.latLng.latitude,
                          lng: apartment.latLng.longitude,
                        }}
                      />
                    </div>
                  )}
                </div>
                {apartment.videos.length === 0 && !apartment.latLng ? (
                  <></>
                ) : (
                  <div className="col-12 bg-white m-0">
                    <div className="row m-0">
                      <div
                        onClick={() => setCurrentDisplay("IMG")}
                        className={`col d-flex c-pointer align-items-center py-3 justify-content-center${currentDisplay === "IMG"
                            ? " fc-cprimary"
                            : " fc-1c-muted"
                          }`}
                        style={{
                          border: "1px solid #00000020",
                        }}
                      >
                        <CameraAltIcon />
                        {!isMobileScreen && (
                          <span className="mx-2">Images</span>
                        )}
                      </div>
                      {apartment.videos.length !== 0 && (
                        <div
                          onClick={() => setCurrentDisplay("VID")}
                          className={`col d-flex c-pointer align-items-center py-3 justify-content-center${currentDisplay === "VID"
                              ? " fc-cprimary"
                              : " fc-1c-muted"
                            }`}
                          style={{
                            border: "1px solid #00000020",
                          }}
                        >
                          <OndemandVideoIcon />
                          {!isMobileScreen && (
                            <span className="mx-2">Video</span>
                          )}
                        </div>
                      )}
                      {apartment.latLng && (
                        <div
                          onClick={() => setCurrentDisplay("MAP")}
                          className={`col d-flex c-pointer align-items-center py-3 justify-content-center${currentDisplay === "MAP"
                              ? " fc-cprimary"
                              : " fc-1c-muted"
                            }`}
                          style={{
                            border: "1px solid #00000020",
                          }}
                        >
                          <MyLocationIcon />

                          {!isMobileScreen && (
                            <span className="mx-2">Location</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* only for mobile view */}
                {isMobileScreen && (
                  <div className="bg-white m-0 row">
                    <div className="border-bottom pb-3">
                      <h1 className="fc-cprimary m-0 mt-4 mb-2">
                        ₹{apartment.cost.toLocaleString()}
                      </h1>
                      <div className="d-flex align-items-center">
                        <h5 className="fc-1c m-0">{apartment.propertyType}</h5>
                        <span className="mx-2 text-muted">•</span>
                        <span className="text-muted mx-1">
                          <AccessTimeIcon />
                        </span>
                        <h6 className="fc-1c m-0 mt-1 text-muted">
                          <Moment fromNow>{apartment.postedOn.toDate()}</Moment>
                        </h6>
                      </div>
                      <div className="mt-3">
                        <h5 className="m-0 text-muted fc-1c">
                          {apartment.societyName} - {apartment.localeName},{" "}
                          {apartment.city}
                        </h5>
                      </div>
                    </div>

                    <div className="mt-4">
                      <div className="row">
                        <div className="col-6">
                          <img
                            src={peopleIcon}
                            width="24"
                            height="24"
                            alt="logo"
                          />
                          <span className="pt-2 mx-2 fc-1c text-muted">
                            {apartment.bedroom} Bed
                          </span>
                        </div>
                        <div className="col-6">
                          <img
                            src={peopleIcon}
                            width="24"
                            height="24"
                            alt="logo"
                          />
                          <span className="pt-2 mx-2 fc-1c text-muted">
                            {apartment.bathroom} Bath
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <img
                            src={peopleIcon}
                            width="24"
                            height="24"
                            alt="logo"
                          />
                          <span className="pt-2 mx-2 fc-1c text-muted">
                            {apartment.area} FT²
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row m-0 p-2">
                  <div className="col-12 d-flex flex-column my-3">
                    {!isMobileScreen && (
                      <>
                        {" "}
                        <div className="d-flex align-items-center">
                          <h4
                            className="m-0"
                            style={{
                              color: "#3cb6d3",
                            }}
                          >
                            Rs. {apartment.cost}
                          </h4>
                          <span className="mx-2 text-muted">•</span>
                          <h6
                            className="m-0 my-2"
                            style={{
                              color: "#1c5663",
                            }}
                          >
                            {apartment.propertyType}
                          </h6>
                        </div>
                        <h5 className="text-muted m-0 my-2">
                          {apartment.address}
                        </h5>{" "}
                      </>
                    )}
                  </div>
                  <div className="col-12 d-flex flex-column align-items-start">
                    <p
                      className={`fc-1c ${readMore ? "trunc-nline" : ""
                        } apartment-desc`}
                      style={{
                        fontSize: 15,
                        lineHeight: 1.6,
                        "--lines": 10,
                      }}
                      // style={{
                      // }}
                      // className="fc-75"
                      dangerouslySetInnerHTML={{ __html: apartment.desc }}
                    />

                    {apartment.desc.length > 200 && (
                      <button
                        onClick={() => setReadMore((prev) => !prev)}
                        className="tag align-self-start p-2 px-3 bg-white"
                        style={{
                          borderRadius: "20px",
                          color: "#3cb6d3",
                          fontWeight: 500,
                          fontSize: 12,
                          border: "1px solid #3cb6d3",
                        }}
                      >
                        Read {readMore ? "More" : "Less"}
                      </button>
                    )}
                  </div>
                  <div className="col-12 d-flex flex-column align-items-start mt-4 pb-3 px-0 mx-0">
                    <div className="row w-100">
                      <h4 className="m-0 fc-1c pb-3">Features & Amenities</h4>

                      <div className="row col-12 p-0 m-0">
                        {isMobileScreen ? (
                          <FeaturesDisplay
                            options={[
                              { key: "unitFeatures", name: "Unit Features" },
                              {
                                key: "societyFeatures",
                                name: "Society Features",
                              },
                            ]}
                            features={apartment.features}
                          />
                        ) : (
                          <div className="row m-0">
                            <div
                              className="col-12 mb-4 mb-md-0 col-md-5 p-0 pt-3 d-flex flex-row flex-md-column flex-gap flex-wrap"
                              style={{
                                borderRight: isMobileScreen
                                  ? "none"
                                  : "1px solid #00000020",
                                borderBottom: !isMobileScreen
                                  ? "none"
                                  : "1px solid #00000020",
                              }}
                            >
                              <p
                                className={`user-select-none c-pointer fc-1c px-4 py-3 features-chip${featureType === "unitFeatures"
                                    ? " fc-1c chip-selected"
                                    : ""
                                  }`}
                                onClick={(e) => {
                                  setFeatureType("unitFeatures");
                                }}
                              >
                                <img
                                  src={peopleIcon}
                                  width="20"
                                  height="20"
                                  alt="logo"
                                />
                                <span
                                  className="pt-2 mx-2"
                                  style={
                                    featureType === "unitFeatures"
                                      ? {}
                                      : {
                                        fontFamily: "proxima_nova_rgregular",
                                      }
                                  }
                                >
                                  Unit Features (
                                  {apartment.features["unitFeatures"].length})
                                </span>
                              </p>
                              <p
                                className={`user-select-none c-pointer fc-1c px-4 py-3 features-chip${featureType === "societyFeatures"
                                    ? " fc-1c chip-selected"
                                    : ""
                                  }`}
                                onClick={(e) => {
                                  setFeatureType("societyFeatures");
                                }}
                              >
                                <img
                                  src={peopleIcon}
                                  width="20"
                                  height="20"
                                  alt="logo"
                                />
                                <span
                                  className="pt-2 mx-2"
                                  style={
                                    featureType === "societyFeatures"
                                      ? {}
                                      : {
                                        fontFamily: "proxima_nova_rgregular",
                                      }
                                  }
                                >
                                  Society Features (
                                  {apartment.features["societyFeatures"].length}
                                  )
                                </span>
                              </p>
                            </div>
                            <div
                              className="col-12 col-md-7 pt-3 m-0"
                              style={{
                                maxHeight: 220,
                                overflowY: "auto",
                                overflowX: "hidden",
                              }}
                            >
                              {apartment.features[featureType].length === 0 && (
                                <div className="d-flex align-items-center justify-content-center text-muted w-100">
                                  <p> No features </p>
                                </div>
                              )}
                              <div className="row w-100">
                                {apartment.features[featureType].map((item) => (
                                  <div className="col-12 col-md-6">
                                    <p
                                      key={item}
                                      className="w-100 p-0 px-3 py-2 features-chip2"
                                      style={{
                                        color: "#27788a",
                                        fontFamily: "proxima_nova_rgregular",
                                        fontSize: 14,
                                      }}
                                    >
                                      {item}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex flex-wrap tags">
                      {[...new Set(apartment.amenities)].map((tag) => (
                        <div
                          className="user-select-none text-muted py-1 border px-2 my-2 tag d-flex justify-content-center align-items-center"
                          key={tag}
                          style={{
                            fontSize: 12,
                            background: "#f3f4f5",
                          }}
                        >
                          <StarIcon
                            style={{
                              fontSize: 14,
                            }}
                          />
                          <span className="mx-1">{tag}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-12 d-flex flex-column align-items-start mt-4 pb-3 px-0 mx-0">
                    <div className="row w-100 mx-0">
                      <div className="row rounded bg-white col-12 p-0 m-0 py-3">
                        <div className="d-flex justify-content-between my-2">
                          <p className="fc-5c">Monthly Rent</p>
                          <h5 className="fc-cprimary m-0">
                            ₹ {apartment.cost.toLocaleString()}
                          </h5>
                        </div>

                        <div className="my-2">
                          <div className="d-flex justify-content-between">
                            <p className="m-0 fc-5c">
                              Security Deposit (Instant refund)
                            </p>
                            <h5 className="fc-cprimary m-0">
                              ₹ {apartment.deposit.toLocaleString()}
                            </h5>
                          </div>
                          <p
                            style={{
                              fontFamily: "proxima_nova_rgregular",
                            }}
                            className="fc-75"
                          >
                            Fully refundable within 1-7 days if vacated in
                            original condition
                          </p>
                        </div>

                        {/* {(apartment.type === "managed" ||
                          apartment.category === "managed") && (
                            <div className="my-2">
                              <div className=" d-flex justify-content-between">
                                <p className="m-0 fc-5c">One Time Service Fees</p>
                                <h5 className="fc-cprimary m-0">
                                  ₹{" "}
                                  {parseInt(apartment.cost / 2).toLocaleString()}
                                </h5>
                              </div>
                              <p
                                style={{
                                  fontFamily: "proxima_nova_rgregular",
                                }}
                                className="fc-75"
                              >
                                Urbanletting charges a one time accommodation
                                convenience fee of Rs.2796.
                              </p>
                            </div>
                          )} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 d-flex flex-column align-items-start mt-4 pb-3 px-0 mx-0">
                    <div className="row w-100 mx-0">
                      <h4 className="m-0 fc-1c pb-3">
                        About {apartment.societyName}
                      </h4>

                      <div className="row bg-white col-12 p-0 m-0">
                        {/* col one */}
                        <div className="col-12 col-md-6">
                          <AboutChips
                            name="Property Type"
                            value={apartment.propertyType}
                          />
                          <AboutChips
                            name="Parking Type"
                            value={apartment.parkingType}
                          />{" "}
                          <AboutChips
                            name="Lease Term"
                            value={apartment.leaseTerm}
                          />
                          <AboutChips
                            name="Furnished"
                            value={apartment.furnishingStatus}
                          />
                        </div>

                        {/* col two */}
                        <div className="col-12 col-md-6">
                          <AboutChips
                            name="Property Sub-type"
                            value={apartment.propertyType}
                          />

                          <AboutChips
                            name="Parking Spots"
                            value={apartment.parkingSpots}
                          />

                          <AboutChips
                            name="Short-term"
                            value={apartment.shortTerm}
                          />

                          <AboutChips
                            name="Year Built"
                            value={apartment.yearBuilt}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-wrap tags">
                      {[...new Set(apartment.amenities)].map((tag) => (
                        <div
                          className="user-select-none text-muted py-1 border px-2 my-2 tag d-flex justify-content-center align-items-center"
                          key={tag}
                          style={{
                            fontSize: 12,
                            background: "#f3f4f5",
                          }}
                        >
                          <StarIcon
                            style={{
                              fontSize: 14,
                            }}
                          />
                          <span className="mx-1">{tag}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-4 col-md-2"
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <CustomButton
                    label="Delete"
                    onClickHandler={() => {
                      deleteProperty();
                    }}
                    isBg
                    bg="#3cd3a3"
                  />
                </div>
                <div className="col-4 col-md-2">
                  <CustomButton
                    label="Reject"
                    onClickHandler={() => {
                      rejectProperty();
                    }}
                    isBg
                    bg="#3cd3a3"
                  />
                </div>
                <div className="col-4 col-md-2">
                  <CustomButton
                    label="Approve"
                    onClickHandler={() => {
                      approveDoc();
                    }}
                    isBg
                    bg="#3cd3a3"
                  />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        apartment === undefined && <Loader />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    city: state.approve.city,
    locality: state.approve.locality,
    doc_id: state.approve.doc_id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUploadingLoader: (val) => dispatch(setUploadingLoader(val)),
  setDocId: (val) => dispatch(setDocId(val)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ApartmentDetailsApprove
);
