import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import {
  setCurrFriendID,
  setShowChatList,
  setShowChatBox,
  setCurrFriendObj,
} from "../../../../store/actions/actions";
import "./message-item.css";
import firebase from "../../../../config/fbConfig";
import linearLoaderPrimary from "../../../../assets/linear-loader-primary.svg";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Box, Typography } from "@mui/material";

const MessageItem = (props) => {
  const {
    user,
    users,
    userDoc,
    setCurrFriendID,
    setShowChatList,
    setShowChatBox,
    setCurrFriendObj,
  } = props;
  console.log(user);
  const { content, timestamp, idFrom } = props.lastMessage;
  const friendID = user?.uid === users[0] ? users[1] : users[0];
  const [friendObj, setFriendObj] = useState(null);

  useEffect(() => {
    if (friendID) {
      firebase
        .firestore()
        .collection("users")
        .doc(friendID)
        .get()
        .then((d) => {
          if (d) {
            // console.log(d.data());
            setFriendObj(d.data());
          }
        });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {friendObj === null ? (
        <div
          className="d-flex"
          style={{
            height: 60,
          }}
        >
          <img src={linearLoaderPrimary} alt="svg loader" className="mx-auto" />
        </div>
      ) : (
        <Box
          onClick={() => {
            setCurrFriendID(friendID);
            setCurrFriendObj(friendObj);
            setShowChatBox(true);
            setShowChatList(false);
          }}
          className="message-item border-bottom w-100 d-flex align-items-center c-pointer py-2 justify-content-between"
        >
          <div className="d-flex">
            <div className="mx-2">
              <Avatar alt={friendObj?.fullname} src="none" />
            </div>
            <div>
              <p
                className=""
                style={{
                  color: "black",
                  fontSize: 14,
                  fontFamily: "proxima_nova_rgregular",
                  marginBottom: "-5px",
                }}
              >
                {friendObj?.fullname}
              </p>

              <p
                className="fc-1c m-0 trunc-nline"
                style={{
                  "--lines": 1,
                  fontFamily: "proxima_nova_rgregular",
                }}
              >
                {userDoc.uid === idFrom && (
                  <DoneAllIcon
                    style={{
                      fontSize: 18,
                    }}
                  />
                )}{" "}
                {content}
              </p>
            </div>
          </div>
          <Typography
            sx={{
              fontSize: 12,
              marginBottom: "20px",
              marginRight: { xs: "25px", sm: "5px" },
            }}
            className="fc-1c text-muted fst-italic"
          >
            <Moment style={{ fontFamily: "proxima_nova_rgregular" }} fromNow>
              {timestamp.toDate()}
            </Moment>
          </Typography>
        </Box>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setShowChatList: (val) => dispatch(setShowChatList(val)),
  setShowChatBox: (val) => dispatch(setShowChatBox(val)),
  setCurrFriendID: (val) => dispatch(setCurrFriendID(val)),
  setCurrFriendObj: (val) => dispatch(setCurrFriendObj(val)),
});

export default connect(null, mapDispatchToProps)(MessageItem);
