// Define action types
const SET_ALL_CHATS = "SET_ALL_CHATS";

export const setAllChats = (chats) => ({
  type: SET_ALL_CHATS,
  payload: chats,
});

const initialState = [];

const chatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_CHATS:
      return {
        ...state,
        chats: action.payload,
      };
    // Other cases...
    default:
      return state;
  }
};

export default chatsReducer;
