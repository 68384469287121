import SendIcon from "@mui/icons-material/Send";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import firebase from "../../../../config/fbConfig";
import "./conversation.css";
import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { setShowChatBox } from "../../../../store/actions/actions";
import linearLoaderPrimary from "../../../../assets/linear-loader-primary.svg";
import Avatar from "@mui/material/Avatar";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import Moment from "react-moment";
import CallIcon from "@mui/icons-material/Call";
import { Box } from "@mui/material";
import { useMediaQuery } from "react-responsive";
const Conversation = ({ userDoc, friendID, setShowChatBox, friendObj }) => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const [msg, setMsg] = useState("");
  const [chats, setChats] = useState(null);
  const chatsContainerRef = useRef();

  const scrollToBottom = () => {
    if (chatsContainerRef.current) {
      chatsContainerRef.current.scrollTop =
        chatsContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (userDoc === null || friendID === null) return;

    const docID = [userDoc.id, friendID].sort().join("-");

    const unsub = firebase
      .firestore()
      .collection("messages")
      .doc(docID)
      .collection(docID)
      .orderBy("timestamp")
      .onSnapshot((d) => {
        if (d.size > 0) {
          setChats(() => d.docs.map((doc) => ({ id: doc.id, ...doc.data() })));

          firebase
            .firestore()
            .collection("messages")
            .doc(docID)
            .update({
              read: true,
            })
            .then(() => {
              scrollToBottom();
            });
        } else {
          setChats([]);
        }
      });

    return () => unsub();

    // eslint-disable-next-line
  }, [userDoc, friendID]);

  const saveMessage = (e) => {
    e.preventDefault();

    if (!msg || !friendID) return;
    const docID = [userDoc.id, friendID].sort().join("-");
    const obj = {
      content: msg,
      idFrom: userDoc.id,
      idTo: friendID,
      timestamp: new Date(),
    };

    firebase
      .firestore()
      .collection("messages")
      .doc(docID)
      .collection(docID)
      .add(obj)
      .then(() => {
        firebase
          .firestore()
          .collection("messages")
          .doc(docID)
          .set({
            lastMessage: obj,
            users: [userDoc.uid, friendID],
            read: false,
            idTo: friendID,
          })
          .then(() => {
            setMsg("");

            scrollToBottom();
          });
      });
  };
  return (
    <Box className="container w-100 position-absolute outerBox">
      <Box
        className={`container position-fixed w-100 outerBox`}
        sx={{
          zIndex: 200,
          pointerEvents: "none",
          bottom: isMobileScreen ? "0px" : "10px",
          marginLeft: isMobileScreen ? "-5px" : "23px",
        }}
      >
        <div className="p-0">
          <div
            className="bg-white border c col-11 col-md-3 ms-auto me-auto me-md-0 position-relative chatBox"
            style={{
              pointerEvents: "auto",
            }}
          >
            <div
              style={{
                height: 60,
              }}
              className="border-bottom d-flex align-items-center"
            >
              {" "}
              <Avatar alt={friendObj?.fullname} src="none" className="m-2" />
              <div>
                <h6 className="m-0 ms-1 fc-1c">{friendObj.fullname}</h6>
              </div>
              <div className="ms-auto d-flex align-items-center">
                <div className="fc-1c c-pointer">
                  <VideoCameraFrontIcon style={{ fontSize: 25 }} />
                </div>
                <div className="fc-1c mx-2 c-pointer">
                  <CallIcon style={{ fontSize: 23 }} />
                </div>
                <div
                  onClick={() => {
                    setShowChatBox(null);
                  }}
                  className="fc-1c c-pointer"
                >
                  <CloseIcon style={{ fontSize: 25 }} />
                </div>
              </div>
            </div>
            <div
              ref={chatsContainerRef}
              style={{
                // overflowX: "hidden",
                overflowY: "none",
                // height: 245,
              }}
              className="all-chats-container p-2 px-3"
            >
              {chats === null ? (
                <div className="d-flex">
                  <img
                    src={linearLoaderPrimary}
                    alt="svg loader"
                    className="mx-auto"
                  />
                </div>
              ) : chats.length > 0 ? (
                <div className="d-flex flex-column">
                  {chats.map((chat) => (
                    <Box>
                      <Box>
                        <div
                          className={`position-relative my-2 text-${
                            userDoc.uid === chat.idFrom ? "white" : "gray"
                          }`}
                          style={{
                            padding: "3px 10px",
                            maxWidth: "80%",
                            fontSize: 14,
                            borderRadius:
                              userDoc.uid === chat.idFrom
                                ? "10px 10px 0px 10px"
                                : "0px 10px 10px 10px",
                            width: "fit-content",
                            background:
                              userDoc.uid === chat.idFrom
                                ? "#3cb6d3"
                                : "#B2FFFF",
                            marginLeft:
                              userDoc.uid === chat.idFrom ? "auto" : 0,
                          }}
                        >
                          <p
                            className="m-0"
                            style={{
                              fontFamily: "proxima_nova_rgregular",
                            }}
                          >
                            {chat.content}
                          </p>
                          <div className="d-flex align-items-center">
                            <div
                              className="ms-auto"
                              style={{
                                fontSize: 10,
                              }}
                            >
                              <Moment
                                style={{
                                  fontFamily: "proxima_nova_rgregular",
                                  fontStyle: "italic",
                                }}
                                fromNow
                              >
                                {chat.timestamp.toDate()}
                              </Moment>
                            </div>

                            {userDoc.uid === chat.idFrom && (
                              <span className="ms-2">
                                <DoneAllIcon
                                  style={{
                                    fontSize: 15,
                                  }}
                                />
                              </span>
                            )}
                          </div>
                          <div
                            className={`${
                              userDoc.uid === chat.idFrom
                                ? "triangle-container-user"
                                : "triangle-container"
                            }`}
                          >
                            <div
                              className={`${
                                userDoc.uid === chat.idFrom
                                  ? "triangle-user"
                                  : "triangle"
                              }`}
                            ></div>
                          </div>
                        </div>
                      </Box>
                    </Box>
                  ))}
                </div>
              ) : (
                <div>No Chats</div>
              )}
            </div>
            <div className="chatBoxForm">
              <form
                onSubmit={saveMessage}
                className="px-2 mb-2 d-flex "
                style={{
                  height: 45,
                }}
              >
                <input
                  required
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  type="text"
                  placeholder="Type a message..."
                  className="post-input px-3 w-100"
                  style={{
                    borderRadius: 99,
                    fontFamily: "proxima_nova_rgregular",
                  }}
                />
                <button type="sumbit" className="clean-button p-2 bg-cprimary">
                  <SendIcon />
                </button>
              </form>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    userDoc: state.user.user
      ? state.firestore.ordered.userDoc
        ? state.firestore.ordered.userDoc[0]
        : {}
      : {},

    friendID: state.community.currentFriendID,
    friendObj: state.community.currentFriendObj,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setShowChatBox: (val) => dispatch(setShowChatBox(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
