import React from "react";

class DateFormatter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formattedDate: "",
    };
  }

  componentDidMount() {
    this.formatDate();
  }

  formatDate() {
    const { postedOn } = this.props;
    const date = new Date(postedOn);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const formattedDate = `${date.getDate()}th ${monthNames[date.getMonth()]}`;
    this.setState({ formattedDate });
  }

  render() {
    return <span>{this.state.formattedDate}</span>;
  }
}

export default DateFormatter;
