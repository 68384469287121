import React from "react";
import logo from "../../assets/Icon_white_transparent_1080px.svg";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import instagram from "../../assets/icons8-instagram-48.png";
import whatsapp from "../../assets/icons8-whatsapp-48.png";

function CataloguePage() {
  const items = [
    {
      id: 1,
      name: "List your Property",
      link: "https://urbanletting.in/post-flat",
    },
    {
      id: 2,
      name: "Find your Property",
      link: "https://urbanletting.in/apartments/yqnMZNXH2IRG8TVxw694",
    },
  ];
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "black",
          paddingTop: 0,
          minHeight: "100vh",
          color: "white",
          paddingTop: 10,
        }}
      >
        <img src={logo} alt="" style={{ width: 100 }} />
        <Box style={{ marginTop: 30 }}>
          <p style={{ fontSize: 30, margin: 0 }}>UrbanLetting Bengaluru</p>
          <p
            style={{
              textAlign: "center",
            }}
          >
            Rentals | Flats
          </p>
        </Box>
        <Stack spacing={2}>
          {items.map((item) => (
            <div
              style={{
                backgroundColor: "white",
                paddingTop: 12,
                paddingBottom: 12,
                paddingRight: 100,
                paddingLeft: 100,
                borderRadius: 8,
              }}
            >
              <a
                href={item.link}
                target="_blank"
                key={item.id}
                style={{ margin: 0, color: "black" }} rel="noreferrer"
              >
                {item.name}
              </a>
            </div>
          ))}
        </Stack>
        <Box style={{ marginTop: 20 }}>
          <img src={instagram} alt="ig" />
          <img src={whatsapp} alt="wh" />
        </Box>
      </Box>
    </>
  );
}

export default CataloguePage;
