import bangloreImg from "../assets/cities/banglore.jpg";
import mumbaiImg from "../assets/cities/mumbai.jpg";

export const flatGroups = [
  {
    id: 1,
    name: "Flats and Flatmates Bangalore",
    groupType: "Public group",
    img: bangloreImg,
  },
  {
    id: 2,
    name: "Flats and Flatmates Mumbai",
    groupType: "Public group",
    img: mumbaiImg,
  },
  {
    id: 3,
    name: " Brokers Network Bangalore",
    groupType: "Public group",
    img: mumbaiImg,
  },
  {
    id: 4,
    name: " Brokers Network Mumbai",
    groupType: "Public group",
    img: mumbaiImg,
  },
];

export const headerList = [
  { id: 0, name: "Home", redirect: "/" },
  { id: 1, name: "Owners", redirect: "/owners" },
  { id: 2, name: "Brokers", redirect: "/brokers" },
  { id: 3, name: "Flat & Flatmates", redirect: "/community" },
  { id: 4, name: "Post for free", redirect: "/post-for-free" },
];
