import { useEffect } from "react";
import { useState } from "react";
import firebase from "../../../../config/fbConfig";
import CustomInput from "../../../common/custom-input/custom-input";
import "./post-property-page-two.css";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import { scrollToTop } from "../../../../utils/common-functions";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { FieldError } from "../../../common/field-error/field-error";
import { CustomCheckBox } from "../../../common/custom-checkbox/custom-checkbox";

const PageTwo = ({ newPostData, addNewPostData, handleNavClick, userDoc }) => {
  const [nextCliked, setNextClicked] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const {
    propertyType,
    furnishingStatus,
    societyName,
    idealFor,
    flatNumber,
    postedBy,
    managementPreference,
  } = newPostData;

  const isContains = (arr, val) => arr.find((item) => item === val);
  const [doesntMatter, setDoesntMatter] = useState(false);

  useEffect(() => {
    addNewPostData({
      name: "idealFor",
      value: doesntMatter ? ["Family", "Bachelors", "Couple"] : [],
    });

    // eslint-disable-next-line
  }, [doesntMatter]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("city")
      .doc("SSX77pJNAU5wvOIwECnW")
      .collection("locality")
      .doc("yqnMZNXH2IRG8TVxw694")
      .collection("societies")
      .onSnapshot((d) => {
        if (d.size) {
          setSuggestions(
            d.docs.map((d) => ({
              name: d.data().name,
              id: d.id,
              commonImages: d.data().images,
            }))
          );
        }
      });
  }, []);

  return (
    <div>
      <div className="row">
        <div className="mt-4 pb-4"></div>
        <h2 className="mb-2 fc-1c pt-4 pb-2">Property Details</h2>
        <p
          style={{
            fontFamily: "proxima_nova_rgregular",
            fontSize: 17,
          }}
        >
          Provide important information about your rental. Tip: detailed
          listings tend to get more leads
        </p>
        <div className=""></div>

        <div className="row my-2 py-2 pb-4 mb-0 border-bottom">
          <div className="col-12 col-md-3 fc-1c p-2 d-flex">
            <span>
              <HomeWorkOutlinedIcon />
            </span>

            <h5 className="m-1 mx-3 fc-1c">I'm</h5>
          </div>
          <div className="col-12 col-md-8 d-flex flex-wrap flex-row flex-gap align-items-center my-3 my-md-0">
            {["Owner", "Broker"].map((type) => (
              <div
                key={type}
                style={{
                  borderRadius: "20px",
                  fontFamily: "proxima_nova_rgregular",
                  background: postedBy === type ? "#3cb6d3" : "white",
                }}
                className={`post-property-chips user-select-none py-2 c-pointer text-center ${
                  postedBy === type ? "text-white" : "fc-1c"
                } `}
                onClick={() => {
                  addNewPostData({ name: "postedBy", value: type });
                }}
              >
                {type}
              </div>
            ))}
            {propertyType.length === 0 && nextCliked && (
              <FieldError errMsg="Please choose who you" extraPadding />
            )}
          </div>
        </div>

        {/* property type */}

        <div className="row my-2 py-2 pb-4 mb-0 border-bottom">
          <div className="col-12 col-md-3 fc-1c p-2 d-flex">
            <span>
              <HomeWorkOutlinedIcon />
            </span>

            <h5 className="m-1 mx-3 fc-1c">Property Type</h5>
          </div>
          <div className="col-12 col-md-8 d-flex flex-wrap flex-row flex-gap align-items-center my-3 my-md-0">
            {["Apartment", "Independent"].map((type) => (
              <div
                key={type}
                style={{
                  borderRadius: "20px",
                  fontFamily: "proxima_nova_rgregular",
                  background: propertyType === type ? "#3cb6d3" : "white",
                }}
                className={`post-property-chips user-select-none py-2 c-pointer text-center ${
                  propertyType === type ? "text-white" : "fc-1c"
                } `}
                onClick={() => {
                  addNewPostData({ name: "propertyType", value: type });
                }}
              >
                {type}
              </div>
            ))}
            {propertyType.length === 0 && nextCliked && (
              <FieldError errMsg="Please choose property type" extraPadding />
            )}
          </div>
          {/* conditional field */}
          {propertyType === "Apartment" && (
            <div className="row my-2 pt-4">
              <div className="col-12 col-md-3 p-2 d-flex"></div>

              <div className="col-12 col-md-5">
                <div className="row mx-3 mx-md-0">
                  <div className="col-12 col-md-6 p-0 my-1 my-md-0 position-relative">
                    <CustomInput
                      isNewPostField
                      type="text"
                      label="Society name"
                      name="societyName"
                      id="data"
                      isOptions
                    />

                    <datalist
                      id="data"
                      style={{
                        maxHeight: 200,
                      }}
                    >
                      {suggestions.map((item) => (
                        <option key={item.id} value={item.name} />
                      ))}
                    </datalist>
                  </div>

                  {societyName.length !== 0 && (
                    <div className="col-12 col-md-5 p-0 my-3 my-md-0 mx-0 mx-md-2">
                      <CustomInput
                        isNewPostField
                        type="text"
                        label="Flat number"
                        name="flatNumber"
                      />{" "}
                    </div>
                  )}
                </div>
                {(societyName.length === 0 || flatNumber.length === 0) &&
                  nextCliked && (
                    <FieldError errMsg="Please enter Society Name and flat number both" />
                  )}
              </div>
            </div>
          )}
        </div>
      </div>

      {propertyType === "Apartment" && postedBy === "Owner" && (
        <div className="row my-2 py-2 pb-4 mb-0 border-bottom">
          <div className="col-12 col-md-3 fc-1c p-2 d-flex">
            <span>
              <HomeWorkOutlinedIcon />
            </span>

            <div className="m-1 mx-3">
              <h5>Management Preference</h5>
              <span
                style={{
                  fontSize: 12,
                  fontFamily: "proxima_nova_rgregular",
                }}
              >
                OpenList does not charge to manage your property. Management
                includes a free property photoshoot, rent agreement, tenant
                background check, and routine property inspections.
              </span>
            </div>
          </div>
          <div className="col-12 col-md-8 d-flex flex-wrap flex-row flex-gap align-items-center my-3 my-md-0">
            {["Self-Managed", "Managed by OpenList"].map((type) => (
              <div
                key={type}
                style={{
                  borderRadius: "20px",
                  fontFamily: "proxima_nova_rgregular",
                  background:
                    managementPreference === type ? "#3cb6d3" : "white",
                }}
                className={`post-property-chips user-select-none py-2 c-pointer text-center ${
                  managementPreference === type ? "text-white" : "fc-1c"
                } `}
                onClick={() => {
                  addNewPostData({ name: "managementPreference", value: type });
                }}
              >
                {type}
              </div>
            ))}
            {managementPreference.length === 0 && nextCliked && (
              <FieldError
                errMsg="Please choose management prefrence"
                extraPadding
              />
            )}
          </div>
        </div>
      )}

      {/* furnishing field */}
      <div className="row my-2 py-2 py-md-4 border-bottom">
        <div className="col-12 fc-1c col-md-3 p-2 d-flex">
          <span
            style={{
              marginTop: 2,
            }}
          >
            <ChairOutlinedIcon />
          </span>

          <h5 className="m-1 mx-3 fc-1c">Furnishing</h5>
        </div>
        <div className="col-12 col-md-8 d-flex flex-wrap flex-row flex-gap align-items-center my-3 my-md-0">
          {["Furnished", "Semi-furnished", "Un-furnished"].map((type) => (
            <div
              key={type}
              style={{
                borderRadius: "20px",
                fontFamily: "proxima_nova_rgregular",
                background: furnishingStatus === type ? "#3cb6d3" : "white",
              }}
              className={`post-property-chips user-select-none py-2 c-pointer text-center ${
                furnishingStatus === type ? "text-white" : "fc-1c"
              } `}
              onClick={() => {
                addNewPostData({ name: "furnishingStatus", value: type });
              }}
            >
              {type}
            </div>
          ))}
          {furnishingStatus.length === 0 && nextCliked && (
            <FieldError errMsg="Please specify furnished" extraPadding />
          )}
        </div>
      </div>

      {/* tenants field */}
      <div className="row my-2 py-2 py-md-4 border-bottom">
        <div className="col-12 col-md-3 fc-1c p-2 d-flex">
          <span>
            <CottageOutlinedIcon />
          </span>

          <div className="m-1 mx-3">
            <h5 className="fc-1c">Tenants Preferred</h5>
            <span
              style={{
                fontSize: 12,
                fontFamily: "proxima_nova_rgregular",
              }}
            >
              Add your text here
            </span>
          </div>
        </div>
        <div className="col-12 col-md-8 d-flex flex-wrap flex-row flex-gap align-items-center my-3 my-md-0">
          {["Family", "Bachelors", "Couple"].map((type) => (
            <div
              key={type}
              style={{
                borderRadius: "20px",
                fontFamily: "proxima_nova_rgregular",
                background: isContains(idealFor, type) ? "#3cb6d3" : "white",
              }}
              className={`post-property-chips py-2 user-select-none c-pointer text-center ${
                isContains(idealFor, type) ? "text-white" : "fc-1c"
              } `}
              onClick={() => {
                // add code here
                let temp = [];

                if (isContains(idealFor, type)) {
                  temp = idealFor.filter((item) => item !== type);
                } else {
                  temp = [...idealFor, type];
                }

                addNewPostData({
                  name: "idealFor",
                  value: temp,
                });
              }}
            >
              {type}
            </div>
          ))}

          {idealFor.length === 0 && nextCliked && (
            <FieldError errMsg="Please choose Ideal For" extraPadding />
          )}
        </div>

        <div className="row">
          <div className="col-0 col-md-3"></div>
          <div className="col-12 ps-4 col-md-9 d-flex align-items-center">
            <CustomCheckBox
              checked={doesntMatter}
              onChangeHandler={() => {
                setDoesntMatter((prev) => !prev);
              }}
            />
            <p className="m-0 mx-1 fc-1c pt-1">Doesn't Matter</p>
          </div>
        </div>
      </div>

      {/* control buttons */}
      <div className="d-flex w-100 justify-content-between align-items-center my-4 mb-0 pb-4">
        <h5
          onClick={() => handleNavClick("BACK")}
          className="fc-cprimary c-pointer"
        >
          <ArrowBackIosNewIcon />

          <span className="mx-1 pt-2">Back</span>
        </h5>
        <div>
          <button
            onClick={() => {
              setNextClicked(true);

              if (
                propertyType.length !== 0 &&
                furnishingStatus.length !== 0 &&
                idealFor.length !== 0
              ) {
                if (
                  propertyType === "Apartment" &&
                  (societyName.length === 0 || flatNumber.length === 0)
                ) {
                  return;
                } else {
                  handleNavClick("NEXT");
                }
              } else {
                scrollToTop();
              }
            }}
            className="text-white border-none bg-cprimary m-2 px-4 input-form-btns"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageTwo;
