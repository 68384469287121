import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Apartment from "../apartment/apartment";
import Loader from "../../../common/loader/Loader";
import PropertyFilters from "../property-filters/property-filters";
import { setCurrentLocalityID } from "../../../../store/actions/actions";
import { scrollToTop } from "../../../../utils/common-functions";
import "./apartments.css";
import Map from "../../../common/google-map/google-map";
import MobileFilters from "../property-filters/mobile-filters";
import { Button } from "@mui/material";
import { Cancel, ListAlt, LocationOn, Tune } from "@material-ui/icons";
import { FilterAlt } from "@mui/icons-material";
import Footer from "../../../common/footer/footer";

const DEFAULT_MIN_RANGE = 0;
const DEFAULT_MAX_RANGE = 100000;

const Aapartments = (props) => {
  useEffect(() => {
    scrollToTop();
  }, []);

  // filters
  const [selectedApartmentTypes, setSelectedApartmentTypes] = useState([]);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState("");
  const [selectedFurnishingTypes, setSelectedFurnishingTypes] = useState("");
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedLooking, setSelectedLooking] = useState("");
  const [minRentPrice, setMinRentPrice] = useState(DEFAULT_MIN_RANGE);
  const [maxRentPrice, setMaxRentPrice] = useState(DEFAULT_MAX_RANGE);
  const [societyFilter, setSocietyFilter] = useState("");
  const [filteredFlats, setFilteredFlats] = useState([]);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });

  const [latlng, setLatlng] = useState({
    lat: 41.31694479999999,
    lng: -74.1250465,
  });

  const resetAllFilters = () => {
    setSelectedApartmentTypes([]);
    setSelectedPropertyTypes("");
    setSelectedFurnishingTypes("");
    setSelectedCat("");
    setSelectedLooking("");
    setSocietyFilter("");

    setMaxRentPrice(DEFAULT_MAX_RANGE);
    setMinRentPrice(DEFAULT_MIN_RANGE);
  };

  const [flats, setFlats] = useState([]);
  const { locality } = useParams();
  const [loading, setLoading] = useState(true);
  const [currentView, setCurrentView] = useState("list");

  useEffect(() => {
    const fetchFlats = async () => {
      try {
        const res = await axios.get(
          `https://urbanletting-6afef.df.r.appspot.com/properties?city=bangalore&locality=${locality}`
        );
        setFlats(res.data);
        setLoading(false); // Set loading to false after data is fetched
        console.log(flats);
      } catch (err) {
        console.log(err);
      }
    };
    fetchFlats();
  }, []);

  useEffect(() => {
    const filteredData = flats.filter((flat) => {
      // Check if flat or its properties are undefined
      if (
        !flat ||
        !flat.bedroom ||
        !flat.propertyType ||
        !flat.furnishingStatus ||
        !flat.tags ||
        !flat.idealFor ||
        !flat.cost
      ) {
        return false; // Skip this flat if any required property is undefined
      }

      // Apply filters based on selected filter criteria
      const isApartmentTypeMatch =
        selectedApartmentTypes.length === 0 ||
        selectedApartmentTypes.includes(flat.bedroom);
      const isPropertyTypeMatch =
        selectedPropertyTypes === "" ||
        flat.propertyType === selectedPropertyTypes;
      const isFurnishingTypeMatch =
        selectedFurnishingTypes === "" ||
        flat.furnishingStatus === selectedFurnishingTypes;
      const isCatMatch =
        selectedCat === "" || (flat.tags && flat.tags.includes(selectedCat));
      const isLookingMatch =
        selectedLooking === "" ||
        (flat.idealFor && flat.idealFor.includes(selectedLooking));
      const isRentPriceInRange =
        parseInt(flat.cost) >= parseInt(minRentPrice) &&
        parseInt(flat.cost) <= parseInt(maxRentPrice);
      const isSocietyMatch =
        societyFilter === "" ||
        (flat.societyName &&
          flat.societyName
            .toLowerCase()
            .includes(societyFilter.toLowerCase().trim()));

      // Return true only if all filters match
      return (
        isApartmentTypeMatch &&
        isPropertyTypeMatch &&
        isFurnishingTypeMatch &&
        isCatMatch &&
        isLookingMatch &&
        isRentPriceInRange &&
        isSocietyMatch
      );
    });
    setFilteredFlats(filteredData);
  }, [
    selectedApartmentTypes,
    selectedPropertyTypes,
    selectedFurnishingTypes,
    selectedCat,
    selectedLooking,
    minRentPrice,
    maxRentPrice,
    societyFilter,
    flats,
  ]);

  const sortedFlats = filteredFlats.slice().sort((a, b) => {
    const timeA = a.postedOn;
    const timeB = b.postedOn;
    return timeB - timeA;
  });

  return (
    <div
      style={{
        backgroundColor: "#00000010",
        minHeight: "100vh",
      }}
      className="pt-0"
    >
      <div
        style={{
          display: isMobileScreen ? "flex" : "none",
          background: "white",
          position: "fixed",
          zIndex: 999,
          width: "100vw",
          height: "4rem",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 18px 0px 18px",
        }}
      >
        <Button
          sx={{ fontSize: "15px" }}
          startIcon={<ListAlt />}
          onClick={() => setCurrentView("list")}
        >
          List
        </Button>
        <Button
          sx={{ fontSize: "15px" }}
          startIcon={<LocationOn />}
          onClick={() => setCurrentView("map")}
        >
          Map
        </Button>
        <Button
          sx={{ fontSize: "15px" }}
          startIcon={<FilterAlt />}
          onClick={() => setCurrentView("filter")}
        >
          Filter
        </Button>
      </div>
      <div style={{ display: isMobileScreen ? "none" : "block" }}>
        <PropertyFilters
          selectedFurnishingTypes={selectedFurnishingTypes}
          setSelectedFurnishingTypes={setSelectedFurnishingTypes}
          selectedPropertyTypes={selectedPropertyTypes}
          setSelectedPropertyTypes={setSelectedPropertyTypes}
          selectedApartmentTypes={selectedApartmentTypes}
          setSelectedApartmentTypes={setSelectedApartmentTypes}
          selectedLooking={selectedLooking}
          setSelectedLooking={setSelectedLooking}
          selectedCat={selectedCat}
          setSelectedCat={setSelectedCat}
          minRentPrice={minRentPrice}
          setMinRentPrice={setMinRentPrice}
          maxRentPrice={maxRentPrice}
          setMaxRentPrice={setMaxRentPrice}
          resetAllFilters={resetAllFilters}
          societyFilter={societyFilter}
          setSocietyFilter={setSocietyFilter}
          setShowAllFilters={setShowAllFilters}
          showAllFilters={showAllFilters}
        />
      </div>

      {currentView === "filter" && (
        <div
          className="position-fixed d-block d-md-none w-100 bg-white top-0 p-0"
          style={{
            zIndex: 10,
            overflow: "scroll",
            height: "100vh",
          }}
        >
          <div
            className="pt-4"
            style={{
              backgroundColor: "#00000010",
              marginTop: 70,
            }}
          >
            <MobileFilters
              selectedFurnishingTypes={selectedFurnishingTypes}
              setSelectedFurnishingTypes={setSelectedFurnishingTypes}
              selectedPropertyTypes={selectedPropertyTypes}
              setSelectedPropertyTypes={setSelectedPropertyTypes}
              selectedApartmentTypes={selectedApartmentTypes}
              setSelectedApartmentTypes={setSelectedApartmentTypes}
              selectedLooking={selectedLooking}
              setSelectedLooking={setSelectedLooking}
              selectedCat={selectedCat}
              setSelectedCat={setSelectedCat}
              minRentPrice={minRentPrice}
              setMinRentPrice={setMinRentPrice}
              maxRentPrice={maxRentPrice}
              setMaxRentPrice={setMaxRentPrice}
              resetAllFilters={resetAllFilters}
              societyFilter={societyFilter}
              setSocietyFilter={setSocietyFilter}
              filteredFlats={filteredFlats}
              setShowAllFilters={setShowAllFilters}
              setCurrentView={setCurrentView}
            />
          </div>
        </div>
      )}
      <div className="dashboard h-100" style={{ width: "98.5vw" }}>
        <div className="row d-flex mb-0 h-100">
          <div className="d-none d-md-block col-3"></div>
          <div
            className={`${
              !isMobileScreen ? "d-flex justify-content-between" : ""
            }`}
            style={{ marginTop: "4.50rem" }}
          >
            <div
              className=" bg-white"
              style={{
                width: isMobileScreen ? "100%" : "64.5%",
                height: "100vh",
                overflowY: "scroll",
                scrollbarWidth: "none",
                display:
                  currentView === "map" || !isMobileScreen ? "block" : "none",
              }}
            >
              <Map
                cityPlaceId={locality}
                localityPlaceId={props.currentLocalityID}
              />
            </div>
            {showAllFilters ? (
              <div
                style={{
                  width: isMobileScreen ? "100%" : "35%",
                }}
              >
                <div className="" style={{ width: "100%" }}>
                  <MobileFilters
                    selectedFurnishingTypes={selectedFurnishingTypes}
                    setSelectedFurnishingTypes={setSelectedFurnishingTypes}
                    selectedPropertyTypes={selectedPropertyTypes}
                    setSelectedPropertyTypes={setSelectedPropertyTypes}
                    selectedApartmentTypes={selectedApartmentTypes}
                    setSelectedApartmentTypes={setSelectedApartmentTypes}
                    selectedLooking={selectedLooking}
                    setSelectedLooking={setSelectedLooking}
                    selectedCat={selectedCat}
                    setSelectedCat={setSelectedCat}
                    minRentPrice={minRentPrice}
                    setMinRentPrice={setMinRentPrice}
                    maxRentPrice={maxRentPrice}
                    setMaxRentPrice={setMaxRentPrice}
                    resetAllFilters={resetAllFilters}
                    societyFilter={societyFilter}
                    setSocietyFilter={setSocietyFilter}
                    showAllFilters={showAllFilters}
                    filteredFlats={filteredFlats}
                    setShowAllFilters={setShowAllFilters}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: isMobileScreen ? "100%" : "36%",
                  paddingLeft: 10,
                  height: "100vh",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  display:
                    currentView === "list" || !isMobileScreen
                      ? "block"
                      : "none",
                }}
              >
                {filteredFlats && filteredFlats.length > 0 && (
                  <div>
                    <div
                      className="mt-4 fc-1c"
                      style={{
                        fontFamily: "proxima_nova_rgregular",
                        fontSize: "20px",
                      }}
                    >
                      Home{" "}
                      <span
                        style={{
                          fontSize: "20px",
                          color: "gray",
                          margin: "0px 4px 0px 4px",
                        }}
                      >
                        ·
                      </span>{" "}
                      {filteredFlats[0].locality}{" "}
                      <span
                        style={{
                          fontSize: "20px",
                          color: "gray",
                          margin: "0px 4px 0px 4px",
                        }}
                      >
                        ·
                      </span>{" "}
                      <span
                        style={{
                          color: "black",
                          fontFamily: "proxima_nova_rgregular",
                          fontWeight: "500",
                        }}
                      >
                        {filteredFlats[0].city}
                      </span>
                    </div>
                    <div
                      style={{
                        color: "gray",
                        fontFamily: "proxima_nova_rgregular",
                        marginTop: "7px",
                      }}
                    >
                      {filteredFlats[0].city} Apartments, Condos and Houses for
                      Rent
                    </div>
                    <div
                      style={{
                        marginTop: "7px",
                        display: isMobileScreen ? "block" : "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "proxima_nova_rgregular",
                          fontSize: "20px",
                        }}
                      >
                        {filteredFlats.length} Rentals Found
                        <span
                          style={{
                            fontSize: "20px",
                            color: "gray",
                            margin: "0px 4px 0px 4px",
                          }}
                        >
                          ·
                        </span>{" "}
                        <span style={{ fontFamily: "proxima_nova_rgbold" }}>
                          Sort
                        </span>
                        <span
                          style={{
                            fontFamily: "proxima_nova_rgregular",
                            color: "gray",
                            marginLeft: "5px",
                            fontSize: "18px",
                          }}
                        >
                          Default
                        </span>
                      </div>
                      <Button
                        variant="outlined"
                        sx={{
                          fontFamily: "proxima_nova_rgregular",
                          textTransform: "none",
                          fontSize: "15px",
                          "&:hover": {
                            backgroundColor: "#E4F5FF",
                            boxShadow: "none",
                          },
                          "&:active": {
                            backgroundColor: "#E4F5FF",
                            boxShadow: "none",
                          },
                          "&:focus": {
                            backgroundColor: "#E4F5FF",
                            boxShadow: "none",
                          },
                        }}
                      >
                        Rent Guide
                      </Button>
                    </div>
                  </div>
                )}
                {loading ? (
                  <Loader />
                ) : sortedFlats.length > 0 ? (
                  <div>
                    {sortedFlats.map((flat) => (
                      <Apartment key={flat.id} {...flat} {...props} />
                    ))}
                    <div style={{ overflowX: "hidden" }}>
                      <Footer />
                    </div>
                  </div>
                ) : (
                  <div
                    className="d-flex fc-1c justify-content-center align-items-center"
                    style={{ height: "90vh" }}
                  >
                    No results found
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    apartments: state.firestore.ordered.catlogue,
    nearByLocalities: state.firestore.ordered.nearByLocalities,
    currentCityID: state.apartment.currentCityID,
    currentLocalityID: state.apartment.currentLocalityID,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentLocalityID: (val) => dispatch(setCurrentLocalityID(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Aapartments);
