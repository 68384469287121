import { NavLink, Redirect, Route } from "react-router-dom";
import favProperties from "./components/fav-properties/fav-properties";
import UserAccount from "./components/user-account/user-account";
import UserListings from "./components/user-listings/user-listings";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useMediaQuery } from "react-responsive";
import { scrollToTop } from "../../utils/common-functions";
import { useEffect } from "react";
import { connect } from "react-redux";
import MyPosts from "../community/components/community-post/myPosts";
import { FaImages } from "react-icons/fa";

const User = ({ userDoc }) => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div style={{ backgroundColor: "#f3f4f5" }}>
      <div
        className="container h-100 w-100 py-4 my-0"
        style={{
          minHeight: "100vh",
        }}
      >
        <div className="row">
          <div
            className={`col-12 col-md-3 d-flex flex-row flex-md-column justify-content-around justify-content-md-start ${
              !isMobileScreen && "position-fixed"
            } `}
            style={{
              backgroundColor: isMobileScreen ? "white" : "#f3f4f5",
            }}
          >
            <NavLink
              to="/profile/listings"
              className="my-0 my-md-3 py-3 px-4 d-flex align-items-center py-md-2 profile-options-link"
            >
              <ApartmentIcon />
              {!isMobileScreen && <span className="mx-2 pt-1">Listings</span>}
            </NavLink>
            <NavLink
              className="my-0 my-md-3 py-3 px-4 d-flex align-items-center  py-md-2 profile-options-link"
              to="/profile/favorites"
            >
              <FavoriteBorderIcon />
              {!isMobileScreen && <span className="mx-2 pt-1">Favorites</span>}
            </NavLink>

            <NavLink
              to="/profile/account"
              className="my-0 my-md-3 py-3 px-4 d-flex align-items-center py-md-2 profile-options-link"
            >
              <ManageAccountsIcon />
              {!isMobileScreen && <span className="mx-2 pt-1">Account</span>}
            </NavLink>

            <NavLink
              to="/profile/my_posts"
              className="my-0 my-md-3 py-3 px-4 d-flex align-items-center py-md-2 profile-options-link"
            >
              <FaImages />
              {!isMobileScreen && <span className="mx-2 pt-1">My Posts</span>}
            </NavLink>

            {userDoc?.isAdmin && (
              <NavLink
                to="/console/admin"
                className="my-0 my-md-3 py-3 px-4 d-flex align-items-center py-md-2 profile-options-link"
              >
                <AdminPanelSettingsIcon />
                {!isMobileScreen && <span className="mx-2 pt-1">Admin</span>}
              </NavLink>
            )}
          </div>
          <div
            style={{ left: isMobileScreen ? 0 : "500px" }}
            className="col-12 col-md-6 position-relative"
          >
            <Redirect exact from="/profile" to="/profile/listings" />
            <Route path="/profile/favorites/" component={favProperties} exact />
            <Route path="/profile/account/" component={UserAccount} exact />
            <Route path="/profile/listings/" component={UserListings} exact />
            <Route path="/profile/my_posts/" component={MyPosts} exact />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDoc: state.firestore.ordered.userDoc
    ? state.firestore.ordered.userDoc[0]
    : {},
});

export default connect(mapStateToProps, null)(User);
