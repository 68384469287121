import NotFound from "./components/common/not-found/not-found";
import React, { useState, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import apartmentDetails from "./components/details/components/apartment-details/apartment-details";
import Apartments from "./components/catalogue/components/apartments/apartments";
import PostProperty from "./components/post-property/post-property";
import Header from "./components/common/header/header";
import Footer from "./components/common/footer/footer";
import UploadingDataLoader from "./components/common/uploading-data-loader/uploading-data-loader";
import { connect, useSelector } from "react-redux";
import UploadingDone from "./components/common/uploading-done/uploading-done";
import Auth from "./components/common/auth/auth";
import "react-toastify/dist/ReactToastify.css";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import User from "./components/user/user";
import CommunityPost from "./components/community/components/community-post/community-post";
import Console from "./components/console/console";
import Owners from "./components/owners/Owners";
import CataloguePage from "./components/catalogue/CataloguePage";
import mixpanel from "mixpanel-browser";
import RequestCallback from "./components/details/components/contact-form/RequestCallback";
import Brokers from "./components/brokers/Brokers";
import homePage from "./components/landing/components/home-page/home-page";
import ApartmentPages from "./components/AparamentsPages/ApartmentPages";
import ProtectedRoute from "./components/common/auth/ProtectedRoute";
import { setAuthModalConfig } from "./store/actions/actions";

mixpanel.init("4803adae56bae8426473beee8ca60bc6", {
  debug: true,
  track_pageview: true,
});

function App(props) {
  const [showFooter, setShowFooter] = useState(true);
  const { doneLoader, uploadingLoader, authModalConfig } = props;
  const location = useLocation();
  const userData = useSelector((state) => state?.user?.user);

  setTimeout(() => {
    if (
      userData &&
      (!userData?.fullname ||
        !userData?.email ||
        !("fullname" in userData) ||
        !("email" in userData))
    ) {
      authModalConfig.show = true;
      authModalConfig.type = "SignUp";
    }
  }, 10000);

  useEffect(() => {
    setShowFooter(
      !location.pathname.includes("/catalogue") &&
        !location.pathname.includes("/community") &&
        !location.pathname.includes("/property/rent")
    );
  }, [location]);

  const showHeader =
    !location.pathname.includes("/catalogue") &&
    !location.pathname.includes("/community");

  return (
    <div className="position-relative">
      {doneLoader && <UploadingDone />}
      {uploadingLoader && <UploadingDataLoader />}
      {authModalConfig.show && <Auth />}
      {authModalConfig.show && authModalConfig.type === "requestCallback" && (
        <RequestCallback />
      )}

      {showHeader && <Header />}

      <div
        style={{
          paddingTop: showHeader ? 68 : 0,
        }}
      >
        <Switch>
          <Route path="/" component={homePage} exact />
          <Route path="/not-found/" component={NotFound} exact />
          <Route path="/post-for-free/" exact>
            <ProtectedRoute>
              <PostProperty />
            </ProtectedRoute>
          </Route>
          <Route path="/profile/" component={User} />
          <Route path="/console/admin/" component={Console} />
          <Route
            path="/properties/rent/bangalore/:locality/detail"
            component={apartmentDetails}
          />
          <Route
            path="/property/rent/bangalore/:locality"
            component={Apartments}
            exact
          />
          <Route
            path="/2bhk-flats-for-rent-in-:locality-bangalore"
            component={ApartmentPages}
            exact
          />
          <Route
            path="/property/bangalore/whitefield/details/:apartmentId/"
            component={apartmentDetails}
            exact
          />
          <Route path="/community" component={CommunityPost} exact />
          <Route path="/owners" component={Owners} exact />
          <Route path="/brokers" component={Brokers} exact />
          <Route path="/catalogue" component={CataloguePage} exact />
        </Switch>
      </div>
      {showFooter && <Footer />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  uploadingLoader: state.apartment.uploadingLoader,
  doneLoader: state.apartment.doneLoader,
  authModalConfig: state.user.authModalConfig,
  filterArray: state.apartment.filterArray,
  user: state.user.user,
  city: state.approve.city,
  locality: state.approve.locality,
  doc_id: state.approve.doc_id,
  currentCityID: state.apartment.currentCityID,
  currentLocalityID: state.apartment.currentLocalityID,
});

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
    return [
      ...(props.currentLocalityID
        ? [
            {
              collection: "city",
              doc: props.currentCityID ? props.currentCityID : null,
              where: props.filterArray.length !== 0 ? props.filterArray : null,
              storeAs: "catlogue",
              subcollections: [
                {
                  collection: "locality",
                  doc: props.currentLocalityID ? props.currentLocalityID : null,
                  subcollections: [
                    {
                      collection: "catalogue",
                    },
                  ],
                },
              ],
            },
          ]
        : []),

      {
        collection: "callback",
        storeAs: "callback",
        orderBy: ["date", "desc"],
      },

      {
        collection: "city",
        doc: props.currentCityID ? props.currentCityID : null,
        storeAs: "nearByLocalities",
        subcollections: [
          {
            collection: "locality",
            doc: props.currentLocalityID ? props.currentLocalityID : null,
          },
        ],
      },
      {
        collection: "users",
        doc: props.user ? props.user.uid : null,
        storeAs: "userDoc",
      },

      {
        collection: "city",
        storeAs: "allCities",
      },

      ...(props.city
        ? [
            {
              collection: "city",
              storeAs: "allLocalities",
              doc: props.city ? props.city : null,
              subcollections: [
                {
                  collection: "locality",
                },
              ],
            },
          ]
        : []),

      ...(props.user
        ? [
            {
              collection: "messages",
              storeAs: "messages",
              where: ["users", "array-contains", props.user.uid],
            },
          ]
        : []),

      ...(props.user
        ? [
            {
              collection: "messages",
              storeAs: "unreadMessages",
              where: [
                ["users", "array-contains", props.user.uid],
                ["read", "==", false],
                ["idTo", "==", props.user.uid],
              ],
            },
          ]
        : []),

      ...(props.locality
        ? [
            {
              collection: "city",
              storeAs: "localityData",
              doc: props.city ? props.city : null,
              subcollections: [
                {
                  collection: "locality",
                  doc: props.locality ? props.locality : null,
                  subcollections: [{ collection: "pending" }],
                },
              ],
            },
          ]
        : []),

      ...(props.locality
        ? [
            {
              collection: "city",
              storeAs: "societiesData",
              doc: props.city ? props.city : null,
              subcollections: [
                {
                  collection: "locality",
                  doc: props.locality ? props.locality : null,
                  subcollections: [{ collection: "societies" }],
                },
              ],
            },
          ]
        : []),

      ...(props.doc_id
        ? [
            {
              collection: "city",
              storeAs: "pendingDocData",
              doc: props.city ? props.city : null,
              subcollections: [
                {
                  collection: "locality",
                  doc: props.locality ? props.locality : null,
                  subcollections: [
                    {
                      collection: "catalogue",
                      doc: props.doc_id ? props.doc_id : null,
                    },
                  ],
                },
              ],
            },
          ]
        : []),
    ];
  })
)(App);
