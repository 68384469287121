import "./uploading-done.css";
import done from "../../../assets/done.gif";

const UploadingDone = () => {
  return (
    <div
      className="position-fixed w-100 h-100 d-flex justify-content-center align-items-center bg-white"
      style={{
        zIndex: 101,
      }}
    >
      <img src={done} alt="done gif" />
    </div>
  );
};
export default UploadingDone;
