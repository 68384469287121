import { useEffect, useState } from "react";
import { connect } from "react-redux";
import UploadImages from "../../../common/upload-images/upload-images";
import CustomInput from "../../../common/custom-input/custom-input";
import firebase from "../../../../config/fbConfig";
import { CustomButton } from "../../../common/custom-button/custom-button";
import Loader from "../../../common/loader/Loader";
import {
  setDoneLoader,
  setUploadingLoader,
  setCity,
  setLocality,
} from "../../../../store/actions/actions";
import { useHistory } from "react-router-dom";
import { AccessDenied } from "../../../common/access-denied/access-denied";
import { scrollToTop } from "../../../../utils/common-functions";
import LocationCityIcon from "@mui/icons-material/LocationCity";

const Societies = ({
  userDoc,
  user,
  setDoneLoader,
  setUploadingLoader,
  setCity,
  setLocality,
  allCities,
  allLocalities,
  city,
  locality,
  societiesData,
}) => {
  const [images, setImages] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [societyName, setSocietyName] = useState("");
  const [config, setConfig] = useState({
    type: "",
    id: null,
  });
  const [nextCliked, setNextClicked] = useState(false);
  const history = useHistory();

  const isAllUploadedImages = () => images.every((img) => img.url.length > 0);

  useEffect(() => {
    setCity("none");
    setLocality("none");
    // eslint-disable-next-line
  }, []);

  const isSuggContains = (arr, val) => arr.find((item) => item.name === val);

  useEffect(() => {
    const isSugg = isSuggContains(suggestions, societyName);
    setConfig(
      isSugg
        ? { type: "managed", id: isSugg.id }
        : { type: "unmanaged", id: null }
    );
    // eslint-disable-next-line
  }, [societyName]);

  useEffect(() => {
    if (societiesData && societiesData.length > 0) {
      setSuggestions(
        societiesData.map((d) => ({
          name: d.name,
          id: d.id,
        }))
      );
    }
  }, [societiesData]);

  const cleanUpEverything = () => {
    setUploadingLoader(false);
    setDoneLoader(true);
    setLocality("none");
    setCity("none");

    setTimeout(() => {
      setDoneLoader(false);
      history.push("/");

      scrollToTop();
    }, 3500);
  };

  const pushDataToFirestore = () => {
    if (societyName.length === 0 || images.length === 0) {
      setNextClicked(true);
      return;
    }

    setNextClicked(false);

    setUploadingLoader(true);

    // doc already available just update it
    if (config.type === "managed") {
      firebase
        .firestore()
        .collection("city")
        .doc(city)
        .collection("locality")
        .doc(locality)
        .collection("societies")
        .doc(config.id)
        .get()
        .then((d) => {
          const doc = d.data();
          const prevImages = doc.images ? doc.images : [];
          d.ref
            .update({
              images: [
                ...prevImages,
                ...images
                  .sort((a, b) => a.order - b.order)
                  .map((img) => img.url),
              ],
            })
            .then(() => {
              // all done
              cleanUpEverything();
            });
        });
    } else {
      // create a fresh doc

      firebase
        .firestore()
        .collection("city")
        .doc(city)
        .collection("locality")
        .doc(locality)
        .collection("societies")
        .add({
          name: societyName,
          type: "managed",
          details: [],
          images: images
            .sort((a, b) => a.order - b.order)
            .map((img) => img.url),
        })
        .then((ddd) => {
          // all done
          cleanUpEverything();
        });
    }
  };

  return (
    <div
      className="container h-100 w-100 py-4"
      style={{
        minHeight: "100vh",
      }}
    >
      {user === undefined ? (
        <Loader />
      ) : userDoc.isAdmin !== undefined ? (
        userDoc.isAdmin ? (
          <>
            <h4 className="fc-1c mt-4">Add Society Images</h4>

            {/* select city */}
            <div className="row my-2 pt-4">
              <div className="col-12 fc-1c col-md-3 p-2 d-flex">
                <span
                  style={{
                    marginTop: 2,
                  }}
                >
                  <LocationCityIcon />
                </span>

                <h5 className="fc-1c m-1 mx-2">City name?</h5>
              </div>

              <div className="col-12 col-md-7">
                <div className="row mx-3 mx-md-0">
                  <div className="col-12 col-md-6 p-0 my-1 my-md-0 position-relative">
                    <select
                      style={{
                        fontFamily: "proxima_nova_rgregular",
                      }}
                      className="form-control location-input"
                      onChange={(e) => {
                        setCity(
                          e.target.item(e.target.selectedIndex).dataset.id
                        );

                        setLocality("none");
                      }}
                    >
                      {allCities &&
                        allCities.map((city, index) => (
                          <>
                            {index === 0 && (
                              <option
                                value="Select"
                                data-id="none"
                                key="Select"
                              >
                                Select City
                              </option>
                            )}
                            <option
                              value={city.name}
                              data-id={city.id}
                              key={city.id}
                            >
                              {city.name}
                            </option>
                          </>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* select locality */}
            {city !== "none" && allLocalities && allLocalities.length > 0 && (
              <div className="row my-2 pt-4">
                <div className="col-12 fc-1c col-md-3 p-2 d-flex">
                  <span
                    style={{
                      marginTop: 2,
                    }}
                  >
                    <LocationCityIcon />
                  </span>

                  <h5 className="fc-1c m-1 mx-2">Locality name?</h5>
                </div>

                <div className="col-12 col-md-7">
                  <div className="row mx-3 mx-md-0">
                    <div className="col-12 col-md-6 p-0 my-1 my-md-0 position-relative">
                      <select
                        style={{
                          fontFamily: "proxima_nova_rgregular",
                        }}
                        className="form-control location-input"
                        onChange={(e) => {
                          setLocality(
                            e.target.item(e.target.selectedIndex).dataset.id
                          );
                          // setDocId(null);
                        }}
                      >
                        {allLocalities &&
                          allLocalities.map((locality, index) => (
                            <>
                              {index === 0 && (
                                <option
                                  value="Select"
                                  data-id="none"
                                  key="Select"
                                >
                                  Select Locality
                                </option>
                              )}
                              <option
                                value={locality.name}
                                data-id={locality.id}
                                key={locality.id}
                              >
                                {locality.name}
                              </option>
                            </>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {city !== "none" && locality !== "none" && (
              <>
                {" "}
                <div className="row my-2 pt-4">
                  <div className="col-12 fc-1c col-md-3 p-2 d-flex">
                    <span
                      style={{
                        marginTop: 2,
                      }}
                    >
                      <LocationCityIcon />
                    </span>

                    <h5 className="fc-1c m-1 mx-2">Society Name?</h5>
                  </div>

                  <div className="col-12 col-md-7">
                    <div className="row mx-3 mx-md-0">
                      <div className="col-12 col-md-6 p-0 my-1 my-md-0 position-relative">
                        <CustomInput
                          value={societyName}
                          onChangeHandler={(val) => {
                            setSocietyName(val);
                          }}
                          type="text"
                          label="Society name"
                          id="data"
                          isOptions
                          extraClasses=" location-input"
                        />

                        <datalist
                          id="data"
                          style={{
                            maxHeight: 200,
                          }}
                        >
                          <select>
                            {suggestions.map((item) => (
                              <option key={item.id} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </datalist>
                      </div>
                    </div>
                  </div>

                  {(societyName.length === 0 || images.length === 0) &&
                    nextCliked && (
                      <div className="col-12 col-md-8 text-danger mb-2 mx-3 mx-md-0">
                        Please input all the values.
                      </div>
                    )}
                </div>
                {/* upload images */}
                <UploadImages
                  images={images}
                  setImages={setImages}
                  isAllUploaded={isAllUploadedImages}
                  isSocietyRoute
                />
                <div className="row m-0 col-12 col-md-3">
                  <CustomButton
                    label="Save"
                    onClickHandler={pushDataToFirestore}
                    isBg
                    bg="#3cd3a3"
                    isDisabled={!isAllUploadedImages()}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <AccessDenied />
        )
      ) : user === null ? (
        <AccessDenied />
      ) : (
        <Loader />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  userDoc: state.firestore.ordered.userDoc
    ? state.firestore.ordered.userDoc[0]
    : {},
  allLocalities: state.firestore.ordered.allLocalities,
  allCities: state.firestore.ordered.allCities,
  city: state.approve.city,
  locality: state.approve.locality,
  societiesData: state.firestore.ordered.societiesData,
});

const mapDispatchToProps = (dispatch) => ({
  setUploadingLoader: (val) => dispatch(setUploadingLoader(val)),
  setDoneLoader: (val) => dispatch(setDoneLoader(val)),
  setCity: (val) => dispatch(setCity(val)),
  setLocality: (val) => dispatch(setLocality(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Societies);
