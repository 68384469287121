import { Box, Typography } from "@mui/material";
import "./owners.css";
import ownersImg from "../../assets/landlord-home.png";
import Grid from "@mui/material/Grid";
import resources from "../../assets/owners/resources.png";
import howttoguide from "../../assets/owners/howtoguide.png";
import industry from "../../assets/owners/industry.png";
import Verified from "../../assets/owners/verifiedAccount.png";
import Support from "../../assets/owners/support.png";
import Sign from "../../assets/owners/propertySign.png";
import { Link } from "react-router-dom";

function Owners() {
  const items = [
    {
      title: "Find tenants at no cost",
      img: resources,
    },
    {
      title: "Free property photoshoot",
      img: Sign,
    },
    {
      title: "Free rent agreement",
      img: howttoguide,
    },
    {
      title: "Free tenant verification",
      img: industry,
    },
    {
      title: "Routine property inspection",
      img: Verified,
    },
    {
      title: "Support at your fingertips",
      img: Support,
    },
  ];
  return (
    <div style={{ backgroundColor: "#3CB6D3" }}>
      <Box
        sx={{
          paddingTop: 10,
          backgroundColor: "#3CB6D3",
        }}
        className="container"
      >
        <Box
          sx={{
            display: { sm: "flex", xs: "block" },
            // paddingLeft: { sm: 5, md: 20, xs: 3 },
            // paddingRight: { sm: 15, md: 0, xs: 3 },
          }}
        >
          <Box style={{}}>
            <Box>
              <h1
                style={{
                  color: "white",
                  fontSize: 70,
                  fontFamily: "proxima_nova_rgbold",
                  textShadow: "2px 2px 6px hsla(0, 0%, 50.2%, .7)",
                }}
              >
                Time to List
              </h1>
              <p
                style={{
                  marginTop: "-1px",
                  fontSize: 23,
                  color: "white",
                  width: "70%",
                  fontFamily: "proxima_nova_rgregular",
                }}
              >
                Join the largest open listing network. No Subscription Popups.
                Rent property in no time
              </p>
              <p
                style={{
                  marginTop: 30,
                  color: "white",
                  fontSize: 25,
                  fontWeight: 600,
                  fontFamily: "proxima_nova_rgregular",
                  textShadow: "1.5px 1.5px 3px hsla(0, 0%, 50.2%, .7)",
                }}
              >
                List for free, No Catch
              </p>
              <Link to="/post-for-free">
                <button
                  style={{
                    border: "none",
                    padding: "10px 40px 10px 40px",
                    borderRadius: 8,
                    boxShadow: "2px 2px 3px rgba(0,0,0,.7)",
                    marginTop: 7,
                    fontSize: 20,
                    fontFamily: "proxima_nova_rgbold",
                    color: "#2f91a7",
                  }}
                >
                  Post Your Property
                </button>
              </Link>
            </Box>
          </Box>
          <Box sx={{ width: "50%" }}>
            <div style={{}}>
              <img className="responsiveImage" src={ownersImg} alt="hero-img" />
            </div>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#F1F3F4",
            padding: { xs: 3, sm: 3, md: 10 },
            marginTop: { sm: 10, xs: 8 },
            borderRadius: 8,
          }}
        >
          <Box style={{ textAlign: "center", color: "#3CB6D3" }}>
            <h1
              style={{
                fontFamily: "proxima_nova_rgbold",
                fontSize: 45,
                textShadow: "1px 1px 2px hsla(0, 0%, 50.2%, .6)",
              }}
            >
              What We Offer
            </h1>
            <Typography
              sx={{
                paddingRight: { xs: 0, sm: 10 },
                paddingLeft: { xs: 0, sm: 10 },
                fontFamily: "proxima_nova_rgregular",
                fontSize: 20,
                fontWeight: 500,
                color: "#489cb0",
              }}
            >
              If you are looking to increase visibility for your listing, join
              the thousands of landlords who have benefited from OpenList.in
            </Typography>
          </Box>

          <Box style={{ marginTop: 50 }}>
            <Grid
              container
              rowSpacing={5}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {items.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      backgroundColor: "white",
                      borderRadius: 15,
                      border: "1px solid rgb(218, 220, 224)",
                    }}
                  >
                    <img
                      src={item.img}
                      alt={item.alt}
                      srcSet=""
                      height={150}
                      className="p-4"
                    />
                    <p
                      style={{
                        fontFamily: "proxima_nova_rgregular",
                        color: "#3CB6D3",
                        fontWeight: 600,
                      }}
                    >
                      {item.title}
                    </p>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default Owners;
