import "./custom-button.css";

const CustomButton = (props) => {
  const {
    label,
    onClickHandler,
    isBg,
    bg,
    isIcon,
    icon,
    fontColor,
    isRounded,
    isBorder,
    isDisabled,
    type,
  } = props;
  return (
    <button
      disabled={isDisabled}
      type={type ? type : "button"}
      style={{
        background: isBg ? bg : "#00000015",
        color: fontColor ? fontColor : "#fff",
        fontSize: 12,
        letterSpacing: 1,
        border: isBorder ? "1px solid #1c5663" : "none",
        borderRadius: isRounded ? 999 : 5,
        cursor: isDisabled ? "no-drop" : "cursor",
        "--clr": isBg ? bg : fontColor,
      }}
      className={`custom-button w-100 border transition-3s py-2 text-uppercase transition-3s`}
      onClick={() => onClickHandler && onClickHandler()}
    >
      <span className="pt-4 mx-1">{label}</span>
      {isIcon && <span>{icon}</span>}
    </button>
  );
};

export { CustomButton };
