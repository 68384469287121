import { connect } from "react-redux";
import { CustomButton } from "../../../common/custom-button/custom-button";
import Loader from "../../../common/loader/Loader";
import {
  setCity,
  setLocality,
  setDocId,
} from "../../../../store/actions/actions";
import { AccessDenied } from "../../../common/access-denied/access-denied";
import ApartmentDetailsApprove from "../apartment-details-approve/apartment-details-approve";
import { useEffect } from "react";
import LocationCityIcon from "@mui/icons-material/LocationCity";

const Approve = ({
  userDoc,
  user,
  setCity,
  allLocalities,
  allCities,
  localityData,
  pendingDocData,
  setLocality,
  setDocId,
  city,
  locality,
  doc_id,
}) => {
  useEffect(() => {
    setCity("none");
    setLocality("none");
    setDocId(null);

    // eslint-disable-next-line
  }, []);
  return (
    <div
      className="container h-100 w-100 py-4 px-0"
      style={{
        minHeight: "100vh",
      }}
    >
      {user === undefined ? (
        <Loader />
      ) : userDoc.isAdmin !== undefined ? (
        userDoc.isAdmin ? (
          <>
            <h4 className="fc-1c mt-4">Approve pending property posts</h4>

            {/* select city */}
            <div className="row my-2 pt-4">
              <div className="col-12 fc-1c col-md-3 p-2 d-flex">
                <span
                  style={{
                    marginTop: 2,
                  }}
                >
                  <LocationCityIcon />
                </span>
                <h5 className="fc-1c m-1 mx-2">City name?</h5>
              </div>

              <div className="col-12 col-md-7">
                <div className="row mx-3 mx-md-0">
                  <div className="col-12 col-md-6 p-0 my-1 my-md-0 position-relative">
                    <select
                      style={{
                        fontFamily: "proxima_nova_rgregular",
                      }}
                      className="form-control location-input"
                      onChange={(e) => {
                        setCity(
                          e.target.item(e.target.selectedIndex).dataset.id
                        );

                        setLocality("none");
                        setDocId(null);
                      }}
                    >
                      {allCities &&
                        allCities.map((city, index) => (
                          <>
                            {index === 0 && (
                              <option
                                value="Select"
                                data-id="none"
                                key="Select"
                              >
                                Select City
                              </option>
                            )}
                            <option
                              value={city.name}
                              data-id={city.id}
                              key={city.id}
                            >
                              {city.name}
                            </option>
                          </>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* select locality */}
            {city !== "none" && allLocalities && allLocalities.length > 0 && (
              <div className="row my-2 pt-4">
                <div className="col-12 fc-1c col-md-3 p-2 d-flex">
                  <span
                    style={{
                      marginTop: 2,
                    }}
                  >
                    <LocationCityIcon />
                  </span>

                  <h5 className="fc-1c m-1 mx-2">Locality name?</h5>
                </div>

                <div className="col-12 col-md-7">
                  <div className="row mx-3 mx-md-0">
                    <div className="col-12 col-md-6 p-0 my-1 my-md-0 position-relative">
                      <select
                        style={{
                          fontFamily: "proxima_nova_rgregular",
                        }}
                        className="form-control location-input"
                        onChange={(e) => {
                          setLocality(
                            e.target.item(e.target.selectedIndex).dataset.id
                          );
                          setDocId(null);
                        }}
                      >
                        {allLocalities &&
                          allLocalities.map((locality, index) => (
                            <>
                              {index === 0 && (
                                <option
                                  value="Select"
                                  data-id="none"
                                  key="Select"
                                >
                                  Select Locality
                                </option>
                              )}
                              <option
                                value={locality.name}
                                data-id={locality.id}
                                key={locality.id}
                              >
                                {locality.name}
                              </option>
                            </>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* pending posts of that locality */}

            {city !== "none" && locality !== "none" && localityData && (
              <table className="table border">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Doc ID</th>
                    <th scope="col">View</th>
                  </tr>
                </thead>
                <tbody>
                  {localityData.length === 0 && (
                    <div className="text-muted my-2">No pending properties</div>
                  )}
                  {localityData.map((pending, index) => (
                    <tr key={pending.id} className="my-3">
                      <td className="col-1">{index + 1}</td>
                      <td className="col-8">{pending.id}</td>
                      <td className="col-4">
                        <CustomButton
                          label="View Property"
                          onClickHandler={() => {
                            setDocId(pending.catalogueId);
                          }}
                          isBg
                          bg="#3cd3a3"
                        />{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {city !== "none" &&
              locality !== "none" &&
              doc_id &&
              pendingDocData && (
                <ApartmentDetailsApprove apartment={pendingDocData[0]} />
              )}
          </>
        ) : (
          <AccessDenied />
        )
      ) : user === null ? (
        <AccessDenied />
      ) : (
        <Loader />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  userDoc: state.firestore.ordered.userDoc
    ? state.firestore.ordered.userDoc[0]
    : {},
  allLocalities: state.firestore.ordered.allLocalities,
  allCities: state.firestore.ordered.allCities,
  localityData: state.firestore.ordered.localityData,
  pendingDocData: state.firestore.ordered.pendingDocData,
  city: state.approve.city,
  locality: state.approve.locality,
  doc_id: state.approve.doc_id,
});

const mapDispatchToProps = (dispatch) => ({
  setCity: (val) => dispatch(setCity(val)),
  setLocality: (val) => dispatch(setLocality(val)),
  setDocId: (val) => dispatch(setDocId(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Approve);
