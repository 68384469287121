import "./apartment.css";
import SwiperCore, { Navigation, Pagination } from "swiper";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { CustomButton } from "../../../common/custom-button/custom-button";
import { ReactComponent as MaintenanceIncludedIcon } from "../../../../assets/maintenanceIncluded.svg";
import { ReactComponent as MaintenanceExtraIcon } from "../../../../assets/maintenanceExtra.svg";
import { connect } from "react-redux";
import Lightbox from "react-image-lightbox";
import { useEffect, useState } from "react";
import DateFormatter from "../../../../common/DateFormatter";
import { Link } from "react-router-dom";
import ImageWidget from "../../../common/image-widget/image-widget";
import { Button, Typography } from "@mui/material";
import { CalendarToday, Email } from "@material-ui/icons";
import { useMediaQuery } from "react-responsive";
SwiperCore.use([Navigation, Pagination]);

const Apartment = (props) => {
  const {
    images,
    id,
    tags,
    bedroom,
    bathroom,
    postedOn,
    cost,
    propertyType,
    city,
    localeName,
    area,
    societyName,
    deposit,
    postedBy,
    maintenanceIncluded,
    maintenance,
    locality,
  } = props;
  const [photoIndex, setPhotoIndex] = useState(0);
  const [showBigPic, setBigPic] = useState(false);
  const [rentedOut, setRentedOut] = useState(false);
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });

  useEffect(() => {
    const timestamp = postedOn; // Your timestamp
    const date = new Date(timestamp); // Convert timestamp to date object

    const currentDate = new Date(); // Current date
    const diffTime = currentDate - date; // Difference in milliseconds

    const diffDays = diffTime / (1000 * 60 * 60 * 24); // Convert difference from milliseconds to days

    if (diffDays >= 30) {
      setRentedOut(true);
      console.log("The date is 21 days or more older than the current date.");
    } else {
      console.log("The date is less than 21 days old.");
    }
  }, []);

  return (
    <>
      <Link to={`/properties/rent/bangalore/${locality}/detail?id=${id}`}>
        <div
          className="apartment-card rounded bg-white my-4 p-2"
          style={{ color: "black" }}
        >
          <div>
            <div className="col-12 position-relative">
              <ImageWidget
                src={images[0]}
                alt="Example Image"
                isRentedOut={rentedOut}
              />
              {showBigPic && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => setBigPic(false)}
                  onMovePrevRequest={() =>
                    setPhotoIndex(
                      (photoIndex + images.length - 1) % images.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % images.length)
                  }
                  enableZoom={false}
                />
              )}
            </div>

            <div className="p-2 mt-2">
              <div className="d-flex justify-content-between">
                <div
                  className="col-10 col-md-8 d-flex align-items-center"
                  // style={{
                  //   borderRight: "1px solid #00000020",
                  // }}
                >
                  <h5 className="m-0 d-flex w-100 align-items-center flex-wrap">
                    <h4
                      style={{
                        color: "#3cb6d3",
                        fontFamily: "proxima_nova_rgbold",
                      }}
                    >
                      ₹ {cost.toLocaleString()}
                    </h4>{" "}
                    <div className="mx-1">
                      {maintenanceIncluded === "Extra" ? (
                        <div className="m-0 mb-2 c-pointer maintenance-chip position-relative">
                          <p
                            className="position-absolute bg-cprimary rounded text-white px-2 py-1 text-center"
                            style={{ fontFamily: "proxima_nova_rgregular" }}
                          >
                            ₹ {parseInt(maintenance).toLocaleString()}{" "}
                            Maintenance
                          </p>
                          <span className="mx-1">+</span>
                          <MaintenanceExtraIcon />{" "}
                        </div>
                      ) : (
                        <div className="m-0 mb-2 c-pointer maintenance-chip position-relative">
                          <p
                            className="position-absolute bg-cprimary rounded text-white px-2 py-1 text-center"
                            style={{ fontFamily: "proxima_nova_rgregular" }}
                          >
                            No Extra Maintenance
                          </p>
                          <span className="mx-1">+</span>
                          <MaintenanceIncludedIcon />{" "}
                        </div>
                      )}
                    </div>
                    <span
                      className="px-2 text-center text-muted"
                      style={{
                        fontFamily: "proxima_nova_rgregular",
                        fontSize: 14,
                      }}
                    >
                      Deposit ₹ {deposit.toLocaleString()}
                    </span>
                  </h5>
                </div>
                {isMobileScreen ? (
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "20px",
                      textTransform: "none",
                      fontFamily: "proxima_nova_rgregular",
                      fontSize: 16,
                      paddingY: "1px",
                    }}
                  >
                    <Email />
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "20px",
                      textTransform: "none",
                      fontFamily: "proxima_nova_rgregular",
                      fontSize: 16,
                      paddingY: "3px",
                    }}
                    startIcon={<Email />}
                  >
                    Contact
                  </Button>
                )}
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Typography
                    variant="h6"
                    color={"GrayText"}
                    style={{ fontFamily: "proxima_nova_rgregular" }}
                  >
                    {propertyType} ·{" "}
                    <span
                      style={{
                        fontFamily: "proxima_nova_rgregular",
                        color: "GrayText",
                        fontSize: 17,
                      }}
                    >
                      <DateFormatter postedOn={postedOn} />
                    </span>
                  </Typography>
                  <Typography
                    className="mt-2"
                    variant="body2"
                    color={"#5c6f73"}
                    style={{ fontFamily: "proxima_nova_rgbold" }}
                  >
                    {bedroom} BED · {bathroom} BATH · {area} FT²
                  </Typography>
                  <Typography
                    className="mt-2"
                    variant="body2"
                    color={"GrayText"}
                    style={{ fontFamily: "proxima_nova_rgregular" }}
                  >
                    {localeName}, {city}
                  </Typography>
                </div>

                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "20px",
                    textTransform: "none",
                    fontFamily: "proxima_nova_rgregular",
                    fontSize: 16,
                    paddingY: isMobileScreen ? "1px" : "3px",
                  }}
                  startIcon={<CalendarToday />}
                >
                  Book a Tour
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLocalityID: state.apartment.currentLocalityID,
  };
};

export default connect(mapStateToProps, null)(Apartment);
