const initState = {
  city: null,
  locality: null,
  doc_id: null,
};

const approveReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_CITY_NAME":
      return {
        ...state,
        city: action.payload,
      };

    case "SET_LOCALITY_NAME":
      return {
        ...state,
        locality: action.payload,
      };

    case "SET_DOC_ID":
      return {
        ...state,
        doc_id: action.payload,
      };

    default:
      return state;
  }
};

export default approveReducer;
