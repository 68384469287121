import { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const FeaturesDisplay = ({ options, features }) => {
  const [showOptions, setOptions] = useState(0);
  const [featureType, setFeatureType] = useState("");

  return (
    <div className="col-12 bg-white">
      {showOptions === 0 && (
        <div>
          {options.map((option) => (
            <div
              key={option.key}
              onClick={() => {
                setFeatureType(option);
                setOptions(1);
              }}
              className="rounded p-2 m-2 my-4 c-pointer d-flex justify-content-between"
            >
              <h6
                className="fc-1c m-0"
                style={{
                  fontSize: 16,
                }}
              >
                {option.name}
              </h6>

              <div className="d-flex text-muted align-items-center">
                <h6 className="m-0 mx-1">({features[option.key].length})</h6>
                <span>
                  <ArrowForwardIosIcon
                    style={{
                      fontSize: 16,
                    }}
                  />
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
      {showOptions !== 0 && (
        <div className="d-flex flex-column">
          <div className="d-flex py-2 my-3 align-items-center border-bottom">
            <div
              className="fc-cprimary c-pointer"
              onClick={() => setOptions(0)}
            >
              <ArrowBackIosIcon
                style={{
                  fontSize: 16,
                }}
              />
              <span className="py-1">Back</span>
            </div>

            <h5 className="m-0 mx-2 my-1 text-muted">{featureType.name}</h5>

            <h5
              className="m-0 text-end text-muted"
              style={{
                flex: 1,
              }}
            >
              ({features[featureType.key].length})
            </h5>
          </div>

          <div className="row bg-white">
            {features[featureType.key] !== undefined &&
              features[featureType.key].map((opt) => (
                <div key={opt} className={`col-6 px-4 my-1 m-auto m-md-0`}>
                  <h6 className="fc-1c">{opt}</h6>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { FeaturesDisplay };
