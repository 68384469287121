import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setAuthModalConfig } from "../../store/actions/actions";
import {
  setDoneLoader,
  addNewPostData,
  resetAllInputs,
  setUploadingLoader,
  setUser,
} from "../../store/actions/actions";
import "./post-property.css";

import PageOne from "./components/post-property-page-one/post-property-page-one";
import PageThree from "./components/post-property-page-three/post-property-page-three";
import PageTwo from "./components/post-property-page-two/post-property-page-two";
import { useSelector } from "react-redux";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

const PostProperty = (props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const user = useSelector((state) => state?.user?.user);

  const handleNavClick = (type) => {
    if (type === "NEXT") {
      setCurrentPage((prev) => prev + 1);
    } else if (type === "BACK") {
      setCurrentPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    if (user == null) {
      props.setAuthModalConfig({ show: true, type: "Login" });
    } else {
      props.setAuthModalConfig({ show: false, type: "Login" });
    }
  }, [user, props.setAuthModalConfig]);

  return (
    <>
      <div style={{ background: "#f3f4f5" }} className="p-0">
        <div className="w-100 post-property container">
          {currentPage === 0 && (
            <PageOne
              handleNavClick={handleNavClick}
              newPostData={props.newPostData}
              addNewPostData={props.addNewPostData}
            />
          )}
          {currentPage === 1 && (
            <PageTwo
              handleNavClick={handleNavClick}
              newPostData={props.newPostData}
              addNewPostData={props.addNewPostData}
              userDoc={props.userDoc}
            />
          )}
          {currentPage === 2 && (
            <PageThree
              handleNavClick={handleNavClick}
              newPostData={props.newPostData}
              addNewPostData={props.addNewPostData}
              resetAllInputs={props.resetAllInputs}
              setCurrentPage={setCurrentPage}
              setUploadingLoader={props.setUploadingLoader}
              setDoneLoader={props.setDoneLoader}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  newPostData: state.apartment.newPost,
  userDoc: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (val) => dispatch(setUser(val)),
  addNewPostData: (obj) => dispatch(addNewPostData(obj)),
  resetAllInputs: () => dispatch(resetAllInputs()),
  setUploadingLoader: (val) => dispatch(setUploadingLoader(val)),
  setDoneLoader: (val) => dispatch(setDoneLoader(val)),
  setAuthModalConfig: (config) => dispatch(setAuthModalConfig(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostProperty);
