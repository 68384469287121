import CountComponent from "../../../common/count-component/count-component";
import FloorInput from "../../../common/floor-input/floor-input";
import FeaturesInput from "../../../common/features-input/features-input";
import toast from "react-hot-toast";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import UploadImages from "../../../common/upload-images/upload-images";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import CustomInput from "../../../common/custom-input/custom-input";
import UploadVideos from "../../../common/upload-videos/upload-videos";
import { scrollToTop } from "../../../../utils/common-functions";
import { FieldError } from "../../../common/field-error/field-error";
import DomainIcon from "@mui/icons-material/Domain";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import { useMediaQuery } from "react-responsive";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const PageThree = ({
  handleNavClick,
  addNewPostData,
  newPostData,
  resetAllInputs,
  setUploadingLoader,
  setDoneLoader,
  setCurrentPage,
  user,
}) => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const [descriptionBody, setDescriptionBody] = useState("");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const history = useHistory();
  const [chipData, setChipData] = useState([]);
  const [tag, setTag] = useState("");
  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleChange = (event) => {
    setTag(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setChipData((prevChipData) => [...prevChipData, tag]);
      setTag("");
    }
  };

  const [pageTwoClicked, setPageTwoClicked] = useState(false);
  const isAllUploadedImages = () =>
    newPostData.images.every((img) => img.length > 0);

  const isAllUploadedVideos = () =>
    newPostData.videos.every((img) => img.length > 0);

  // building tags
  useEffect(() => {
    let temp = [];

    // temp = [...temp, ...newPostData.idealFor.map((item) => `Only ${item}`)];

    addNewPostData({ name: "tags", value: temp });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    addNewPostData({
      name: "images",
      value: images.sort((a, b) => a.order - b.order).map((img) => img.url),
    });
  }, [images]);

  useEffect(() => {
    addNewPostData({
      name: "videos",
      value: videos.sort((a, b) => a.order - b.order).map((vid) => vid.url),
    });
  }, [videos]);

  const newPostDatawithId = {
    ...newPostData,
    uid: user.id,
    desc: descriptionBody,
    postedOn: Date.now(),
    tags: chipData,
  };
  const postNewApartment = async () => {
    try {
      const res = await axios.post(
        "https://urbanletting-6afef.df.r.appspot.com/properties",
        newPostDatawithId
      );
      if (res.status === 200) {
        setUploadingLoader(false);
        toast.success("Apartment posted successfully!");
        cancelEverything("reset");
      } else {
        toast.error("Failed to post apartment. Please try again.");
      }
    } catch (error) {
      setUploadingLoader(false);
      toast.error("An error occurred while posting the apartment.");
      console.error("Error:", error);
    }
  };

  const saveDataToDB = () => {
    setUploadingLoader(true);
    postNewApartment();
  };

  const cancelEverything = (type) => {
    if (type === "reset") {
      resetAllInputs();
      setCurrentPage(0);

      return;
    }
    addNewPostData("features", {
      unitFeatures: [],
      societyFeatures: [],
    });

    setUploadingLoader(false);
    resetAllInputs();
    setDoneLoader(true);

    setTimeout(() => {
      setDoneLoader(false);
      history.push("/");

      scrollToTop();
    }, 3500);
  };

  return (
    <>
      <div className="border-bottom py-4">
        <h2 className="mb-4 fc-1c">Floor Plans</h2>
        <p
          style={{
            fontFamily: "proxima_nova_rgregular",
          }}
        >
          Your rental requires at least one floor plan, but feel free to add
          more
        </p>

        <div className="row bg-white my-2 p-3 d-flex flex-column flex-md-row">
          <div className="col-12 col-md-2">
            <CountComponent
              heading="Bedrooms"
              name="bedroom"
              pageTwoClicked={pageTwoClicked}
            />
          </div>
          <div className="col-12 col-md-2">
            <CountComponent
              heading="Bathrooms"
              name="bathroom"
              pageTwoClicked={pageTwoClicked}
            />
          </div>

          <div className="col-12 col-md-2">
            <FloorInput
              heading="Rent"
              name="cost"
              unit="₹"
              unitPosition="start"
              pageTwoClicked={pageTwoClicked}
            />
          </div>

          <div className="col-12 col-md-2">
            <FloorInput
              heading="Unit size"
              name="area"
              unit="ft²"
              unitPosition="end"
              pageTwoClicked={pageTwoClicked}
            />
          </div>

          <div className="col-12 col-md-2">
            <FloorInput
              heading="Deposit Amount"
              name="deposit"
              error="deposit"
              unit="₹"
              unitPosition="start"
              pageTwoClicked={pageTwoClicked}
            />
          </div>
          {newPostData.postedBy === "Broker" && (
            <div className="col-12 col-md-2">
              <FloorInput
                heading="Brokerage"
                name="brokerage"
                error="brokerage"
                unit="₹"
                unitPosition="start"
                pageTwoClicked={pageTwoClicked}
              />
            </div>
          )}
        </div>
      </div>

      <div className="row py-4 border-bottom">
        <div className="col-12 fc-1c col-md-2 p-2 d-flex">
          <DomainIcon />
          <h5 className="fc-1c m-1 mx-2">Maintenance?</h5>
        </div>

        <div className="col-12 col-md-6 d-flex flex-wrap flex-row flex-gap align-items-center my-3 my-md-0">
          {["Included", "Extra"].map((type) => (
            <div
              key={type}
              style={{
                borderRadius: "20px",
                fontFamily: "proxima_nova_rgregular",
                background:
                  newPostData.maintenanceIncluded === type
                    ? "#3cb6d3"
                    : "white",
              }}
              className={`post-property-chips user-select-none py-2 c-pointer text-center ${
                newPostData.maintenanceIncluded === type
                  ? "text-white"
                  : "fc-1c"
              } `}
              onClick={() => {
                addNewPostData({ name: "maintenanceIncluded", value: type });
              }}
            >
              {type}
            </div>
          ))}

          {newPostData.maintenanceIncluded === "" && pageTwoClicked && (
            <FieldError errMsg="Please choose maintenance?" extraPadding />
          )}

          <div className="row w-100 mb-0">
            {newPostData.maintenanceIncluded === "Extra" && (
              <div className="col-12 col-md-6 mt-2 my-md-0">
                <CustomInput
                  isNewPostField
                  type="text"
                  label="Maintenance cost"
                  name="maintenance"
                  isLessHeight
                  extraClasses=" location-input"
                />
                {newPostData.maintenanceIncluded === "Extra" &&
                  newPostData.maintenance.length === 0 &&
                  pageTwoClicked && (
                    <FieldError errMsg=" Please enter maintenance cost" />
                  )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className={`py-4 border-bottom d-flex ${
          isMobileScreen && "flex-column "
        }`}
      >
        <div className="col-12 fc-1c col-md-2 p-2 d-flex">
          <DomainIcon />
          <h5 className="fc-1c m-1 mx-2">Property Tags</h5>
        </div>

        <div
          className={`d-flex flex-column align-items-start  ${
            isMobileScreen ? "w-100" : "w-25"
          }`}
        >
          <input
            type="text"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={tag}
            placeholder="Eg. Premium Property, Furnished etc."
            style={{
              borderBottom: "none",
              border: "1px solid rgba(128, 128, 128, 0.3)",
              borderRadius: "25px",
              fontFamily: "proxima_nova_rgregular",
              padding: "15px",
              width: "100%",
              color: "gray",
            }}
            className=""
          />

          <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              listStyle: "none",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
            component="ul"
          >
            {chipData?.map((data, index) => {
              return (
                <ListItem key={index}>
                  <Chip
                    style={{ fontFamily: "proxima_nova_rgregular" }}
                    label={data}
                    onDelete={handleDelete(data)}
                  />
                </ListItem>
              );
            })}
          </Paper>
        </div>
      </div>

      {/* upload images section  */}

      <div className="border-bottom py-4">
        <h2 className="mb-4 fc-1c">Photos</h2>
        <p
          style={{
            fontFamily: "proxima_nova_rgregular",
          }}
        >
          Drag the images to change the order.
        </p>

        <UploadImages
          images={images}
          setImages={setImages}
          isAllUploaded={isAllUploadedImages}
          propertyImagesData={{
            city: newPostData.city,
            locality: newPostData.locality,
          }}
        />

        {images.length < 2 && pageTwoClicked && (
          <FieldError errMsg="Please upload atleast two pictures." />
        )}
      </div>

      {/* upload videos section */}

      <div className="border-bottom py-4">
        <h2 className="mb-4 fc-1c">Videos</h2>
        <p
          style={{
            fontFamily: "proxima_nova_rgregular",
          }}
        >
          Drag the videos to change the order.
        </p>

        <UploadVideos
          videos={videos}
          setVideos={setVideos}
          newPostData={newPostData}
        />
      </div>

      <div className="row my-4 mb-0 m-1">
        <h2 className="mb-4 m-0 fc-1c">Description</h2>
        <div className="col-12 border p-2 bg-white">
          <TextareaAutosize
            defaultValue={newPostData.desc}
            style={{
              outline: "none",
              border: "none",
              resize: "none",
            }}
            onChange={(e) => setDescriptionBody(e.target.value)}
          />
        </div>
        {descriptionBody.split(" ").length < 10 && pageTwoClicked && (
          <FieldError errMsg="Please enter description (min 10 words)." />
        )}
      </div>

      <div className="row my-4 py-4 mb-0">
        <h2 className="mb-4 fc-1c">Features / Amenities</h2>
        <p
          style={{
            fontFamily: "proxima_nova_rgregular",
          }}
        >
          Select the items that apply to your rental
        </p>

        <FeaturesInput />

        {newPostData.features["unitFeatures"].length === 0 &&
          newPostData.features["societyFeatures"].length === 0 &&
          pageTwoClicked && <FieldError errMsg="Please select some features" />}
      </div>

      <div className="d-flex w-100 justify-content-between align-items-center my-4 mb-0 pb-4">
        <h5
          onClick={() => handleNavClick("BACK")}
          className="fc-cprimary c-pointer"
        >
          <ArrowBackIosNewIcon />

          <span className="mx-1 pt-2">Back</span>
        </h5>
        <div>
          <button
            onClick={() => cancelEverything("reset")}
            className="text-white border-none bg-cprimary m-2 px-4 input-form-btns"
          >
            Reset
          </button>
          <button
            onClick={() => {
              setPageTwoClicked(true);

              if (
                isAllUploadedImages() &&
                isAllUploadedVideos() &&
                newPostData.bedroom > 0 &&
                newPostData.bathroom > 0 &&
                newPostData.cost.length !== 0 &&
                newPostData.deposit.length !== 0 &&
                newPostData.area.length !== 0 &&
                newPostData.images.length >= 2 &&
                descriptionBody.length >= 10 &&
                (newPostData.features["unitFeatures"].length !== 0 ||
                  newPostData.features["societyFeatures"].length !== 0)
              ) {
                if (
                  newPostData.maintenanceIncluded === "No" &&
                  newPostData.maintenance.length === 0
                ) {
                  return;
                } else {
                  saveDataToDB();
                }
              } else {
                scrollToTop();
              }
            }}
            className=" text-white border-none bg-cprimary m-2 px-4 input-form-btns"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, null)(PageThree);
