const initState = {
  user: undefined,
  authModalConfig: { show: false, type: "" },
  welcomeMsgConfig: { show: false, msg: "" },
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_AUTH_MODAL_CONFIG":
      return {
        ...state,
        authModalConfig: action.payload,
      };

    case "SET_WELCOME_MSG_CONFIG":
      return {
        ...state,
        welcomeMsgConfig: action.payload,
      };

    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
