import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useMediaQuery } from "react-responsive";
import "./modal-wrapper.css";

const ModalWrapper = ({ open, handleClose, children }) => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobileScreen ? "90%" : "35%",
  };
  return (
    <Modal
      className=".modal-content"
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="bg-white rounded row m-0">{children}</div>
      </Box>
    </Modal>
  );
};
export default ModalWrapper;
