import { ReactComponent as Playstore } from "../../../assets/playstore.svg";
import { ReactComponent as AppStore } from "../../../assets/appstore.svg";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import Geocode from "react-geocode";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./footer.css";
import { connect } from "react-redux";
import { setAuthModalConfig } from "../../../store/actions/actions";
import { Facebook, Mail, Twitter } from "@material-ui/icons";
import { BsTwitterX } from "react-icons/bs";
import { Divider } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";

Geocode.setApiKey("AIzaSyAfnuR3fH07x2UtXonxFnlmcsFeXfzWZQs");
Geocode.setLanguage("en");
Geocode.setRegion("in");

const Footer = ({ user, setAuthModalConfig, userDoc }) => {
  const history = useHistory();
  const location = useLocation();
  const isPropertyPage =
    location.pathname.includes("/property/rent") ||
    location.pathname.includes("/community");
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });

  const apartmentsNearMe = () => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        var crd = pos.coords;

        Geocode.fromLatLng(crd.latitude, crd.longitude).then(
          (response) => {
            const address = response.results[0].formatted_address;

            let locality = address.split(",").at(-4)
              ? address.split(",").at(-4)
              : "";
            if (locality.toLowerCase().includes("whitefield")) {
              history.push("/apartments/yqnMZNXH2IRG8TVxw694");
            } else {
              history.push("/not-found");
            }
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  };

  return (
    <footer
      className="page"
      style={{
        background: "#3cb6d3",
        color: "white",
        position: "relative",
        zIndex: 1,
        overflowX: "hidden"
      }}
    >
      <div
        className={`footer-heading container ${
          isPropertyPage ? "d-none" : "d-flex"
        }  justify-content-between gap-5  align-items-center p-1`}
      >
        <h2 className="mb-0 text-white text-center">OpenList</h2>
        <div className="footer-heading-btn">
          <button>Blogs</button>
          <button>About Us</button>
          <button>Terms</button>
          <button>Privacy Policy</button>
          <button>FAQ's</button>
        </div>
      </div>
      <h2
        style={{ display: isPropertyPage || isMobileScreen ? "block" : "none" }}
        className="mb-2 text-white text-center text-secondary mobile-heading"
      >
        OpenList
      </h2>
      <Divider
        style={{
          background: "white",
          width: "95vw",
          margin: "auto",
          marginBottom: "30px",
        }}
      />

      <div className="container text-center text-md-left mt-8">
        <div
          className={` mt-3 ${
            isPropertyPage || isMobileScreen ? "d-grid" : "d-flex"
          } justify-content-around`}
          style={{
            gridTemplateColumns: isMobileScreen ? "1fr" : "1fr 1fr",
            justifyItems: " center",
          }}
        >
          <div
            className="col-md-3 col-lg-3 col-xl-3 mb-4"
            style={{ width: isPropertyPage ? "fit-content" : "" }}
          >
            <h6 className="text-uppercase font-weight-bold mb-4">
              Popular Searches
            </h6>

            {user !== null && userDoc && userDoc.isAdmin && (
              <p
                className="font-weight-light text-center bg-none py-0 text-capitalize mb-4 near-me transition-3s"
                onClick={() => {
                  history.push("/community");
                }}
                style={{
                  cursor: "pointer",
                  fontFamily: "proxima_nova_rgregular",
                  fontSize: 15,
                  cursor: "pointer",
                  marginBottom: "10px",
                }}
              >
                Community
              </p>
            )}
            <p
              style={{
                fontFamily: "proxima_nova_rgregular",
                fontSize: 15,
                cursor: "pointer",
                marginBottom: "10px",
              }}
            >
              Rooms Near Me
            </p>
            <p
              style={{
                fontFamily: "proxima_nova_rgregular",
                fontSize: 15,
                cursor: "pointer",
                marginBottom: "10px",
              }}
            >
              Houses Near Me
            </p>
            <p
              style={{
                fontFamily: "proxima_nova_rgregular",
                fontSize: 15,
                cursor: "pointer",
                marginBottom: "10px",
              }}
            >
              Renting Made Easy
            </p>
            <p
              className="text-center text-capitalize near-me transition-3s"
              onClick={apartmentsNearMe}
              style={{
                cursor: "pointer",
                fontFamily: "proxima_nova_rgregular",
                fontSize: 15,
                marginBottom: "10px",
              }}
            >
              Apartments Near Me
            </p>

            <p
              style={{
                fontFamily: "proxima_nova_rgregular",
                fontSize: 15,
                cursor: "pointer",
                marginBottom: "10px",
              }}
            >
              All Rentals Near Me
            </p>
          </div>
          <div
            className="col-md-3 col-lg-3 col-xl-3 mb-4"
            style={{ width: isPropertyPage ? "fit-content" : "" }}
          >
            <h6 className="text-uppercase font-weight-bold mb-4">LANDLORDS </h6>
            {/* <hr
              className="deep-gray accent-2  mt-0 d-inline-block mx-auto"
              style={{
                width: "60px",
              }}
            /> */}

            <div>
              <Link
                className="btn border-0 text-capitalize font-weight-bold bg-white"
                style={{
                  borderRadius: "20px",
                  color: "#3cb6d3",
                  lineHeight: "26px",
                  fontWeight: 500,
                  fontSize: 12,
                }}
                onClick={() => {
                  if (!user) {
                    setAuthModalConfig({ show: true, type: "Login" });
                  }
                }}
                to={user ? "/post-for-free" : ""}
              >
                Post for Free
              </Link>
            </div>
            <p
              style={{
                fontFamily: "proxima_nova_rgregular",
                fontSize: 15,
                cursor: "pointer",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              Brokers
            </p>
            <p
              style={{
                fontFamily: "proxima_nova_rgregular",
                fontSize: 15,
                cursor: "pointer",
                marginBottom: "10px",
              }}
            >
              My Listings
            </p>
            <p
              style={{
                fontFamily: "proxima_nova_rgregular",
                fontSize: 15,
                cursor: "pointer",
                marginBottom: "10px",
              }}
            >
              Email Preferences
            </p>
          </div>
          <div
            className="col-md-3 col-lg-3 col-xl-3 mb-4"
            style={{ width: isPropertyPage ? "fit-content" : "" }}
          >
            <div className="w-100">
              <h6 className="text-uppercase font-weight-bold mb-4">
                rental apps
              </h6>
              {/* <hr
                className="deep-gray accent-2 mb-4 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                }}
              /> */}

              <div>
                <p>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.hyperdot.urbanletting"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Playstore />
                  </a>
                </p>
                <p>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.hyperdot.urbanletting"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AppStore />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-3 col-lg-3 col-xl-3 mb-4"
            style={{ width: isPropertyPage ? "fit-content" : "" }}
          >
            <h6 className="text-uppercase font-weight-bold mb-4">
              Social media
            </h6>
            {/* <hr
              className="deep-gray accent-2 mb-4 mt-0 d-inline-block mx-auto"
              style={{
                width: "60px",
              }}
            /> */}

            <div className="d-flex flex-gap justify-content-center social-media-flinks">
              <p>
                <a
                  target="_blank"
                  href="#mail"
                  className="p-3 transition-3s b-shadow"
                  rel="noreferrer"
                >
                  <YouTubeIcon />
                </a>
              </p>
              <p>
                <a
                  href="https://www.linkedin.com/company/urbanletting/about/"
                  className="p-3 transition-3s b-shadow"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon />
                </a>
              </p>
              <p>
                <a
                  href="https://www.instagram.com/urbanletting/"
                  target="_blank"
                  className="p-3 transition-3s b-shadow"
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>
              </p>
            </div>
            <div className="d-flex flex-gap justify-content-center social-media-flinks mt-4">
              <p>
                <a
                  target="_blank"
                  href="https://www.facebook.com/"
                  className="p-3 transition-3s b-shadow"
                  rel="noreferrer"
                >
                  <Facebook />
                </a>
              </p>{" "}
              <p>
                <a
                  target="_blank"
                  href="https://twitter.com/"
                  className="p-3 transition-3s b-shadow"
                  rel="noreferrer"
                >
                  <BsTwitterX />
                </a>
              </p>{" "}
              <p>
                <a
                  target="_blank"
                  href=""
                  className="p-3 transition-3s b-shadow"
                  rel="noreferrer"
                >
                  <Mail />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Divider style={{ background: "white", width: "95vw", margin: "auto" }} />
      <div className="footer-copyright text-center py-3">
        © 2021 Copyright:
        <a className="mx-2" href="https://urbanletting.in/">
          {" "}
          OpenList.in
        </a>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  userDoc: state.firestore.ordered.userDoc
    ? state.firestore.ordered.userDoc[0]
    : {},
});

const mapDispatchToProps = (dispatch) => ({
  setAuthModalConfig: (val) => dispatch(setAuthModalConfig(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
