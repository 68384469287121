import Properties from "../../../common/properties/properties";
import ApartmentIcon from "@mui/icons-material/Apartment";

const UserListings = () => {
  return (
    <div className="container h-100 w-100 py-4">
      <div className="fc-cprimary border-bottom pb-2 d-flex align-items-center">
        <ApartmentIcon
          style={{
            fontSize: 40,
          }}
        />

        <h2 className="mx-3 fc-1c pt-2">Listings</h2>
      </div>

      <Properties keyName="listings" />
    </div>
  );
};

export default UserListings;
