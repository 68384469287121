import { React } from "react";

import { connect } from "react-redux";

import { addNewPostData } from "../../../store/actions/actions";
import { FieldError } from "../field-error/field-error";

import "./floor-input.css";

const FloorInput = ({
  heading,
  name,
  addNewPostData,
  newPostData,
  pageTwoClicked,
  area,
  unitPosition,
  error,
}) => {
  return (
    <form className="row my-3 my-md-0 d-flex align-items-center floor-input">
      <h6 className="col-6 col-md-12">{heading}</h6>

      <div className="col-6 col-md-12 position-relative">
        {area && (
          <span
            className="position-absolute user-select-none h-100 d-flex align-items-center w-100 px-2 text-muted"
            style={{
              pointerEvents: "none",
              left: unitPosition === "end" ? "75%" : "7%",
            }}
          >
            {area}
          </span>
        )}
        <input
          required
          type="number"
          className="input border my-1"
          value={newPostData[name]}
          onChange={(e) => addNewPostData({ name, value: e.target.value })}
          style={{
            borderRadius: 20,
            outline: "none",
            height: 30,
            fontSize: 16,
            paddingLeft: unitPosition === "start" ? 20 : 8,
            paddingRight: unitPosition === "end" ? 30 : 8,
          }}
        />
      </div>

      {newPostData[name].length === 0 && pageTwoClicked && (
        <FieldError errMsg={`enter ${error ? error : name}`} />
      )}
    </form>
  );
};

const mapStateToProps = (state) => ({
  newPostData: state.apartment.newPost,
});

const mapDispatchToProps = (dispatch) => ({
  addNewPostData: (obj) => dispatch(addNewPostData(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FloorInput);
