import React, { useState } from "react";
import "./contact-form.css";
import CustomInput from "../../../common/custom-input/custom-input";
import "flatpickr/dist/themes/material_green.css";
import firebase from "../../../../config/fbConfig";
import { FieldError } from "../../../common/field-error/field-error";
import {
  setUploadingLoader,
  setAuthModalConfig,
} from "../../../../store/actions/actions";
import { connect, useSelector } from "react-redux";
import swal from "sweetalert";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import RequestCallback from "./RequestCallback";

const ContactForm = ({ setUploadingLoader, setAuthModalConfig }) => {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState(new Date());
  const [addInfo, setAddInfo] = useState(
    "Hi, I'd like to schedule a visit to the rental property I found on openlist."
  );
  const [showRequestCallback, setShowRequestCallback] = useState(false); // State to control visibility of RequestCallback

  const user = useSelector((state) => state.user.user);

  const clickContactProperty = () => {
    setSubmitClicked(true);

    if (!name || !phone || !date || !addInfo) {
      return;
    }

    setUploadingLoader(true);
    firebase
      .firestore()
      .collection("callback")
      .add({
        name: name,
        number: phone,
        date: date,
        info: addInfo + " " + window.location,
        url: window.location + "",
      })
      .then(() => {
        setUploadingLoader(false);
        setName("");
        setPhone("");
        setDate(new Date());
        setSubmitClicked(false);

        swal("Thanks! Someone from our team will reach out you soon.", {
          icon: "success",
          closeOnClickOutside: true,
          button: false,
          timer: 2000,
        });
      });
  };

  return (
    <>
      <h5
        style={{
          color: "#1c5663",
        }}
        className="p-2"
      >
        Request Callback
      </h5>
      <button
        style={{
          marginTop: "-10px",
          border: "none",
          display: "flex",
          gap: 5,
          color: "#3CB6D3",
          backgroundColor: "transparent",
        }}
        onClick={(e) => {
          if (!user) {
            setAuthModalConfig({
              show: true,
              type: "Login",
            });
          } else {
            setShowRequestCallback(true); // Show RequestCallback component when the button is clicked
          }
        }}
      >
        <LocalPhoneOutlinedIcon style={{ fontSize: 19 }} />
        View Phone Number
      </button>
      {showRequestCallback && (
        <RequestCallback
          showRequestCallback={setShowRequestCallback}
          setUploadingLoader={setUploadingLoader}
        />
      )}
      <form className="p-2 pt-0 w-100 contact-form">
        <div className="form-group my-2">
          <CustomInput
            required
            type="text"
            label="Name*"
            onChangeHandler={(value) => setName(value)}
            value={name}
            isLessHeight
            extraClasses="location-input"
          />
          {submitClicked && name.length === 0 && (
            <FieldError errMsg="Please enter name" />
          )}
        </div>
        <div className="form-group my-2">
          <CustomInput
            required
            type="text"
            label="Number*"
            isMobNum
            onChangeHandler={(value) => {
              if (value === "" || /^[0-9\b]+$/.test(value)) {
                setPhone(value);
              }
            }}
            value={phone}
            isLessHeight
            extraClasses=" location-input"
          />
          {submitClicked && phone.length === 0 && (
            <FieldError errMsg="Please enter number" />
          )}
        </div>
        <div className="form-group my-2">
          <textarea
            type="email"
            className="border px-2 location-input bg-white"
            name="desc"
            placeholder="Additional Information*"
            defaultValue={addInfo}
            style={{
              minHeight: 120,
              fontFamily: "proxima_nova_rgregular",
            }}
            onChange={(e) => setAddInfo(e.target.value)}
          />
        </div>
        <div
          onClick={clickContactProperty}
          type="submit"
          className="text-capitalize text-white my-2 w-100 btn-clear text-center py-2 bg-cprimary"
          style={{
            borderRadius: 20,
          }}
        >
          Request a Callback
        </div>
      </form>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setUploadingLoader: (val) => dispatch(setUploadingLoader(val)),
  setAuthModalConfig: (val) => dispatch(setAuthModalConfig(val)),
});

export default connect(null, mapDispatchToProps)(ContactForm);
