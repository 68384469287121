import { useSelector } from "react-redux";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useState, useEffect } from "react";
import { CommunityPostCards } from "../../../community/components/community-post-cards/community-post-cards";

const FavProperties = () => {
  const { posts } = useSelector((state) => state.posts);
  const { user } = useSelector((state) => state.user);
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    if (user && posts) {
      const myFavorites = [];
      posts.forEach((post) => {
        // const userLikes = post.likes.filter((l) => l === user.uid);
        if (post.likes.includes(user.uid)) {
          myFavorites.push(post);
        }
      });

      setFavorites(myFavorites?.reverse());
    }
  }, [posts]);
  console.log("favorite", favorites);
  return (
    <div className="container h-100 w-100 py-4">
      <div className="fc-cprimary border-bottom pb-2 d-flex align-items-center">
        <FavoriteBorderIcon
          style={{
            fontSize: 40,
          }}
        />

        <h2 className="mx-3 fc-1c pt-2">Favourites</h2>
      </div>
      {favorites.length === 0 ? (
        <h3
          style={{ fontFamily: "proxima_nova_rgregular", fontSize: "17px" }}
          className="text-center fc-1c mt-5 "
        >
          You have no favourites yet.
        </h3>
      ) : (
        <div>
          {favorites.map((post) => (
            <CommunityPostCards post={post} user={user} />
          ))}
        </div>
      )}

      {/* <Properties keyName="favorites" /> */}
    </div>
  );
};

export default FavProperties;
