import ApartmentIcon from "@mui/icons-material/Apartment";
import { connect } from "react-redux";
import { CustomButton } from "../../../common/custom-button/custom-button";
import firebase from "../../../../config/fbConfig";

const Requests = ({ callback }) => {
  const delDocFromDb = (id) => {
    if (id) {
      firebase.firestore().collection("callback").doc(id).delete();
    }
  };

  const handleContact = (item) => {
  
  };

  const openProperty = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="container h-100 w-100 py-4">
      <div className="fc-cprimary border-bottom pb-2 d-flex align-items-center">
        <ApartmentIcon
          style={{
            fontSize: 40,
          }}
        />

        <h2 className="mx-3 fc-1c pt-2">Requests</h2>
      </div>

      <table class="table">
        <tbody>
          {callback &&
            callback.map((item, index) => (
              <tr key={item.name}>
                <th scope="row">{index + 1}.</th>
                <td>{item.name}</td>
                <td>
                  <div
                    className="fc-1c c-pointer"
                    onClick={() => openProperty(item.url)}
                  >
                    Link
                  </div>
                </td>
                <td className="col-8 col-md-4">
                  <div className="row m-0 d-flex flex-wrap flex-row">
                    <div className="col-12 col-md-6">
                      <CustomButton
                        label="Contact"
                        onClickHandler={() => handleContact(item)}
                        isBg
                        bg="#3cd3a3"
                      />
                    </div>

                    <div className="col-12 col-md-6 my-2 my-md-0">
                      <CustomButton
                        label="Resolved"
                        onClickHandler={() => delDocFromDb(item.id)}
                        isBg
                        bg="#3cd3a3"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  callback: state.firestore.ordered.callback,
});

export default connect(mapStateToProps, null)(Requests);
