import "./property-filters.css";
import { useEffect, useState } from "react";
import { setFilterArray } from "../../../../store/actions/actions";
import { connect } from "react-redux";
import { MultiRangeSlider } from "../../../common/multi-range-slider/multi-range-slider";
import CustomInputTypeform from "../../../common/custom-input-typeform/custom-input-typeform";
import firebase from "../../../../config/fbConfig";
import { FaRegBuilding } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { VscDebugRestart } from "react-icons/vsc";
import { useMediaQuery } from "react-responsive";
import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { Cancel, Tune } from "@material-ui/icons";
import { Checkbox } from "@material-ui/core";

const APARTMENT_TYPES = ["0", "1", "2", "3", "4+"];
const PROPERTY_TYPES = ["Apartments", "All Condos", "All Houses", "Rooms"];
const FURNISHING_TYPES = ["Furnished", "Semi-furnished", "Un-furnished"];
const IDEAL_FOR_TYPES = ["Family", "Bachelors", "Couple"];
const CATEGORY_TYPES = ["Posted by Owner", "Posted by Agent"];
const POPULAR_FILTERS = ["Pets", "3D/Video Tour", "Furnished", "Short-Term"];
const AMENITIES = [
  "A/C",
  "Balcony",
  "Dishwasher",
  "Fireplace",
  "Gym",
  "Pool",
  "Laundry Insuite",
  "Laundry Facilities",
  "Wheelchair Access",
];
const UTILITIES = [
  "Heating",
  "Water",
  "Hydro/Electricity",
  "Wifi",
  "Satellite TV",
  "Cable TV",
];
const FLOORS = ["Carpet", "Ceremic", "Hardwood"];

const CATEGORIES = [
  "Corporate Housing",
  "Student Housing",
  "Senior Housing",
  "Co-op Housing",
  "Sublet",
  "Vacation",
  "Luxury",
];

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  fontFamily: "proxima_nova_rgregular",
  fontSize: "15px",
  position: "relative",
  zIndex: 999,
  color: "black",
  backgroundColor: "#eaeeee",
  padding: "7px 12px",
  "&:hover": {
    backgroundColor: "gray",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "gray",
    boxShadow: "none",
  },
  "&:focus": {
    backgroundColor: "gray",
    boxShadow: "none",
  },
}));

const MobileFilters = (props) => {
  const {
    setFilterArray,
    filterArray,
    selectedFurnishingTypes,
    setSelectedFurnishingTypes,
    selectedPropertyTypes,
    setSelectedPropertyTypes,
    selectedApartmentTypes,
    setSelectedApartmentTypes,
    setMinRentPrice,
    setMaxRentPrice,
    resetAllFilters,
    selectedLooking,
    setSelectedLooking,
    selectedCat,
    setSelectedCat,
    societyFilter,
    setSocietyFilter,
    showAllFilters,
    filteredFlats,
    setShowAllFilters,
    setCurrentView,
    minRentPrice,
    maxRentPrice,
  } = props;


  const [normalFilter, setNormalFilter] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [tempInp, setTempInp] = useState("");
  const [alignment, setAlignment] = useState("normalFilter");
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1800px)" });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openMenu, setOpenMenu] = useState(null);
  const handleClick = (event, menu) => {
    if (openMenu === menu) {
      handleClose(); // Close the menu if it's already open
    } else {
      setAnchorEl(event.currentTarget);
      setOpenMenu(menu);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
  };

  const isSuggContains = (arr, val) => arr.find((item) => item.name === val);

  useEffect(() => {
    firebase
      .firestore()
      .collection("city")
      .doc("SSX77pJNAU5wvOIwECnW")
      .collection("locality")
      .doc("yqnMZNXH2IRG8TVxw694")
      .collection("societies")
      .onSnapshot((d) => {
        if (d.size) {
          setSuggestions(
            d.docs.map((d) => ({
              name: d.data().name,
              id: d.id,
            }))
          );
        }
      });
  }, []);

  const isTypeSelected = (arr, selectedType) =>
    arr.find((type) => type === selectedType);

  const handleChange = (event, filter) => {
    setAlignment(filter);
    if (filter === "normalFilter") setNormalFilter(true);
    else setNormalFilter(false);
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        overflowY: "auto",
        height: "100vh",
        scrollbarWidth: "none",
        position: "relative",
      }}
    >
      <div
        style={{
          background: "#f6f8f9",
          position: "fixed",
          width: "34.8%",
          zIndex: 999,
          height: "92px",
          color: "#1c5663",
          display: showAllFilters ? "flex" : "none",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 12px",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            fontSize: "2.1rem",
          }}
        >
          <Tune style={{ fontSize: "2.1rem" }} />{" "}
          <span
            style={{
              marginLeft: "12px",
              fontSize: "2.1rem",
              fontFamily: "proxima_nova_rgregular",
              fontWeight: "500",
            }}
          >
            {" "}
            Filters
          </span>
        </div>
        <div>
          <Cancel
            style={{ fontSize: "2.1rem" }}
            onClick={() => setShowAllFilters(false)}
          />
        </div>
      </div>
      <div className="d-flex flex-column justify-content-between align-items-center p-1">
        <button
          type="button"
          style={{ fontSize: "14px", fontFamily: "proxima_nova_rgregular" }}
          className=" ms-auto user-select-none clean-button fc-1c"
          onClick={() => {
            setFilterArray([]);

            resetAllFilters();

            setNormalFilter(true);

            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          <VscDebugRestart style={{ marginRight: "3px" }} />
          <span>Reset</span>
        </button>
      </div>

      {normalFilter ? (
        <>
          <div
            style={{
              paddingLeft: 12,
              paddingRight: 12,
              marginTop: showAllFilters ? "6rem" : 0,
              marginBottom: "7.3rem",
            }}
          >
            <div className={`my-3`}>
              <p
                className=" mb-4"
                style={{
                  fontFamily: "proxima_nova_rgbold",
                  fontSize: showAllFilters ? "17px" : "14px",
                  color: "#21566b",
                }}
              >
                Property Type
              </p>

              <div
                className={`d-grid ${
                  isMobileScreen ? "mobile-grid" : "desktop-grid"
                }`}
                style={{
                  gridTemplateColumns: isMobileScreen
                    ? "repeat(2, 1fr)"
                    : "repeat(3, 1fr)", // Set 3 items per row
                  gap: "20px", // Adjust the gap between items as needed
                }}
              >
                {PROPERTY_TYPES.map((propertyType) => (
                  <span
                    key={propertyType}
                    className="user-select-none text-center c-pointer border px-4 py-2"
                    style={{
                      fontFamily: "proxima_nova_rgregular",
                      borderRadius: "20px",
                      cursor: "pointer",
                      fontSize: showAllFilters ? "16px" : "14px",
                      background:
                        selectedPropertyTypes === propertyType
                          ? "#3cb6d3"
                          : "white",
                      color:
                        selectedPropertyTypes === propertyType
                          ? "#fff"
                          : "black",
                    }}
                    onClick={() => {
                      if (selectedPropertyTypes === propertyType) {
                        setSelectedPropertyTypes("");
                      } else {
                        setSelectedPropertyTypes(propertyType);
                      }
                    }}
                  >
                    {propertyType === "Apartment" ? (
                      <FaRegBuilding style={{ marginRight: "6px" }} />
                    ) : (
                      <IoHome style={{ marginRight: "6px" }} />
                    )}
                    {propertyType}
                  </span>
                ))}
              </div>
            </div>

            <div className="my-5">
              <div className="d-flex align-items-center">
                <p
                  className="m-0 me-3"
                  style={{
                    fontFamily: "proxima_nova_rgbold",
                    fontSize: showAllFilters ? "17px" : "14px",
                    color: "#21566b",
                    width: isMobileScreen ? "40px" : "120px",
                  }}
                >
                  Rent Price
                </p>

                <div
                  className="d-flex flex-grow-1 align-items-center"
                  style={{ marginLeft: "5px" }}
                >
                  <div className="me-2">
                    <div
                      className="price-box"
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        width: "65px",
                        height: "40px",
                        display: "flex", // Enable Flexbox
                        alignItems: "center", // Center vertically
                        justifyContent: "center", // Center horizontally
                        fontFamily: "proxima_nova_rgregular", // Optional: set a font family
                      }}
                    >
                      ${minRentPrice}
                    </div>
                  </div>

                  <div
                    className="flex-grow-1"
                    style={{
                      marginLeft: isMobileScreen ? " 0px" : "20px",
                      marginRight: isMobileScreen ? "10px" : "30px",
                    }}
                  >
                    <div className="mb-2">
                      <MultiRangeSlider
                        min={0}
                        max={100000}
                        onChange={({ min, max }) => {
                          setMinRentPrice(min);
                          setMaxRentPrice(max);
                        }}
                        style={{ display: "block" }}
                      />
                    </div>
                  </div>

                  <div>
                    <div
                      className="price-box"
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        width: "65px",
                        height: "40px",
                        display: "flex", // Enable Flexbox
                        alignItems: "center", // Center vertically
                        justifyContent: "center", // Center horizontally
                        fontFamily: "proxima_nova_rgregular", // Optional: set a font family
                      }}
                    >
                      ${maxRentPrice}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-5">
              <div className="d-flex align-items-center">
                <p
                  className="m-0 me-3"
                  style={{
                    fontFamily: "proxima_nova_rgbold",
                    fontSize: showAllFilters ? "17px" : "14px",
                    color: "#21566b",
                    width: isMobileScreen ? "40px" : "120px",
                  }}
                >
                  Sqft
                </p>

                <div
                  className="d-flex flex-grow-1 align-items-center"
                  style={{ marginLeft: "5px" }}
                >
                  <div className="me-2">
                    <div
                      className="price-box"
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        width: "65px",
                        height: "40px",
                        display: "flex", // Enable Flexbox
                        alignItems: "center", // Center vertically
                        justifyContent: "center", // Center horizontally
                        fontFamily: "proxima_nova_rgregular", // Optional: set a font family
                      }}
                    >
                      0
                    </div>
                  </div>

                  <div
                    className="flex-grow-1"
                    style={{
                      marginLeft: isMobileScreen ? " 0px" : "20px",
                      marginRight: isMobileScreen ? "10px" : "30px",
                    }}
                  >
                    <div className="mb-2">
                      <MultiRangeSlider
                        min={0}
                        max={100000}
                        onChange={({ min, max }) => {
                          console.log("sqft onChange ");
                        }}
                        style={{ display: "block" }}
                      />
                    </div>
                  </div>

                  <div>
                    <div
                      className="price-box"
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        width: "65px",
                        height: "40px",
                        display: "flex", // Enable Flexbox
                        alignItems: "center", // Center vertically
                        justifyContent: "center", // Center horizontally
                        fontFamily: "proxima_nova_rgregular", // Optional: set a font family
                      }}
                    >
                      5000
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`my-5 ${
                showAllFilters ? "d-flex align-items-baseline gap-4" : ""
              }`}
            >
              <p
                className=" mb-2"
                style={{
                  fontFamily: "proxima_nova_rgbold",
                  fontSize: showAllFilters ? "17px" : "14px",
                  color: "#21566b",
                  width: "120px",
                }}
              >
                Bedrooms
              </p>

              <div className={`d-flex flex-wrap gap-3`} style={{}}>
                {APARTMENT_TYPES.map((apartmentType) => (
                  <span
                    key={apartmentType}
                    className="px-4 py-2 user-select-none border"
                    style={{
                      cursor: "pointer",
                      fontSize: showAllFilters ? "16px" : "14px",
                      borderRadius: 99,
                      background: isTypeSelected(
                        selectedApartmentTypes,
                        apartmentType
                      )
                        ? "#3cb6d3"
                        : "white",
                      color: isTypeSelected(
                        selectedApartmentTypes,
                        apartmentType
                      )
                        ? "#fff"
                        : "black",
                      fontFamily: "proxima_nova_rgregular",
                    }}
                    onClick={() => {
                      if (
                        isTypeSelected(selectedApartmentTypes, apartmentType)
                      ) {
                        setSelectedApartmentTypes((prev) =>
                          prev.filter((type) => type !== apartmentType)
                        );
                      } else {
                        setSelectedApartmentTypes((prev) => [
                          ...prev,
                          apartmentType,
                        ]);
                      }
                    }}
                  >
                    {apartmentType}
                  </span>
                ))}
              </div>
            </div>

            <div
              className={`my-5 ${
                showAllFilters ? "d-flex align-items-baseline gap-4" : ""
              }`}
            >
              <p
                className=" mb-2"
                style={{
                  fontFamily: "proxima_nova_rgbold",
                  fontSize: showAllFilters ? "17px" : "14px",
                  color: "#21566b",
                  width: "120px",
                }}
              >
                Bathrooms
              </p>

              <div className={`d-flex flex-wrap gap-3`} style={{}}>
                {APARTMENT_TYPES.map((apartmentType) => (
                  <span
                    key={apartmentType}
                    className="px-4 py-2 user-select-none border"
                    style={{
                      cursor: "pointer",
                      fontSize: showAllFilters ? "16px" : "14px",
                      borderRadius: 99,
                      background: isTypeSelected(
                        selectedApartmentTypes,
                        apartmentType
                      )
                        ? "#3cb6d3"
                        : "white",
                      color: isTypeSelected(
                        selectedApartmentTypes,
                        apartmentType
                      )
                        ? "#fff"
                        : "black",
                      fontFamily: "proxima_nova_rgregular",
                    }}
                    onClick={() => {}}
                  >
                    {apartmentType}
                  </span>
                ))}
              </div>
            </div>

            <div
              className={`my-5 ${
                showAllFilters ? "d-flex align-items-baseline gap-4" : ""
              }`}
            >
              <p
                className=" mb-2"
                style={{
                  fontFamily: "proxima_nova_rgbold",
                  fontSize: showAllFilters ? "17px" : "14px",
                  color: "#21566b",
                  width: "120px",
                }}
              >
                Parking Spots
              </p>

              <div className={`d-flex flex-wrap gap-3`} style={{}}>
                {APARTMENT_TYPES.map((apartmentType) => (
                  <span
                    key={apartmentType}
                    className="px-4 py-2 user-select-none border"
                    style={{
                      cursor: "pointer",
                      fontSize: showAllFilters ? "16px" : "14px",
                      borderRadius: 99,
                      background: isTypeSelected(
                        selectedApartmentTypes,
                        apartmentType
                      )
                        ? "#3cb6d3"
                        : "white",
                      color: isTypeSelected(
                        selectedApartmentTypes,
                        apartmentType
                      )
                        ? "#fff"
                        : "black",
                      fontFamily: "proxima_nova_rgregular",
                    }}
                    onClick={() => {}}
                  >
                    {apartmentType}
                  </span>
                ))}
              </div>
            </div>

            <div className={`my-3`}>
              <p
                className=" mb-4"
                style={{
                  fontFamily: "proxima_nova_rgbold",
                  fontSize: showAllFilters ? "17px" : "14px",
                  color: "#21566b",
                  fontWeight: "normal",
                }}
              >
                Popular Filters
              </p>

              <div
                className={`d-grid ${
                  isMobileScreen ? "mobile-grid" : "desktop-grid"
                }`}
                style={{
                  gridTemplateColumns: isMobileScreen
                    ? "repeat(2, 1fr)"
                    : "repeat(3, 1fr)", // Set 3 items per row
                  gap: "20px", // Adjust the gap between items as needed
                }}
              >
                {POPULAR_FILTERS.map((propertyType) => (
                  <span
                    key={propertyType}
                    className="user-select-none text-center c-pointer border px-3 py-2"
                    style={{
                      fontFamily: "proxima_nova_rgregular",
                      borderRadius: "20px",
                      cursor: "pointer",
                      fontSize: showAllFilters ? "16px" : "14px",
                      background:
                        selectedPropertyTypes === propertyType
                          ? "#3cb6d3"
                          : "white",
                      color:
                        selectedPropertyTypes === propertyType
                          ? "#fff"
                          : "black",
                    }}
                    onClick={() => {}}
                  >
                    {propertyType === "Apartment" ? (
                      <FaRegBuilding style={{ marginRight: "6px" }} />
                    ) : (
                      <IoHome style={{ marginRight: "6px" }} />
                    )}
                    {propertyType}
                  </span>
                ))}
              </div>
            </div>

            <div className={`my-5`}>
              <p
                className={`mb-2`}
                style={{
                  fontFamily: "proxima_nova_rgbold",
                  fontSize: showAllFilters ? "17px" : "14px",
                  color: "#21566b",
                }}
              >
                Features & Amenities
              </p>

              <div
                className={`d-grid ${showAllFilters ? "" : "mt-2"}`}
                style={{
                  gridTemplateColumns: "repeat(2, 1fr)", // Create two columns
                  gap: "10px", // Adjust the gap between items
                  marginLeft: "-11px",
                }}
              >
                {AMENITIES.map((propertyType) => (
                  <div
                    key={propertyType}
                    className="d-flex align-items-center"
                    style={{
                      fontFamily: "proxima_nova_rgregular",
                      fontSize: showAllFilters ? "17px" : "15px",
                      cursor: "pointer",
                    }}
                  >
                    <Checkbox />
                    <span
                      style={{
                        borderRadius: "20px",
                        background:
                          selectedFurnishingTypes === propertyType
                            ? "#3cb6d3"
                            : "white",
                        color:
                          selectedFurnishingTypes === propertyType
                            ? "#fff"
                            : "black",
                        fontFamily: "proxima_nova_rgregular",
                        fontSize: "16px",
                      }}
                    >
                      {propertyType}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className={`my-5`}>
              <p
                className={`mb-2`}
                style={{
                  fontFamily: "proxima_nova_rgbold",
                  fontSize: showAllFilters ? "17px" : "14px",
                  color: "#21566b",
                }}
              >
                Utilities Included
              </p>

              <div
                className={`d-grid ${showAllFilters ? "" : "mt-2"}`}
                style={{
                  gridTemplateColumns: "repeat(2, 1fr)", // Create two columns
                  gap: "10px", // Adjust the gap between items
                  marginLeft: "-11px",
                }}
              >
                {UTILITIES.map((propertyType) => (
                  <div
                    key={propertyType}
                    className="d-flex align-items-center"
                    style={{
                      fontFamily: "proxima_nova_rgregular",
                      fontSize: showAllFilters ? "16px" : "14px",
                      cursor: "pointer",
                    }}
                  >
                    <Checkbox />
                    <span
                      className=""
                      style={{
                        borderRadius: "20px",
                        background:
                          selectedFurnishingTypes === propertyType
                            ? "#3cb6d3"
                            : "white",
                        color:
                          selectedFurnishingTypes === propertyType
                            ? "#fff"
                            : "black",
                        fontFamily: "proxima_nova_rgregular",
                        fontSize: "16px",
                      }}
                    >
                      {propertyType}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className={`my-5`}>
              <p
                className={`mb-2`}
                style={{
                  fontFamily: "proxima_nova_rgbold",
                  fontSize: showAllFilters ? "17px" : "14px",
                  color: "#21566b",
                }}
              >
                Categories
              </p>

              <div
                className={`d-grid ${showAllFilters ? "" : "mt-2"}`}
                style={{
                  gridTemplateColumns: "repeat(2, 1fr)", // Create two columns
                  gap: "10px", // Adjust the gap between items
                  marginLeft: "-11px",
                }}
              >
                {CATEGORIES.map((propertyType) => (
                  <div
                    key={propertyType}
                    className="d-flex align-items-center"
                    style={{
                      fontFamily: "proxima_nova_rgregular",
                      fontSize: showAllFilters ? "16px" : "14px",
                      cursor: "pointer",
                    }}
                  >
                    <Checkbox />
                    <span
                      style={{
                        borderRadius: "20px",
                        background:
                          selectedFurnishingTypes === propertyType
                            ? "#3cb6d3"
                            : "white",
                        color:
                          selectedFurnishingTypes === propertyType
                            ? "#fff"
                            : "black",
                        fontFamily: "proxima_nova_rgregular",
                        fontSize: "16px",
                      }}
                    >
                      {propertyType}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className={`my-5`}>
              <p
                className={`mb-2`}
                style={{
                  fontFamily: "proxima_nova_rgbold",
                  fontSize: showAllFilters ? "17px" : "14px",
                  color: "#21566b",
                }}
              >
                Floors
              </p>

              <div
                className={`d-grid ${showAllFilters ? "" : "mt-2"}`}
                style={{
                  gridTemplateColumns: "repeat(2, 1fr)", // Create two columns
                  gap: "10px", // Adjust the gap between items
                  marginLeft: "-11px",
                }}
              >
                {FLOORS.map((propertyType) => (
                  <div
                    key={propertyType}
                    className="d-flex align-items-center"
                    style={{
                      fontFamily: "proxima_nova_rgregular",
                      fontSize: showAllFilters ? "16px" : "14px",
                      cursor: "pointer",
                    }}
                  >
                    <Checkbox />
                    <span
                      style={{
                        borderRadius: "20px",
                        background:
                          selectedFurnishingTypes === propertyType
                            ? "#3cb6d3"
                            : "white",
                        color:
                          selectedFurnishingTypes === propertyType
                            ? "#fff"
                            : "black",
                        fontFamily: "proxima_nova_rgregular",
                        fontSize: "16px",
                      }}
                    >
                      {propertyType}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            style={{
              background: "#f6f8f9",
              position: "fixed",
              width: showAllFilters ? "34.8%" : "100%",
              zIndex: 999,
              height: "92px",
              color: "#1c5663",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 12px",
              cursor: "pointer",
              bottom: 0,
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: " 32%",
                fontFamily: "proxima_nova_rgbold",
                textTransform: "none",
                fontSize: "17px",
                color: "black",
                backgroundColor: "#eaeeee",
                borderRadius: "20px",
                padding: showAllFilters ? "5px 0px" : "5px 2px ",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "#eaeeee",
                  boxShadow: "none",
                },
                "&:active": {
                  backgroundColor: "#eaeeee",
                  boxShadow: "none",
                },
                "&:focus": {
                  backgroundColor: "#eaeeee",
                  boxShadow: "none",
                },
              }}
              onClick={() => {
                setFilterArray([]);

                resetAllFilters();

                setNormalFilter(true);

                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            >
              {showAllFilters ? "Clear Filters" : "Clear"}
            </Button>
            <Button
              variant="contained"
              sx={{
                width: " 65%",
                fontFamily: "proxima_nova_rgbold",
                textTransform: "none",
                fontSize: "17px",
                borderRadius: "20px",
                padding: "5px 0px",
                boxShadow: "none",
                backgroundColor: "#3cb6d3",
                "&:hover": {
                  backgroundColor: "#3cb6d3",
                  boxShadow: "none",
                },
                "&:active": {
                  backgroundColor: "#3cb6d3",
                  boxShadow: "none",
                },
                "&:focus": {
                  backgroundColor: "#3cb6d3",
                  boxShadow: "none",
                },
              }}
              onClick={() => {
                showAllFilters
                  ? setShowAllFilters(false)
                  : setCurrentView("list");
              }}
            >
              View {filteredFlats?.length} Listings
            </Button>
          </div>
        </>
      ) : (
        <div>
          {" "}
          <div className="col-12 p-0 my-1 my-md-0 position-relative py-3">
            <CustomInputTypeform
              type="text"
              label="Society name"
              value={tempInp}
              onChangeHandler={(val) => {
                setTempInp(val);
                const isSugg = isSuggContains(suggestions, val);

                setSocietyFilter(isSugg ? val : "");
                setTempInp(isSugg ? "" : val);
              }}
              id="data"
              isOptions
              isLessHeight
            />

            <datalist
              id="data"
              style={{
                maxHeight: 500,
              }}
            >
              {suggestions.map((item) => (
                <option key={item.id} value={item.name} />
              ))}
            </datalist>
          </div>
          <div>
            {societyFilter && (
              <span
                className="text-muted py-2 px-3 my-2 bg-white"
                style={{
                  fontSize: 11,
                  borderRadius: 99,
                }}
              >
                {societyFilter}
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  filterArray: state.apartment.filterArray,
});

const mapDispatchToProps = (dispatch) => ({
  setFilterArray: (arr) => dispatch(setFilterArray(arr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileFilters);
