import { useEffect, useState } from "react";
import "./upload-images.css";
import DeleteIcon from "@material-ui/icons/Delete";
import uuid from "uuid/v1";
import CircularProgress from "@mui/material/CircularProgress";
import { storage } from "../../../config/fbConfig";
import imageCompression from "browser-image-compression";
import watermarkImage from "../../../assets/OpenList.png";

function UploadImages({
  images,
  setImages,
  isAllUploaded,
  isSocietyRoute,
  isCommunity,
  propertyImagesData,
}) {
  const [dragId, setDragId] = useState();
  const [dragModeOn, setDragMode] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const { city, locality } = propertyImagesData;

  useEffect(() => {
    if (isUploading) {
      uploadAllImages();
    }
    setUploading(false);
  }, [images]);

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
    setDragMode(true);
  };

  const handleDrop = (ev) => {
    const dragImage = images.find((image) => image.id === dragId);
    const dropImage = images.find((image) => image.id === ev.currentTarget.id);

    const dragImageOrder = dragImage.order;
    const dropImageOrder = dropImage.order;

    const newImagesState = images.map((image) => {
      if (image.id === dragId) {
        image.order = dropImageOrder;
      }
      if (image.id === ev.currentTarget.id) {
        image.order = dragImageOrder;
      }
      return image;
    });

    setImages(newImagesState);
    setDragMode(false);
  };

  const uploadImages = (e) => {
    if (e.target.files.length) {
      const inc = images ? images.length : 0;
      const temp = Array.from(e.target.files).map((item, i) => ({
        file: item,
        order: i + inc,
        id: uuid(),
        url: "",
        x: item.naturalWidth,
      }));

      setImages((prev) => [...new Set([...prev, ...temp])]);

      setUploading(true);
    }
  };

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const removeImage = (id) => {
    setImages((prev) => prev.filter((img) => img.id !== id));
  };

  const uploadAllImages = () => {
    Promise.all(images.map((item) => uploadImageAsPromise(item, item.url)))
      .then((url) => {})
      .catch((error) => {
        console.log(`Some failed: `, error.message);
      });
  };

  function uploadImageAsPromise(imageFile, url) {
    if (url) {
      return;
    }
    return new Promise(async function (resolve, reject) {
      let firestorageFolder =
        new Date().toLocaleString().substring(0, 8).replaceAll("/", "-") + "/";

      if (isSocietyRoute) {
        firestorageFolder = "common/";
      } else if (isCommunity) {
        firestorageFolder = "community/";
      } else if (propertyImagesData) {
        firestorageFolder = `properties/${city}/${locality}/`;
      }

      var storageRef = storage.ref(firestorageFolder + imageFile.file.name);

      const compressedFile = await imageCompression(imageFile.file, options);

      // Create a canvas to overlay the watermark
      const img = new Image();
      img.src = URL.createObjectURL(compressedFile);
      img.onload = async function () {
        const canvas = document.createElement("canvas");
        const scaleFactor = 0.8;
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        // Overlay watermark image
        const watermarkImg = new Image();
        watermarkImg.src = watermarkImage;
        watermarkImg.onload = function () {
          var task = storageRef.putString(
            canvas.toDataURL("image/jpeg"),
            "data_url"
          );

          task.on(
            "state_changed",
            function progress(snapshot) {},
            function error(err) {},
            async () => {
              const url = await storageRef.getDownloadURL();
              console.log(url);
              setImages((prev) => [
                ...prev.filter((item) => item.id !== imageFile.id),
                {
                  ...prev.filter((item) => item.id === imageFile.id)[0],
                  url: url,
                },
              ]);
            }
          );
        };
      };
    });
  }

  return (
    <>
      <div
        className="row"
        style={
          dragModeOn
            ? {
                border: "1px dashed #ff000050",
              }
            : {}
        }
      >
        {images
          .sort((a, b) => a.order - b.order)
          .map((image) => (
            <li
              className={`col-lg-${
                isCommunity ? "4" : "3"
              } col-md-12 py-3 rounded position-relative d-flex images-container transition-3s`}
              style={{
                height: isCommunity ? 150 : 200,
              }}
              id={image.id}
              draggable={true}
              key={image.id}
              onDragOver={(ev) => {
                ev.preventDefault();
              }}
              onDragStart={handleDrag}
              onDrop={handleDrop}
            >
              <div className="w-100 h-100 position-relative">
                <img
                  className="w-100 h-100 d-flex border rounded mb-4"
                  src={image.url ? image.url : URL.createObjectURL(image.file)}
                  alt={image.file.name}
                  style={{
                    objectFit: "cover",
                  }}
                />
                {image.url.length === 0 && !isAllUploaded() && (
                  <div
                    className="position-absolute w-100 h-100 left-0 bottom-0"
                    style={{
                      zIndex: 20,
                      backdropFilter: "blur(2px)",
                      background: "#00000005",
                    }}
                  >
                    <div
                      className="d-flex align-items-center flex-column justify-content-center"
                      style={{
                        marginTop: "20%",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  </div>
                )}
              </div>

              <div
                className="position-absolute rounded-circle p-2 transition-3s del-button"
                onClick={() => removeImage(image.id)}
              >
                <DeleteIcon />
              </div>
            </li>
          ))}
        <div
          className={`col-12 col-lg-${isCommunity ? "4" : "3"} col-md-12 p-3`}
        >
          <input
            type="file"
            className="bg-white w-100 w-100 position-relative post-property-img-input"
            accept="image/*"
            onChange={uploadImages}
            multiple
            disabled={!isAllUploaded()}
            style={{
              height: isCommunity ? 122 : 170,
              border: "2px dashed gray",
            }}
          />
        </div>
      </div>
    </>
  );
}

export default UploadImages;
