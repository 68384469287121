import React, { useState } from "react";
import { setAuthModalConfig } from "../../../../store/actions/actions";
import { connect, useSelector } from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";
import mixpanel from "mixpanel-browser";

export const RequestCallback = ({
  setAuthModalConfig,
  showRequestCallback,
  setUploadingLoader,
}) => {
  const user = useSelector((state) => state.user.user);
  console.log(user);
  const [email, setEmail] = useState(user ? user.email : "");
  const [fullname, setFullname] = useState(user ? user.fullname : "");
  const [phone, setPhone] = useState(user ? user.phoneNumber.slice(3) : "");
  const path = window.location.pathname;
  const pathSegments = path.split("/");
  const city = pathSegments[3];
  const locality = pathSegments[4];
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const [error, setError] = useState("");

  const handleClick = async () => {
    try {
      if (!id) {
        toast.error("ID not found, unable to request callback.");
        return;
      }

      let token = window.localStorage.getItem("accessToken");
      const res = await axios.post(
        "https://urbanletting-6afef.df.r.appspot.com/notify",
        {
          city: city,
          locality: locality,
          id: id,
          name: fullname,
          email: email,
          phone: phone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        toast.success("Please Check Your Email & SMS");
        setAuthModalConfig({
          show: false,
          type: "requestCallback",
        });
        setUploadingLoader(false);
        showRequestCallback(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 429) {
        toast.error("Request Limit exceeded for today");
        setAuthModalConfig({
          show: false,
          type: "requestCallback",
        });
        showRequestCallback(false);
      } else if (err.response.status === 401) {
        toast.error("Session timeout, Please login again!");
      } else toast.error("Some error occurred, try again!");
      setUploadingLoader(false);
    }
  };

  const handleClickLoader = () => {
    if (phone.length === 10) {
      setUploadingLoader(true);
      handleClick();
    } else {
      setError("phone");
    }
  };

  return (
    <div className="modalWrapper">
      <div className="loginModal">
        <div className="heading">
          <p
            style={{ fontWeight: 400, fontSize: 27, marginBottom: "4px" }}
            className="heading"
          >
            {" "}
            Fill out this one-time form
          </p>

          <span
            className="close_modal"
            onClick={() => {
              showRequestCallback(false);
            }}
          >
            X
          </span>
        </div>
        <h6
          style={{ fontSize: "12px", marginBottom: "14px", fontWeight: "100" }}
        >
          We’ll use your email and phone to share the owner’s details.
        </h6>
        <div className="position-relative">
          <label
            htmlFor="fullname"
            style={{
              fontSize: 11,
              marginLeft: 7,
              marginTop: 4,
              color: "gray",
              zIndex: 1,
            }}
            className="position-absolute"
          >
            Full Name
          </label>
          <input
            value={fullname}
            required
            id="fullname"
            style={{ paddingLeft: 11, paddingTop: 15 }}
            type="text"
            onFocus={() =>
              (document.querySelector('label[for="fullname"]').style.color =
                "#0078DB")
            }
            onBlur={() =>
              (document.querySelector('label[for="fullname"]').style.color =
                "gray")
            }
            onChange={(e) => setFullname(e.target.value)}
          />
        </div>
        <div className="position-relative">
          <label
            htmlFor="email"
            style={{
              fontSize: 11,
              marginLeft: 7,
              marginTop: 4,
              color: "gray",
              zIndex: 1,
            }}
            className="position-absolute"
          >
            Email Id
          </label>
          <input
            value={email}
            required
            id="email"
            style={{ paddingLeft: 11, paddingTop: 15 }}
            type="text"
            onFocus={() =>
              (document.querySelector('label[for="email"]').style.color =
                "#0078DB")
            }
            onBlur={() =>
              (document.querySelector('label[for="email"]').style.color =
                "gray")
            }
            onChange={(e) => {
              const value = e.target.value;
              const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailPattern.test(value)) {
                // Invalid email logic
                setError("email");
              } else {
                // Valid email logic
                setEmail(value);
                setError("");
              }
            }}
          />
          {error === "email" && (
            <div style={{ color: "red", fontSize: "12px", marginTop: "-10px" }}>
              Invalid email
            </div>
          )}
        </div>

        <div className="position-relative">
          <label
            htmlFor="phoneNumber"
            style={{
              fontSize: 11,
              marginLeft: 7,
              marginTop: 4,
              color: "gray",
              zIndex: 1,
            }}
            className="position-absolute"
          >
            Phone Number
          </label>
          <input
            required
            id="phoneNumber"
            style={{ paddingLeft: 38, paddingTop: 15 }}
            type="tel"
            onFocus={() =>
              (document.querySelector('label[for="phoneNumber"]').style.color =
                "#0078DB")
            }
            onBlur={() =>
              (document.querySelector('label[for="phoneNumber"]').style.color =
                "gray")
            }
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
          <span
            style={{
              zIndex: 1,
              left: "11px",
              top: "21px",
            }}
            className="position-absolute"
          >
            +91
          </span>
          {error === "phone" && (
            <div style={{ color: "red", fontSize: "12px", marginTop: "-10px" }}>
              Invalid Phone No.
            </div>
          )}
        </div>

        <p
          style={{ fontSize: "12px", lineHeight: "14px" }}
          className="heading2 mt-5"
        >
          I agree to OpenList{" "}
          <span className="text-decoration-underline">Terms of use</span>
        </p>

        <button
          style={{
            marginTop: "-25px",
            opacity:
              fullname &&
              fullname.length &&
              email &&
              email.length &&
              (phone && phone.length) > 0
                ? 0.9
                : 0.6,
            cursor: "pointer",
          }}
          onClick={
            fullname &&
            fullname.length &&
            email &&
            email.length &&
            phone &&
            phone.length > 0
              ? handleClickLoader
              : undefined
          }
        >
          Get Contact Details
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setAuthModalConfig: (val) => dispatch(setAuthModalConfig(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestCallback);
