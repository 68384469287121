import { useEffect } from "react";
import { useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { addNewPostData } from "../../../store/actions/actions";
import { connect } from "react-redux";

const OPTIONS = [
  {
    featureType: "unitFeatures",
    name: "Unit Features",
    innerOptions: [
      "AC",
      "TV",
      "Beds",
      "Wardrobe",
      "Geyser",
      "Sofa",
      "Washing Machine",
      "Stove",
      "Fridge",
      "Water Purifier",
      "Microwave",
      "Modular Kitchen",
      "Chimney",
      "Dinning Table",
      "Curtains",
      "Exhaust Fan",
    ],
  },
  {
    featureType: "societyFeatures",
    name: "Society Features",
    innerOptions: [
      "24h Security",
      "Maintenance Staff",
      "Gym",
      "Parking",
      "Elevator",
      "Swimming Pool",
      "Video Surveillance",
      "Gas Pipeline",
      "WiFi",
    ],
  },
];

const FeaturesInput = ({ addNewPostData, newPostData }) => {
  const [showOptions, setOptions] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState(newPostData.features);

  useEffect(() => {
    addNewPostData("features", selectedOptions);
  }, [selectedOptions, addNewPostData]);

  const addRemoveOptions = (opt, featureType) => {
    const newData = selectedOptions;
    console.log(selectedOptions);

    if (newData[featureType].find((op) => op === opt)) {
      newData[featureType] = newData[featureType].filter((op) => op !== opt);
    } else {
      newData[featureType] = [...newData[featureType], opt];
    }

    setSelectedOptions(newData);
    addNewPostData("features", newData);
    console.log(selectedOptions);
  };

  // const addRemoveSocietyOptions = (opt) => {
  //   const newData = selectedOptions;
  //   console.log(selectedOptions);

  //   if (newData["societyFeatures"].find((op) => op === opt)) {
  //     newData["societyFeatures"] = newData["societyFeatures"].filter(
  //       (op) => op !== opt
  //     );
  //   } else {
  //     newData["societyFeatures"] = [...newData["societyFeatures"], opt];
  //   }

  //   setSelectedOptions(newData);
  //   console.log(selectedOptions);
  // };

  return (
    <div className="col-12 col-md-10 bg-white">
      {showOptions === 0 && (
        <div>
          {OPTIONS.map((option, idx) => (
            <div
              key={option.name}
              onClick={() => setOptions(idx + 1)}
              className="border-bottom rounded p-2 m-2 my-4 c-pointer d-flex justify-content-between"
            >
              <h6 className="fc-3c m-0">{option.name}</h6>
              <h6 className="m-0 text-muted">
                {selectedOptions[option.featureType].length !== 0 ? (
                  <span>
                    {" "}
                    {selectedOptions[option.featureType].length} Selections{" "}
                  </span>
                ) : (
                  <span className="">None Selected</span>
                )}
              </h6>
            </div>
          ))}
        </div>
      )}
      {showOptions !== 0 && (
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between py-2 my-3 align-items-center border-bottom">
            <div onClick={() => setOptions(0)}>
              <ArrowBackIcon
                style={{
                  fontSize: 30,
                }}
              />
            </div>
            <h6>
              {selectedOptions[
                showOptions === 1 ? "unitFeatures" : "societyFeatures"
              ].length !== 0 ? (
                <>
                  <span className="text-weight-bold text-muted">
                    {
                      selectedOptions[
                        showOptions === 1 ? "unitFeatures" : "societyFeatures"
                      ].length
                    }{" "}
                    Selections
                  </span>
                </>
              ) : (
                <span className="text-muted">None Selected</span>
              )}
            </h6>
          </div>

          <div className="row">
            {OPTIONS[showOptions - 1] !== undefined &&
              OPTIONS[showOptions - 1].innerOptions.map((opt) => (
                <div key={opt} className={`col-8 col-md-3 px-4 m-auto m-md-0`}>
                  <h6
                    className={`fs-1c c-pointer text-center border rounded p-2 user-select-none ${
                      selectedOptions[
                        showOptions === 1 ? "unitFeatures" : "societyFeatures"
                      ].find((op) => op === opt)
                        ? "fc-1c font-weight-bold text-white bg-cprimary"
                        : "text-muted"
                    }`}
                    onClick={() =>
                      addRemoveOptions(
                        opt,
                        showOptions === 1 ? "unitFeatures" : "societyFeatures"
                      )
                    }
                  >
                    {opt}
                  </h6>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  newPostData: state.apartment.newPost,
});

const mapDispatchToProps = (dispatch) => ({
  addNewPostData: (obj) => dispatch(addNewPostData(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesInput);
