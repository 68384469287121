import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import Moment from "react-moment";
import { Swiper, SwiperSlide } from "swiper/react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./properties.css";
import firebase from "../../../config/fbConfig";
import SwiperCore, { Navigation, Pagination } from "swiper";
import linearLoaderPrimary from "./../../../assets/linear-loader-primary.svg";
import { Link } from "react-router-dom";

SwiperCore.use([Navigation, Pagination]);

const Properties = ({ user, userDoc, keyName }) => {
  const [data, setData] = useState(null);
  const [fetch, setFetch] = useState(false);

  const addOrRemoveFavorite = (e, docRef) => {
    if (!user) {
      //   props.setAuthModalConfig({ show: true, type: "Login" });
      return;
    }

    firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .get()
      .then((d) => {
        let favorites = d.data().favorites ? d.data().favorites : [];
        favorites = favorites.filter((fav) => fav !== docRef);

        d.ref
          .update({
            favorites,
          })
          .then(() => {});
      });
  };

  useEffect(() => {
    if (fetch && userDoc[keyName] !== undefined) {
      if (!userDoc[keyName] || userDoc[keyName].length === 0) {
        setData([]);
        return;
      }

      setData([]);

      userDoc[keyName].forEach((item) => {
        const splits = item.split("/");
        if (splits.length !== 3) {
          return;
        }

        const cityId = splits[0],
          localityId = splits[1],
          docId = splits[2];
        firebase
          .firestore()
          .collection("city")
          .doc(cityId)
          .collection("locality")
          .doc(localityId)
          .collection("estate")
          .doc(docId)
          .get()
          .then((d) => {
            if (!d.data()) {
              // setData((prev) => [...(prev ? prev : [])]);
              setData((prev) => prev || []);
              return;
            }
            setData((prev) => [
              ...new Set([
                ...(prev ? prev : []),
                { ...d.data(), id: d.id, docRef: item },
              ]),
            ]);
          });
      });
    }

    setFetch(false);
    // eslint-disable-next-line
  }, [fetch]);

  useEffect(() => {
    if (!keyName) {
      setData([]);
    }
    if (user !== null && userDoc && userDoc[keyName] !== undefined) {
      setFetch(true);
    } else if (userDoc && !userDoc[keyName]) {
      setData([]);
    }
    // eslint-disable-next-line
  }, [user, userDoc]);

  return (
    <div
      className="container h-100 w-100 py-4 px-0"
      style={{
        minHeight: "100vh",
      }}
    >
      <div className="row">
        {data === null ? (
          <div className="d-flex">
            <img
              src={linearLoaderPrimary}
              alt="svg loader"
              className="mx-auto"
            />
          </div>
        ) : data.length !== 0 ? (
          data.map((item) => {
            return (
              <div
                key={item.id}
                className="col-12 col-md-5 my-3 position-relative"
              >
                <div
                  className="bg-white apartment-card border rounded"
                  style={{
                    minHeight: 340,
                    height: 340,
                  }}
                >
                  <div className="image position-relative overflow-hidden rounded">
                    <div
                      className="position-absolute left-0 w-100 h-100"
                      style={{
                        zIndex: 10,
                        pointerEvents: "none",
                      }}
                    >
                      <button
                        className={`clean-button position-absolute c-pointer${
                          keyName === "listings"
                            ? ""
                            : " heart-animation active"
                        }`}
                        onClick={(e) => {
                          addOrRemoveFavorite(e, item.docRef);
                        }}
                        style={{
                          pointerEvents: "all",
                        }}
                      ></button>
                    </div>
                    <Swiper
                      navigation={true}
                      pagination={{
                        type: "fraction",
                      }}
                      style={{
                        border: "none",
                        height: 200,
                      }}
                      grabCursor={true}
                      slidesPerView={1}
                      className="mySwiper p-0 bg-white"
                    >
                      {item.images &&
                        item.images.map((img) => (
                          <SwiperSlide key={img}>
                            <div className="swiper-slide-img">
                              <img
                                width="100%"
                                height="100%"
                                src={img}
                                className="img-fluid"
                                alt="apartment"
                                loading="lazy"
                              />
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                  <div className="p-2 pb-0 bg-white rounded">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <h4 className="fc-cprimary m-0 mt-2 col-8">
                          ₹ {item.cost.toLocaleString()}
                        </h4>
                        {!item.remark && (
                          <Link
                            className="fc-1c bg-success py-1 col text-white text-center rounded"
                            to={`/properties/${item.localeId}/property/${item.id}/`}
                          >
                            Details
                          </Link>
                        )}
                      </div>
                      {!item.remark && (
                        <div className="d-flex align-items-center my-2">
                          <h5 className="fc-1c m-0">{item.propertyType}</h5>
                        </div>
                      )}
                      <h6 className="m-0 text-muted fc-1c">
                        {item.societyName} {item.localeName}, {item.city}
                      </h6>
                      {!item.remark && (
                        <div className="d-flex my-2 align-items-center">
                          <span className="text-muted">
                            <AccessTimeIcon />
                          </span>
                          <h6 className="fc-1c m-0 mx-1 mt-1 text-muted">
                            <Moment fromNow>{item.postedOn.toDate()}</Moment>
                          </h6>
                        </div>
                      )}
                      {item.remark && (
                        <p
                          className="m-0 mt-1 text-danger border-top trunc-nline"
                          style={{
                            fontSize: 13,
                            height: 75,
                            "--lines": 4,
                          }}
                        >
                          {item.remark}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="mt-4 pt-4 text-center fc-1c">No properties found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  userDoc: state.firestore.ordered.userDoc
    ? state.firestore.ordered.userDoc[0]
    : {},
});

export default connect(mapStateToProps, null)(Properties);
