import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import MessageItem from "../message-item/message-item";
import linearLoaderPrimary from "../../../../assets/linear-loader-primary.svg";
import { setAllChats } from "../../../../store/reducers/chatsReducer";
import { Box } from "@mui/material";

const Messages = ({ user, userDoc, messages, showChatBox }) => {
  const dispatch = useDispatch();
  // const [chats, setChats] = useState(null);

  useEffect(() => {
    if (messages === null) {
      setAllChats(null);
      return;
    }

    if (messages.length > 0) {
      // const mappedChats = messages.map((message) => ({ ...message }));
      // setChats(mappedChats);
      dispatch(setAllChats(messages.map((message) => ({ ...message }))));
      console.log(messages);
    } else {
      setAllChats([]);
    }
  }, [dispatch, messages]);

  const chats = useSelector((state) => state.chats?.chats);
  console.log(chats);

  return (
    <Box
      className="position-fixed p-0 container w-100"
      sx={{
        zIndex: 150,
        pointerEvents: "none",
        top: 84,
        marginLeft: { xs: 3, sm: 2 },
      }}
    >
      <div className="row">
        <Box
          sx={{ minHeight: { xs: "100vh", sm: "47vh" } }}
          className="col-12 col-md-3 h-100 ms-auto me-auto me-md-0 p-0 bg-white b-shadow border rounded-3"
          style={{}}
        >
          {/* chat list side bar */}
          <div className="w-100">
            {chats === null ? (
              <div className="d-flex">
                <img
                  src={linearLoaderPrimary}
                  alt="svg loader"
                  className="mx-auto"
                />
              </div>
            ) : (
              <div
                className="d-flex flex-column"
                style={{
                  pointerEvents: "auto",
                }}
              >
                {chats && chats.length > 0 ? (
                  chats.map((chat) => (
                    <>
                      <MessageItem
                        key={chat.id}
                        lastMessage={chat.lastMessage}
                        {...chat}
                        userDoc={userDoc}
                        user={user}
                      />
                    </>
                  ))
                ) : (
                  <div style={{ color: "black" }}>
                    <span className="p-3">No chats</span>
                  </div>
                )}
              </div>
            )}
          </div>
        </Box>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,

  userDoc: state.firestore.ordered.userDoc
    ? state.firestore.ordered.userDoc[0]
    : null,

  messages: state.firestore.ordered.messages
    ? state.firestore.ordered.messages
    : null,

  showChatBox: state.community.showChatBox,
});

export default connect(mapStateToProps, null)(Messages);
