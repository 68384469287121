import "./custom-input.css";

import { connect } from "react-redux";

import { addNewPostData } from "../../../store/actions/actions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const CustomInput = ({
  label,
  type,
  name,
  isNewPostField,
  addNewPostData,
  newPostData,
  isDisabled,
  value,
  isMobNum,
  onChangeHandler,
  id,
  isOptions,
  isLessHeight,
  extraClasses,
}) => {
  return (
    <div
      className={`custom-input-div bg-white b-shadow rounded border d-flex flex-column${
        extraClasses ? extraClasses : ""
      }`}
      style={{
        paddingTop: isLessHeight ? 14 : 25,
      }}
    >
      <input
        required
        type={type}
        maxLength={isMobNum ? 10 : 1000}
        minLength={isMobNum ? 10 : 1}
        disabled={isDisabled}
        list={id ? id : null}
        className="input px-2 m-0 py-3"
        style={
          isDisabled
            ? {
                cursor: "no-drop",
                height: 25,
                fontFamily: "proxima_nova_rgregular",
              }
            : {
                height: 25,
                fontFamily: "proxima_nova_rgregular",
              }
        }
        value={isNewPostField ? newPostData[name] : value}
        onChange={(e) =>
          isNewPostField
            ? addNewPostData({ name, value: e.target.value })
            : onChangeHandler(e.target.value)
        }
      />
      <label
        className="position-absolute d-flex justify-content-between w-100"
        style={
          (newPostData[name] && newPostData[name].length > 0) || value
            ? { transform: "translateY(-14px)" }
            : {}
        }
      >
        <span className="mx-2">{label}</span>
        {isOptions && (
          <span className="mx-2">
            <KeyboardArrowDownIcon />
          </span>
        )}
      </label>
      <button
        type="submit"
        style={{
          display: "none",
        }}
        onClick={(e) => e.preventDefault()}
      ></button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  newPostData: state.apartment.newPost,
});

const mapDispatchToProps = (dispatch) => ({
  addNewPostData: (obj) => dispatch(addNewPostData(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomInput);
