const initState = {
  showChatList: false,
  showChatBox: false,
  currentFriendID: null,
  currentFriendObj: null,
};

const communityReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_SHOW_CHAT_LIST":
      return {
        ...state,
        showChatList: action.payload,
      };

    case "SET_SHOW_CHAT_BOX":
      return {
        ...state,
        showChatBox: action.payload,
      };

    case "SET_CURR_FRIEND_ID":
      return {
        ...state,
        currentFriendID: action.payload,
      };
    case "SET_CURR_FRIEND_OBJ":
      return {
        ...state,
        currentFriendObj: action.payload,
      };

    default:
      return state;
  }
};

export default communityReducer;
