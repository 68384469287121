import "./community-post-cards.css";
import { Box, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import firebase from "../../../../config/fbConfig";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Moment from "react-moment";
import swal from "sweetalert";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ModalWrapper from "../../../common/modal-wrapper/modal-wrapper";
import ImagePost from "./ImagePost";
import { MdOutlineMessage } from "react-icons/md";
import fbLike from "../../../../assets/fb-like.svg";
import { Divider } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch } from "react-redux";
import { setAuthModalConfig } from "../../../../store/actions/actions";
import { useRef } from "react";
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";
import { CommunityPostCardsPopUp } from "./postPopup";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";
import { FaRegComment } from "react-icons/fa6";

SwiperCore.use([Navigation, Pagination]);

export const CommunityPostCards = ({ post, user }) => {
  const { desc, images, name, likes, comments, id, uid, timestamp } = post;
  const [isLiked, setIsLiked] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const [showBigPic, setBigPic] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [msg, setMsg] = useState("");
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);
  const [description, setDescription] = useState(desc?.substring(0, 430));
  const [comment, setComment] = useState("");
  const [showAllComments, setShowAllComments] = useState(false);
  const [userData, setUserData] = useState();
  const [sortedComments, setSortedComments] = useState([]);
  const [showPostPopup, setShowPostPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSortedComments(comments?.reverse());
  }, [showComment, user, showModal, loading, comments?.length]);

  useEffect(() => {
    if (user) {
      setIsLiked(likes.find((like) => like === user.uid));
    }
  }, [user, likes]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef?.current && !modalRef?.current?.contains(event.target)) {
        setShowModal(false);
      }
    };

    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const likeOrRemoveLike = () => {
    if (user) {
      let likesTemp = likes;
      if (isLiked) {
        likesTemp = likes.filter((like) => like !== user.uid);
      } else {
        likesTemp = [...likesTemp, user.uid];
      }

      firebase.firestore().collection("posts").doc(id).update({
        likes: likesTemp,
      });
    } else {
      dispatch(setAuthModalConfig({ show: true, type: "Login" }));
    }
  };

  const handleCommentClick = () => {
    if (user) {
      setShowPostPopup(true);
    } else {
      dispatch(setAuthModalConfig({ show: true, type: "Login" }));
    }
  };

  const handleMessageClick = () => {
    if (user) {
      handleOpen();
    } else {
      dispatch(setAuthModalConfig({ show: true, type: "Login" }));
    }
  };

  const saveMessage = (e) => {
    if (user) {
      if (!msg) return;

      const docID = [user.uid, uid].sort().join("-");
      const obj = {
        content: msg,
        idFrom: user.uid,
        idTo: uid,
        timestamp: new Date(),
      };

      firebase
        .firestore()
        .collection("messages")
        .doc(docID)
        .collection(docID)
        .add(obj)
        .then(() => {
          firebase
            .firestore()
            .collection("messages")
            .doc(docID)
            .set({
              lastMessage: obj,
              users: [user.uid, uid],
              read: false,
              idTo: uid,
            })
            .then(() => {
              setMsg("");

              swal("Message successfully sent!", {
                icon: "success",
                closeOnClickOutside: true,
                button: false,
                timer: 1500,
              });
              handleClose();
            });
        });
    }
  };

  const handleSubmitComment = (comment) => {
    if (user) {
      const commentData = {
        userId: user.uid,
        userName: user.fullname,
        userAvatar: user.avatar,
        text: comment,
        timestamp: new Date(),
      };
      sortedComments?.unshift(commentData);
      setLoading(true);
      firebase
        .firestore()
        .collection("posts")
        .doc(id)
        .update({
          comments: firebase.firestore.FieldValue.arrayUnion(commentData),
        })
        .then(() => {
          console.log("comment done");
          if (!showPostPopup) setShowComment(false);
          setComment("");
          if (user?.uid === post?.uid) return;
          const notificationData = {
            userId: user?.uid,
            userName: user.fullname,
            userAvatar: user.avatar,
            postId: id,
            text: `${user.fullname} commented on your post`,
            isSeen: false,
            timestamp: new Date(),
          };
          firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .collection("notifications")
            .add(notificationData)
            .then(() => console.log("Added notification"))
            .catch(() => console.log("error in notification sending"));
        })
        .catch((error) => console.log("error in comment->", error));
    }
    sortedComments?.shift();
    setLoading(false);
  };

  const handleDeletePost = () => {
    user &&
      firebase
        .firestore()
        .collection("posts")
        .doc(id)
        .delete()
        .then(() => {
          toast.success("Post Deleted");
          setShowModal(false);
          console.log("post deleted");
        })
        .catch((error) => console.log("error in delete->", error));
  };
  const setPopUpFalse = () => {
    setShowPostPopup(false);
  };

  return (
    <>
      {!showPostPopup ? (
        <Box
          sx={{
            borderRadius: { xs: 0, sm: "10px" },
            borderTop: "2px solid #EFEFEF",
            paddingTop: "10px",
            marginTop: "20px",
            backgroundColor: "white",
          }}
        >
          <ModalWrapper open={open} handleClose={handleClose}>
            <div className="bg-white rounded row m-0 p-2 py-3">
              <div>
                <p className="m-0 border-bottom pb-1 text-center">
                  Message {name}
                </p>

                <div className="my-2 pb-2">
                  {/* <div className="d-flex flex-wrap flex-gap-col">
                    {MESSAGE_SUGG.map((sugg) => (
                      <p
                        onClick={() => setMsg(sugg)}
                        className="m-0 my-2 py-1 px-2 border c-pointer"
                        key={sugg}
                        style={{
                          background: "#3cb6d308",
                          borderRadius: 99,
                          fontSize: 12,
                        }}
                      >
                        {sugg}
                      </p>
                    ))}
                  </div> */}
                  <textarea
                    className="rounded filter-sidebar border p-2 post-input transition-3s"
                    placeholder="Please type your message here..."
                    style={{
                      fontFamily: "proxima_nova_rgregular",
                      border: "1px solid #00000040",
                      resize: "none",
                      height: 100,
                    }}
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                  ></textarea>
                </div>

                <button
                  style={{
                    backgroundColor: "#3cb6d3",
                    width: "100%",
                    border: "none",
                    padding: "8px",
                    borderRadius: "4px",
                    color: "white",
                    cursor: "pointer",
                    fontFamily: "proxima_nova_rgregular",
                    fontSize: 16,
                  }}
                  onClick={saveMessage}
                >
                  Send Message
                </button>
                {/* <CustomButton
                    label="Send"
                    onClickHandler={saveMessage}
                    fontColor="#3cd3a3"
                    bg="#3cb6d3"
                  /> */}
              </div>
            </div>
          </ModalWrapper>
          <div
            className="d-flex align-items-center p-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ display: "flex" }}>
              <Avatar alt={name} src="none" />

              <div className="mx-2">
                <p
                  className="my-0"
                  style={{
                    color: "black",
                    fontFamily: "proxima_nova_rgregular",
                  }}
                >
                  {name}
                </p>
                <div
                  style={{
                    fontSize: 14,
                  }}
                  className="fc-1c text-muted"
                >
                  <Moment
                    fromNow
                    style={{ fontFamily: "proxima_nova_rgregular" }}
                  >
                    {timestamp?.toDate()}
                  </Moment>
                </div>
              </div>
            </div>
            <div ref={modalRef} className="position-relative">
              <div onClick={() => setShowModal((prev) => !prev)}>
                <MoreVertIcon style={{ color: "black", cursor: "pointer" }} />
              </div>
              {showModal && user.uid === uid && (
                <div
                  style={{
                    zIndex: 50,
                    right: 18,
                    top: 0,
                    width: "200px",
                    borderRadius: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  className="text-black p-1 shadow-sm position-absolute shadow-lg"
                >
                  <div className="popUpModalList" onClick={handleDeletePost}>
                    <span style={{ fontSize: "20px", marginRight: "6px" }}>
                      <MdDelete />
                    </span>{" "}
                    Delete Post
                  </div>
                </div>
              )}
            </div>
          </div>

          {desc && (
            <div
              className="p-2 m-0 fc-1c border-bottom ps-3"
              style={{
                fontFamily: "proxima_nova_rgregular",
                fontSize: 15,
                color: "black",
                whiteSpace: "pre-line",
              }}
            >
              {desc?.length > 430 ? (
                <div
                  style={{
                    fontFamily: "proxima_nova_rgregular",
                    fontSize: 15,
                    color: "black",
                  }}
                >
                  {description}
                  <span
                    style={{
                      color: "#0266d2",
                      cursor: "pointer",
                      fontFamily: "proxima_nova_rgregular",
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      description?.length > 430
                        ? setDescription(desc?.substring(0, 430))
                        : setDescription(desc?.substring(0, desc?.length - 1));
                    }}
                  >
                    ...Read {description?.length > 430 ? "less" : "more"}
                  </span>
                </div>
              ) : (
                desc
              )}
            </div>
          )}

          <ImagePost images={images} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingLeft: 10,
            }}
          >
            <div style={{ display: "flex" }}>
              <img
                src={fbLike}
                style={{ width: 17 }}
                alt="like-icon"
                srcset=""
              />
              <span
                style={{
                  minWidth: 10,
                  color: "gray",
                  marginLeft: "5px",
                  fontFamily: "proxima_nova_rgregular",
                }}
                className=""
              >
                {likes?.length > 0 ? likes?.length : ""}
              </span>
            </div>
            {/* <div>
            <MdModeComment color="gray" />
            <span
              style={{
                minWidth: 10,
                color: "gray",
              }}
              className="py-auto pt-1 mx-2"
            >
              {likes.length > 0 ? likes.length : ""}
            </span>
           
          </div> */}
            <Typography
              sx={{
                color: "gray",
                marginRight: "10px",
                fontFamily: "proxima_nova_rgregular",
                cursor: "pointer",
                ":hover": {
                  color: "black",
                  transition: "all",
                },
              }}
              onClick={handleCommentClick}
            >
              {" "}
              {comments?.length ? comments?.length : "No"} comment
            </Typography>
          </div>
          <Divider
            style={{
              color: "gray",
              marginTop: 10,
              marginLeft: 10,
              marginRight: 10,
            }}
          />
          <div className="row d-flex py-3 m-0">
            <div
              className={`col-3 d-flex c-pointer aligns-items-center justify-content-center user-select-none ${
                !isLiked ? "fc-1c" : "fc-cprimary"
              }`}
              onClick={likeOrRemoveLike}
            >
              <div
                className={`rounded p-1 d-flex align-items-center c-pointer user-select-none ${
                  isMobileScreen ? "gap-1" : "gap-2"
                }`}
              >
                {isLiked ? (
                  <AiFillLike style={{ color: "#0573e6" }} />
                ) : (
                  <AiOutlineLike style={{ color: "black" }} />
                )}
                {/* <LuThumbsUp color={isLiked ? "blue" : "black"} /> */}
                <span
                  style={{
                    fontWeight: 500,
                    fontFamily: "proxima_nova_rgregular",
                    color: isLiked ? "#0573e6" : "black",
                    marginTop: "1px",
                  }}
                >
                  Like
                </span>
              </div>
            </div>

            <div
              onClick={handleCommentClick}
              className={`col-6 d-flex border-start c-pointer justify-content-center user-select-none fc-1c mt-1 ${
                isMobileScreen ? "" : "gap-2"
              }`}
            >
              <FaRegComment style={{ color: "black", marginTop: 3 }} />
              <span
                style={{
                  fontFamily: "proxima_nova_rgregular",
                  color: "black",
                  marginLeft: isMobileScreen ? "3px" : "0px",
                }}
              >
                Comment
              </span>
            </div>

            <div
              onClick={handleMessageClick}
              className={`col-3 d-flex border-start c-pointer justify-content-center user-select-none fc-1c mt-1 gap-1 ${
                isMobileScreen ? "gap-1" : "gap-2"
              }`}
            >
              <MdOutlineMessage
                width="22"
                height="22"
                style={{ color: "black", marginTop: 3 }}
              />

              <span
                style={{
                  fontFamily: "proxima_nova_rgregular",
                  color: "black",
                }}
              >
                Message
              </span>
            </div>
          </div>
          {showAllComments && (
            <Box sx={{ padding: "10px" }}>
              {" "}
              <Box
                className="comment-box-scroll"
                sx={{
                  borderRadius: 4,
                  maxHeight: "150px",
                  marginTop: "-10px",
                  overflow: "auto",
                }}
              >
                {sortedComments.map((comment) => (
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 16,
                      position: "relative",
                      marginBottom: "28px",
                    }}
                  >
                    <Avatar
                      alt={comment.userName}
                      src={comment.userAvatar}
                      style={{ width: 35, height: 35 }}
                    />
                    <Typography
                      style={{
                        borderRadius: 6,
                        padding: 9,
                        color: "gray",
                        background: "#F0F2F5",
                        fontFamily: "proxima_nova_rgregular",
                      }}
                    >
                      {comment.text}
                    </Typography>
                    <Typography
                      sx={{
                        color: "gray",
                        position: "absolute",
                        left: "54px",
                        top: "36px",
                      }}
                    >
                      {loading ? (
                        "Posting..."
                      ) : (
                        <Moment
                          fromNow
                          style={{
                            fontFamily: "proxima_nova_rgregular",
                            fontSize: "13px",
                          }}
                        >
                          {comment?.timestamp?.toDate()}
                        </Moment>
                      )}
                    </Typography>
                  </Typography>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <CommunityPostCardsPopUp
          key={post.id}
          post={post}
          user={user}
          setPopUpFalse={setPopUpFalse}
          allComments={sortedComments}
          handleSubmitComment={handleSubmitComment}
          loading={loading}
        />
      )}
    </>
  );
};
