import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const CustomCheckBox = ({ checked, onChangeHandler }) => {
  return (
    <div onClick={onChangeHandler} className="fc-1c c-pointer">
      {checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
    </div>
  );
};

export { CustomCheckBox };
